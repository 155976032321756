//Chart Builder 

/* ##########################  Configuration Sections  ########################## */	
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions

import { useDispatch } from 'react-redux';
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

import React, { useState, useEffect, useContext, useRef } from 'react';
import {useLocation} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import fetch from 'cross-fetch';

//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//To Do: AudioMessage Context
import AudioMessage from "../common/AudioMessage";

//Search Tools
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';


//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

//Icons
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Datetime Pickers
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

//Try to import our new line chart
import FlexChart from '../Charts/FlexChart';


/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:11800,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
const input = [1,1,2,3,3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function(acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	  }, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};

/*
//Sorting of arrays by objects:
//localstate.orderby:"SerialNumber" - Used in Item Pricing
pricing.sort(function(a,b){
	//Numeric Values
	if (localstate.orderby==='Cost'){
		return a[localstate.orderby]-b[localstate.orderby];
	} else {
		//Alphabetic
		if (a[localstate.orderby] < b[localstate.orderby]){return -1;}
		if (a[localstate.orderby] > b[localstate.orderby]){return 1;}
		return 0;
	}
});
//Reverse if needed
if (localstate.order==="desc"){
	pricing.reverse();
}
*/

//LOWER SCREEN DEMO DATA


const ChartBuilder = (props) => {
	document.title="Chart Builder";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Martin's Section"));
	dispatch(setCurrentMenuItem("/chartbuilder"));
	//Init with URL search parameters:
	// could be '?Name=Chad'
	const params = new URLSearchParams(useLocation().search);
	var nameparameter = params.get('Name');
	if (!nameparameter){nameparameter=""};

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");


	const rowRefs = useRef([]);
	const btnSave = useRef();

	/* ##########################  UseState Variables  ########################## */ 
	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection:true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems:[],		//Defaults
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws an error.
		order:"asc",
		orderby:"Name",
		selectedcount:0,
		rowsperpage:10,
		selectedindexes:[],
		selectedids:[1,2], //Testing
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		searchoptions:{
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs:{
				//Reserved for basic searchpairs. Inject search parameter 'nameparameter' here.
				searchpair1:{type:"Name", value: nameparameter, mode:"like", uuid:uuidv4()},
				searchpair2:{type:"Title", value: "", mode:"like", uuid:uuidv4()},
				searchpair3:{type:"ChartType", value: "", mode:"left", uuid:uuidv4()},
				searchpair4:{type:"", value: "", mode:"right", uuid:uuidv4()},
				searchpair5:{type:"", value: "", mode:"like", uuid:uuidv4()},
				searchpair6:{type:"", value: "", mode:"like", uuid:uuidv4()}
			},
			nestedrelationships:{
				
			}
		}
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Reusable test to be used with useState bool variables
	//Invalid for none selected or pending changes
	//Bulk Edit Selected
	//Export Selected
	//Print Selected
	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length===0){
			//Lock in potential localstate.pendingsaves
			if (localstate.pendingsaves){
				UpdateState(localstate);
			}
			errors.NewError({errmsg:"No items selected.", errshow:true, errtimeout: 5, errtype:'neutral'});
		} else if (localstate.pendingsaves) {
			errors.NewError({errmsg:"One or more items have a pending save.", errshow:true, errtimeout: 5, errtype:'warning'});
		} else {
			//If all tests pass, use callback
			fnCallback(value);
		}
	}


	/* ##########################  Column States  ########################## */	 
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		ID:true,
		Name:true,
		Title:true,
		Description:true,
		ChartType:true,
		SortOrder:true,
		FlexTable:true,
		Measurement:true,
		XAxisType:true,
		XAxisColumn:true,
		ResultLimit:true,
		Days:true,
		FixedDates:true,
		StartDate:true,
		EndDate:true,
		Market:true
	});


	

	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);

	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		colstate[key] = ! colstate[key];
		CloseColumnMenu();
	}

	/* Export Menu */
	const [showexportmenu, setExportMenu] = useState(null);
	const ShowExportMenu = (event) => {setExportMenu(event.currentTarget);}
	const CloseExportMenu = () => {setExportMenu(null);}

	/* ##########################  Post Test  ########################## */
	//Useful for making test API calls.
	
	const TestPost = () =>{
		const postdata = {
			specs:{
				BootID:uuidv4()
			}
		};
		axios.post(dbendpoint+"/postspecs?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					console.log(res);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}

	

	/* ##########################  Selected Rows  ########################## */
	//Explanation: Since we're using <Checkbox> from materialui in an uncontrolled manner (avoiding state, avoiding rerenders...)
	//we are assuming the only way to show a "checked" value of the checkbox is by a user clicking the actual checkbox.

	//Simply using rowRefs.current[index+"Checkbox"].checked can be set to "true", but the view WILL NOT REFLECT THE CHANGE.
	//So for manual clicks on <Checkbox> we register in localstate - to be used later and maintained by UpdateState(localstate).
	//For SelectAll, we have to run through all items and set isSelected, then rerender.
	//Non-direct interaction with checkbox components will result in difficulty trying to access it through some kind
	//of ref/DOM manipulation. Therefore we pass changes to these components through state changes, mutating localstate.

	const SelectRow = (index) => {
		if (localstate.selectedindexes.indexOf(index)===-1){
			localstate.selectedindexes.push(index);
			localstate.selectedids.push(localstate.griditems[index]['ID']);
			UpdateState(localstate);
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex,1);
			var idspliceindex = localstate.selectedids.indexOf(localstate.griditems[index]['ID']);
			localstate.selectedids.splice(idspliceindex,1);
			UpdateState(localstate);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i=0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
	};



	/* ##########################  Search Options  ########################## */


	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};



	//API Build-out: 
	//	Old: Types [searchtype, sub1type, sub2type] , Search [search, sub1search, sub2search]
	//	New: Types [searchtype1, searchtype2, searchtype3], Search [search1, search2, search3]
	//Set Search Key:
	var mode="";
	const onChangeSearchType = (searchtype, searchnumber) => {
		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict, not
		//Default Mode: LIKE
		
		mode = "like";
		//Provision for automatically switching search mode
		if (searchtype==="Location"){
			mode="strict";
		}

		//Not Conditionals: Convert NotName to Name in BasicTableController
		if (searchtype==="NotName"){
			mode="not";
		}

		//Date Searches
		if (searchtype==="DateAfter"){
			mode="dateafter";
		}
		if (searchtype==="DateBefore"){
			mode="datebefore";
		}

		if (searchtype==="Cost >"){
			mode="greaterthan";
		}
		if (searchtype==="Cost <"){
			mode="lessthan";
		}


		localstate.searchoptions.searchpairs["searchpair"+searchnumber].type = searchtype;
		localstate.searchoptions.searchpairs["searchpair"+searchnumber].mode = mode;        
        //Provision to add columns if selected for search.
        if (searchtype==="Name"){
            colstate["Name"] = true;
            setColumnMenu(null);
        }
		UpdateState(localstate);
	}

	//Set Search Value:
	const onChangeSearchValue = debounce(function(searchvalue, searchnumber) {
		localstate.searchoptions.searchpairs["searchpair"+searchnumber].value = searchvalue;

		//Provision to warn user about a bad datetime format:
		//On blank datetime, proceed to load data
		if ((localstate.searchoptions.searchpairs["searchpair"+searchnumber].type==="DateAfter" || localstate.searchoptions.searchpairs["searchpair"+searchnumber].type==="DateBefore") && searchvalue!==""){
			var count = (searchvalue.match(/-/g) || []).length;
			if (searchvalue.length===10 && count===2){
				let isValidDate = Date.parse(searchvalue);
				if (isNaN(isValidDate)) {
					errors.NewError({ errmsg: "Invalid date.", errshow: true, errtimeout: 5, errtype: "warning" });
				} else {
					localstate.page=0;
					localstate.dbreload = true;
					UpdateState(localstate);
				}
			} else {
				errors.NewError({ errmsg: "Use YYYY-MM-DD format for dates.", errshow: true, errtimeout: 5, errtype: "ok" });
			}
		} else {
			localstate.page=0;
			localstate.dbreload = true;
			UpdateState(localstate);
		}
	},600);

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1:true,
		show2:true,
		show3:true,
		show4:false,
		show5:false,
		show6:false,
		lastsearch:3
	});


	const AddSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show"+(newsearchinputs.lastsearch+1)]=true;
		newsearchinputs.lastsearch++;
		setSearchInputs(newsearchinputs);
	}

	const RemoveSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show"+(newsearchinputs.lastsearch)]=false;
		newsearchinputs.lastsearch--;
		setSearchInputs(newsearchinputs);
	}

	

	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";		
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		localstate.pendingsaves = false;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);		
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.pendingsaves = false;
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page=0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems(){  
		if (localstate.clearselection){
			localstate.selectedindexes = [];
			localstate.selectedcount=0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection=true;
		}
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs,
				nestedrelationships:localstate.searchoptions.nestedrelationships
			}
		};

		if (itemlistsearch.length>0){
			//Serials exist in serial list.
			postdata.searchoptions.itemlist = itemlistsearch;
		}

		axios.post(dbendpoint+"/charts/get?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;
					for (var i =0; i<resultdata.length; i++){
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey=i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)){
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}
					}
					localstate.griditems = resultdata;
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.griditems=[];
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}

	useEffect(() => {
		document.title="Chart Builder";
		if (state.dbreload){
			//Avoid duplicate loads.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
		}
	},);



	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		localstate.griditems.unshift({
			PendingItem:true,
			ID:uuidv4(),
			Name:"",
			Title:"New Chart",
			Description:"",
			ChartType:"",
			SortOrder:0,
			FlexTable:"",
			Measurement:"",
			XAxisType:"",
			XAxisColumn:"",
			ResultLimit:10,
			Days:7,
			FixedDates:false,
			StartDate: new Date(new Date().setDate(new Date().getDate()-7)).toISOString().slice(0, 19).replace('T', ' '),
			EndDate: new Date().toISOString().slice(0, 19).replace('T', ' '),
			Market:''
 

		});
		//All selected indexes move up by 1.
		for (var i=0; i<localstate.selectedindexes.length; i++){
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		var updatearray = [];
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				updatearray.push(localstate.griditems[i]);
			}
		}
		if (localstate.pendingsaves){
			if (updatearray.length>0){
				i=0;
				var limit = updatearray.length-1;
				var updateitems = setInterval(function(){
					var item = updatearray[i];
					//Do work here, API call.
					const postdata = {					
						item:item
					};
					axios.post(dbendpoint+"/charts/update", postdata, defaultpostoptions).then(res => {
						//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
						if (res.status===200){
							//If ValidateUser() fails to verify user, it sends back 'login' error. 
							if (res.data.Status==="login"){
								//Not logged in. Reload page causes redirect to /login
								window.location.reload(false);
							}
							if (res.data.Status==="Success"){
								//Success response also includes the item!
								//If we sent new rows, we'll need to reference the old ID.
								var itemindex=0;
								if (res.data.OldID){
									itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.OldID);
									localstate.griditems[itemindex].unsaved = false;
									rowRefs.current[itemindex+'SaveStatus'].classList.remove(classes.unsavedhighlight);
									//Set New ID
									localstate.griditems[itemindex].ID = res.data.item.ID;
								} else {
									itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.item.ID);
									localstate.griditems[itemindex].unsaved = false;
									//Refs allow us to update the grid live!
									rowRefs.current[itemindex+'SaveStatus'].classList.remove(classes.unsavedhighlight);
								}
							}
							if (res.data.Status==="Failure"){
								//Failure error
								errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"warning"})
							}
						} else {
							//Non-200 message from server.
							errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
						}
					});
					//If we have completed all items, clear this interval and update state.
					if (i===limit){
						localstate.pendingsaves = false;
						clearInterval(updateitems);
						UpdateState(localstate);
					}
					i++;
				}, 200);
			}
			btnSave.current.style.color="rgba(0, 0, 0, 0.87)";
			btnSave.current.style.backgroundColor="#DFDFDF";
		} else {
			alert("Nothing to save!");
		}
	}


	{/* BULK EDIT DRAWER STATE AND FUNCTIONS */}
	const [showbulkeditdrawer, setShowBulkEditDrawer] = useState(false);
	const btnApplyBulkEdit = useRef();

	const CloseBulkEditDrawer = () =>{
		setShowBulkEditDrawer(false);
		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}

	//Bulk edits may incur many different types of changes.
	//Using state variables, we can allow the view to change between them.
	const [changetype, setChangeType] = React.useState('');



	//Try to replace all this?
	//In render: {(changetype==='text')}, etc. NO. We need changetype for when we go back to the DB for the update.


	//// CHANGE TYPES - Controls change type and input viewables
	const [changeistext, setChangeIsText] = React.useState(false);
	const [changeisfloat, setChangeIsFloat] = React.useState(false);
	const [changeissomeselectable, setChangeIsSomeSelectable] = React.useState(false);
	const [changeisdbitem, setChangeIsDBItem] = React.useState(false);
	const [changeisdate, setChangeIsDate] = React.useState(false);


	const [changeisint, setChangeIsInt] = React.useState(false);
	const [changeisstatus, setChangeIsStatus] = React.useState(false);
	



	//// VALUES - Controls input values and value validation
	const changevalue = React.createRef();
	//These 2 refs are used to update a number input (RestrictNumber() will limit what can be put into input box)
	const changevaluefloat = React.createRef();
	const changevalueint = React.createRef();
	const changevalueselectable = React.createRef();
	const [dbitemselection, setDBItemSelection] = React.useState();


	const handleChangeEditType = (event) => {
		//Reset change value
		changevalue.current="";
		//Reset Inputs:
		setChangeIsText(false);
		setChangeIsFloat(false);
		setChangeIsSomeSelectable(false);
		setChangeIsDBItem(false);

		//Examples
		setChangeIsInt(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);

		//Reset Btn
		btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
		btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);

		setChangeType(event.target.value);

		if (
			//Text values here.
			event.target.value==="Name"
		) {
			setChangeIsText(true);
		}

		if (event.target.value==="Cost" || event.target.value==="Price"){
			setChangeIsFloat(true);
		}

		if (event.target.value==="SomeSelectable"){
			setChangeIsSomeSelectable(true);
		}

		if (event.target.value==="DBItem"){
			setChangeIsDBItem(true);
		}

		if (event.target.value==="Date"){
			setChangeIsDate(true);
		}
		

		//Unused Examples:
		if (event.target.value==="LotID" || event.target.value==="SkidNumber"){
			setChangeIsInt(true);
		}

		if (event.target.value==="Status"){
			setChangeIsStatus(true);
		}		

		if (event.target.value==="LotID" || event.target.value==="SkidNumber"){
			setChangeIsInt(true);
		}

	};

	const handleChangeBulkValue = (event) => {
		//Currency
		if (changetype==="Cost" || changetype==="Price"){
			changevalue.current = RestrictNumber(event.target.value, changevalue.current);
			//Updates view:
			changevaluefloat.current.value = changevalue.current;
		//Integers	
		} else if (changetype==="LotID" || changetype==="SkidNumber") {
			changevalue.current = RestrictNumberInteger(event.target.value, changevalue.current);
			//Updates view:
			changevalueint.current.value = changevalue.current;
		//Raw Values
		} else {
			changevalue.current = event.target.value;
		}


		//Verify changes can be saved in current form.
		//Avoid certain items being saved as a blank string:
		if (changetype==="Location" && event.target.value===""){
			btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
			btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);
			errors.NewError({errmsg:"Value cannot be blank.", errshow:true, errtimeout: 3, errtype:"neutral"});

		//Allow bulk change to be saved
		} else {
			btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
			btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	const handleChangeDBItem = (event, item) => {
		setDBItemSelection(item);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeSomeSelectable = (event) => {
		changevalue.current = event.target.value;
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}


	//Search for DB Item (autocomplete):
	const [dbitemkey, DBItemKey] = useState("Name");
	const [opendbitemoptions, openDBItemOptions] = React.useState(false);
	const [dbitemoptions, setDBItemOptions] = React.useState([]);
	const [loadingdbitemoptions, setLoadingDBItemOptions] = useState(false);
	const InitDBItemOptions = () => {
		if (dbitemoptions.length===0){
			//Load (up to limit) options
			DBItemSearch("");
		}
		openDBItemOptions(true);
	}

	const DBItemSearch = debounce(function(searchvalue){
		setLoadingDBItemOptions(true);
		const postdata = {					
			key:dbitemkey,
			search:searchvalue,
			limit:20
		};
		//Error such as "TypeError: Cannot read property 'filter' of undefined" means we aren't handling the response correctly or the response is malformed.
		axios.post(dbendpoint+"/charts/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				} else {
					setDBItemOptions(res.data.items);
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingDBItemOptions(false);
		});
	},600);


	const ApplyBulkEdit = () => {
		var items = [];
		for (var i=0; i<localstate.selectedindexes.length; i++){
			items.push(localstate.griditems[localstate.selectedindexes[i]]);
		}

		//Simple key-value pairs:
		if (changetype==="Name" ||
			changetype==="Price" ||
			changetype==="Cost" ||
			changetype==="SomeSelectable" ||
			changetype==="Date"
		){
			for (i=0; i<items.length; i++){
				items[i][changetype] = changevalue.current;
			}
		}

		//Database Item Selection (potential multiple values to change)
		if (changetype==="DBItem"){
			//Place your specific column values here:
			for (i=0; i<items.length; i++){
				items[i]["Name"] = dbitemselection.Name;
				//Example: items[i]["ProductID"] = dbitemselection.ProductID;
			}
		}

		//Reassign other item values if needed before pushing them to postdata.items.
		//Example provision to change Margin if price or cost change
		if (changetype==="Price"){
			for (i=0; i<items.length; i++){
				//items[i].Price = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		if (changetype==="Cost"){
			for (i=0; i<items.length; i++){
				//items[i].Cost = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		var postdata={
			items:items
		}

		

		axios.post(dbendpoint+"/charts/bulkedititems", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==='login'){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status==='Success'){
					//console.log(res.data);
					if (res.data['SuccessCount']>0){
						errors.NewError({
							errmsg:"Items Changed: "+res.data['SuccessCount']+", Failures: "+res.data['FailCount'],
							errshow:true,
							errtimeout: 5,
							errtype:'ok'})
						}
					}
					
				if (res.data.Status==='Failure'){
					//Failure error
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:'warning'})
			}
		});
	}



	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () =>{
		var deleteitemsarray =[];
		//Reflect items to user for confimation.
		for (var i=0; i<state.selectedindexes.length; i++){
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i=0; i<localstate.selectedindexes.length; i++){
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")){
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {				
					item:localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint+"/charts/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;	
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status===200){
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status==="login"){
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status==="Success"){
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID){
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({errmsg:"Could not delete one or more items.", errshow:true, errtimeout: 8, errtype:"warning"})
							}
						}
						if (res.data.Status==="Failure"){
							//Failure error
							errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
						}
					} else {
						//Non-200 message from server.
						errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */

	const onChangeName = (event, index) => {
		if (event.key === "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+"Name"]){
				rowRefs.current[(index+1)+"Name"].focus();
			} else {
				rowRefs.current[("0Name")].focus();
			}
		} else {
			//Update ref
			rowRefs.current[index+"Name"].value=event.target.value;
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index].Name = event.target.value;
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			btnSave.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	const taborder = ["Name", "Title", "Description", "ChartType", "SortOrder", "FlexTable","Measurement", "XAxisType","XAxisColumn", "ResultLimit", "Days", "FixedDates", "StartDate", "EndDate", "Market"];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown"){
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp"){
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length-1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
		//Handle Right Arrow
		if (event.key === "ArrowRight"){
			event.preventDefault();
			//Same Process as Tab
			var tabindex = taborder.indexOf(column);
			for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
				if (i === taborder.length) {
					i = -1;
					if (rowRefs.current[(index + 1) + column]) {
						index = index + 1;
					} else {
						index = 0;
					}
				} else {
					if (taborder[i]) {
						if (colstate[taborder[i]]) {
							rowRefs.current[index + taborder[i]].select();
							break;
						}
					} else {
						i = -1;
					}
				}
			}
		}
		//Handle Left Arrow
		if (event.key === "ArrowLeft"){
			event.preventDefault();
			var tabindex = taborder.indexOf(column);
			var i;
			var rowincrement=0;
			for (i=(tabindex-1); i>-2; i--){
				if (i===-1){
					rowincrement=-1;
					i=taborder.length; //Go to end of tab order
				} else {
					if (colstate[taborder[i]]) {
						break;
					}
				}
			}
			//If tabindex first and we're in the first row, go to the last element on last row.
			if (tabindex===0 && index===0){
				var lastindex = localstate.griditems.length-1;
				rowRefs.current[lastindex + taborder[i]].select();
			} else {
				rowRefs.current[(index+rowincrement) + taborder[i]].select();
			}
		}
	}

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) =>{
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len-1) - index;
			if (decimalchars>2){
				return oldvalue;
			}
		}
		return newvalue;
	}

	//Restrict Number to Integer - Don't accept any decimal.
	const RestrictNumberInteger = (newvalue, oldvalue) => {
		console.log(newvalue);
		if (newvalue <0 ){
			return 0;
		} else {
			return parseInt(newvalue);
		}
	}

	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value===""){
			rowRefs.current[index+column].value="0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		if (event.key !== "Tab" && 
			event.key!=="ArrowDown" && 
			event.key!=="ArrowUp" && 
			event.key!=="ArrowLeft" && 
			event.key!=="ArrowRight" && 
			event.key!=="ShiftLeft" && 
			event.key!=="ShiftRight"
			){
			//Conditionals for Types:
			if (column==="Cost"){
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Cost changes, so does Margin
				rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
			}
			if (column==="Price"){
				newvalue = RestrictNumber(newvalue, oldvalue);
				//If Price changes, so does Margin
				rowRefs.current[index+"Margin"].textContent = (newvalue - rowRefs.current[index+"Cost"].value).toFixed(2);
				localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column==="SomeBoolean"){
				if (event.target.checked){
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}
				
			} else {
				//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
				if (column==="FixedDates") { //MaterialUI Checkbox
					if (event.target.checked){
						localstate.griditems[index][column] = 1;
					} else {
						localstate.griditems[index][column] = 0;
					}
				} else if (column==="SomeSelectable") {
					//Status works similar to booleans - Component takes care of view while localstate has yet to update the DB
					localstate.griditems[index][column] = newvalue;
				} else {
					//Update Refs like usual.
					rowRefs.current[index+column].value=newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
				
			btnSave.current.style.color="white";
			btnSave.current.style.backgroundColor="#01579B";
		}
	}

	//Custom Function - Optional
	const onChangeCost = (event, index, column) => {
		if (event.key === "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+column]){
				rowRefs.current[(index+1)+column].focus();
			} else {
				rowRefs.current[("0"+column)].focus();
			}
		} else {
			//If Cost changes, so does Margin
			rowRefs.current[index+"Margin"].value = (rowRefs.current[index+"Price"].value - event.target.value).toFixed(2);
			//Update ref
			rowRefs.current[index+column].value=event.target.value;
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index][column] = event.target.value;
			console.log(localstate.griditems[index].Cost);
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			btnSave.current.style.color="white";
			btnSave.current.style.backgroundColor="#01579B";
		}
	}


	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		//Reset all:
		for (var i=1; i<7; i++){
			localstate.searchoptions.searchpairs["searchpair"+i].type = "";
			localstate.searchoptions.searchpairs["searchpair"+i].value = "";
			localstate.searchoptions.searchpairs["searchpair"+i].mode = "like";
			localstate.searchoptions.searchpairs["searchpair"+i].uuid = uuidv4();
		}
		//Set Defaults:
		setItemListSearch('');
		localstate.searchoptions.searchpairs.searchpair1.type = "Name";
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	


	/* ########################## Lower Screen Demo Data/Functions ###### */


	//Basic Select
	const [basicselectvalue, setBasicSelectValue] = React.useState('');

	const handleChange = (event) => {
		setBasicSelectValue(event.target.value);
	};


	//Autocomplete Simple
	//Example: Products
	const [openproductoptions, openProductOptions] = React.useState(false);
	const [productoptions, setProductOptions] = React.useState([]);
	const [loadingproductoptions, setLoadingProductOptions] = useState(false);
	const [productsearchterm, setProductSearchTerm] = useState("Default Value");
	//For loading single items that do have a value, use LoadItem function in conjunction with: setProductSearchTerm(res.data.item.product.Name);
	const InitProductOptions = () => {
		if (productoptions.length===0){
			ProductSearch("");
		}
		openProductOptions(true);
	}

	const onChangeProductOption = (event, newvalue) =>{
		//Set value for current search
			// Example:
			// localstate.searchoptions.searchpairs.searchpairX.type=autocompletesearchtypeX;
			// localstate.searchoptions.searchpairs.searchpairX.value=newvalue[autocompletesearchtypeX];
			// localstate.dbreload = true;
			// UpdateState(localstate);

		//Or set value for your item:
			//localstate.itemdata['ProductID'] = newvalue['ProductID'];
			// localstate.pendingsaves = true;
			// btnSave.current.disabled = false;
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";

		//Or just yell at the user, the ProductID they just set
			//alert(newvalue['ProductID']);

		
			
	}

	const ProductSearch = debounce(function(searchvalue){
		setProductSearchTerm(searchvalue);
		setLoadingProductOptions(true);
		const postdata = {					
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/items/searchproducts", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setProductOptions(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingProductOptions(false);
		});
	},600);

	const [basicselectdbvalue, setBasicSelectDBValue] = useState("Default basic select value");

	const GetIDs = (localstate) => {
		var ids=[];
		localstate.selectedindexes.map((index)=>{
			ids.push(localstate.griditems[index].ID);
		});
		return ids;
	}

	

	/* ##########################  TABLE HEAD  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "ID", numeric: true, label: "ID", align:"left", allowsort:true, style:{}},
		{ id: "Name", numeric: false, label: "Name", align:"left", allowsort:true, style:{width:"400px"}},
		{ id: "Title", numeric: false, label: "Title", align:"left", allowsort:true, style:{}},
		{ id: "Description", numeric: false, label: "Description", align:"left", allowsort:true, style:{}},
		{ id: "ChartType", numeric: false, label: "Chart Type", align:"left", allowsort:true, style:{}},
		{ id: "SortOrder", numeric: true, label: "Sort Order", align:"right", allowsort:true, style:{}},
		{ id: "FlexTable", numeric: false, label: "Flex Table", align:"left", allowsort:true, style:{}},
		{ id: "Measurement", numeric: false, label: "Measurement", align:"left", allowsort:true, style:{}},
		{ id: "XAxisType", numeric: false, label: "XAxisType", align:"left", allowsort:true, style:{}},
		{ id: "XAxisColumn", numeric: false, label: "XAxisColumn", align:"left", allowsort:true, style:{}},
		{ id: "ResultLimit", numeric: true, label: "Result Limit", align:"right", allowsort:false, style:{}},
		{ id: "Days", numeric: true, label: "Days", align:"right", allowsort:false, style:{}},
		{ id: "FixedDates", numeric: false, label: "Fixed Dates", align:"center", allowsort:true, style:{}},
		{ id: "StartDate", numeric: false, label: "Start Date", align:"left", allowsort:true, style:{}},
		{ id: "EndDate", numeric: false, label: "End Date", align:"left", allowsort:true, style:{}},
		{ id: "Market", numeric: false, label: "Market", align:"left", allowsort:true, style:{}},
		
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};
	
		return (
			<thead style={{display:"table-header-group"}}>
				<tr style={{border:"1px solid #CCC",
							backgroundColor:"#DDD"}}>
					<td style={{width: "14px", padding:"none", display:"table-cell", padding:"2px 4px 2px 5px"}}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) => 
					colstate[headCell.id] &&
					( 	
						<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}				
							>
								{(headCell.allowsort) && 
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id==="Name") &&
											<div style={{width:"30px", display:"inline-block"}}></div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id) 
											? <span style={{fontWeight:"bold"}}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) && 
									<span>{headCell.label}</span>
								}
							</td>
					))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	{/* Utility Drawer state and functions */}
	const [showutilitydrawer, setShowUtilityDrawer] = useState(false);

	const CloseUtilityDrawer = () =>{
		setShowUtilityDrawer(false);
		//Reload DB?
		//localstate.dbreload = true;
		//Update State?
		//UpdateState(localstate);
	}

	{/* Item List - General purpose boilerplate */}
	const [showitemlist, setShowItemList] = useState(false);
	const [itemlist, setItemList] = useState('');
	//We keep our original item list intact, use itemlistsearch for new DB reload
	const [itemlistsearch, setItemListSearch] = useState("");
	const [dupeitems, setDupeItems] = useState('');
	const CloseItemList = () =>{
		//Get unique items
		const result = itemlist.split(/\r?\n/).filter(element => element);
		const newitemlist = GetUniqueArray(result);
		setItemListSearch(newitemlist);
		setShowItemList(false);

		//Reload DB?
		localstate.dbreload = true;
		//Update State?
		UpdateState(localstate);
	}
	const onChangeItemList = (event) => {
		//Optional check for dupes:
		//Bust apart by line breaks!
		//Let's start sending arrays to PHP Laravel instead where possible since we're already
		//going to do work here before the API call.

		//If there is a line break at the end of the list (blank new line), you may end up with a falsy array element at the end.
		//Filter creates a new array based on passing a test function (true or false).
		//By passing a falsy array element (undefined), it won't return that element in the result array.
		//Split by Line Breaks, then filter.
		const result = event.target.value.split(/\r?\n/).filter(element => element);
		//Find Dupes, let the user know there are dupes.
		const dupes = GetDupeArray(result);
		if (dupes.length>0){
			setDupeItems(dupes);
			console.log(dupes);
		} else {
			setDupeItems('');
		}
		setItemList(event.target.value);
	}

	{/* Print Labels */}
	const PrintLabels = () =>{
		var itemids = [];
		//Grab all IDs
		for (var i=0; i<localstate.selectedindexes.length; i++){
			itemids.push(localstate.griditems[localstate.selectedindexes[i]]['ID']);
		}

		var params = {
			itemids:JSON.stringify(itemids),
			labeltype:'basiclabel',
			autoclose:false,
			seriallist:'',
			orderby:localstate.orderby,
			order:localstate.order
		};
	
		// Creating a form
		var form = document.createElement("form");
		form.setAttribute("method","post");
		form.setAttribute("action","../v3/labels");
		form.setAttribute("target", "NewLabel");
		for (var i in params) {
			if (params.hasOwnProperty(i)){
				// Creating the input
				var input = document.createElement('input');
				input.type='hidden';
				input.name=i;
				input.value=params[i];

				// Attach input to form
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();
	}


	//Chart User Input
	const [days, setDays] = useState(90);
	const InitDateTimeAfter = () => {
		var date = new Date(new Date().setDate(new Date().getDate()-days));
		date.setHours(0,0,0,0);
		return date;
	}
	const InitDateTimeBefore = () => {
		var date = new Date();
		date.setHours(23, 59, 59, 999);
		return date;
	}
	const [market, setMarket] = useState("All");
	const [measurement, setMeasurement] = useState("unitsperday");
	const [datetimeafter, setDateTimeAfter] = useState(InitDateTimeAfter());
	const [datetimebefore, setDateTimeBefore] = useState(InitDateTimeBefore());


	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<div style={{ padding: "8px" }}>

				{/* Standard Page Header with right floated error message space */}
				<div style={{ height: "50px", paddingTop: "5px" }}>
					<div style={{ textAlign: "center" }}>
						<h2>Chart Builder</h2>
					</div>
					{(errors.currenterror.errshow) &&
						<div style={{ position: "relative", float: "right", bottom: "26px", height: "0px", fontSize: "12px" }}>
							<ErrorMessage />
						</div>
					}
				</div>

				{/* /* ##########################  Search Inputs  ########################## */}
				{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
				{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

				{(!isPrintView) &&
					<div>

						{/* Key-Value Searches */}

						{/* Search Pair 1 */}
						{(searchinputs.show1) &&
							<div className={classes.searchinputs} style={{ width: "300px" }}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
									<Select
										key={localstate.searchoptions.searchpairs.searchpair1.uuid}
										value={localstate.searchoptions.searchpairs.searchpair1.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 1)}
										MenuProps={{
											style: { zIndex: 2001 }
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Title"}>Title</MenuItem>
										<MenuItem value={"ChartType"}>Chart Type</MenuItem>
									</Select>
								</FormControl>
								<TextField id="search1" variant="standard"
									key={localstate.searchoptions.searchpairs.searchpair1.uuid + 1}
									defaultValue={localstate.searchoptions.searchpairs.searchpair1.value}
									className={classes.searchinput} InputProps={{ disableUnderline: true }}
									onChange={(event) => onChangeSearchValue(event.target.value, 1)} />
								{/* The 30px wide spacer for a close button can be optionally removed. The space might better be used for view vs the need for close buttons */}
								<div style={{ width: "30px" }}>&nbsp;</div>
							</div>
						}

						{/* Search Pair 2 */}
						{(searchinputs.show2) &&
							<div className={classes.searchinputs} style={{ width: "300px" }}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair2.uuid}
										value={localstate.searchoptions.searchpairs.searchpair2.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 2)}
										MenuProps={{
											style: { zIndex: 2001 }
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Title"}>Title</MenuItem>
										<MenuItem value={"ChartType"}>Chart Type</MenuItem>
									</Select>
								</FormControl>
								<TextField id="search2" variant="standard"
									key={localstate.searchoptions.searchpairs.searchpair2.uuid + 1}
									defaultValue={localstate.searchoptions.searchpairs.searchpair2.value}
									className={classes.searchinput} InputProps={{ disableUnderline: true }}
									onChange={(event) => onChangeSearchValue(event.target.value, 2)} />
								<div style={{ width: "30px", float: "right" }}>
									{(searchinputs.show2 && !searchinputs.show3) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}

						{/* Search Pair 3 */}
						{(searchinputs.show3) &&
							<div className={classes.searchinputs} style={{ width: "300px" }}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair3.uuid}
										value={localstate.searchoptions.searchpairs.searchpair3.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 3)}
										MenuProps={{
											style: { zIndex: 2001 }
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Title"}>Title</MenuItem>
										<MenuItem value={"ChartType"}>Chart Type</MenuItem>
									</Select>
								</FormControl>
								<TextField id="search3" variant="standard"
									key={localstate.searchoptions.searchpairs.searchpair3.uuid + 1}
									defaultValue={localstate.searchoptions.searchpairs.searchpair3.value}
									className={classes.searchinput} InputProps={{ disableUnderline: true }}
									onChange={(event) => onChangeSearchValue(event.target.value, 3)} />
								<div style={{ width: "30px" }}>
									{(searchinputs.show3 && !searchinputs.show4) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}

						{/* Search Pair 4 */}
						{(searchinputs.show4) &&
							<div className={classes.searchinputs} style={{ width: "300px" }}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair4.uuid}
										value={localstate.searchoptions.searchpairs.searchpair4.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 4)}
										MenuProps={{
											style: { zIndex: 2001 }
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Title"}>Title</MenuItem>
										<MenuItem value={"ChartType"}>Chart Type</MenuItem>
									</Select>
								</FormControl>
								<TextField id="search4" variant="standard"
									key={localstate.searchoptions.searchpairs.searchpair4.uuid + 1}
									defaultValue={localstate.searchoptions.searchpairs.searchpair4.value}
									className={classes.searchinput} InputProps={{ disableUnderline: true }}
									onChange={(event) => onChangeSearchValue(event.target.value, 4)} />
								<div style={{ width: "30px" }}>
									{(searchinputs.show4 && !searchinputs.show5) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}


						{/* Search Pair 5 */}
						{(searchinputs.show5) &&
							<div className={classes.searchinputs} style={{ width: "300px" }}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair5.uuid}
										value={localstate.searchoptions.searchpairs.searchpair5.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 5)}
										MenuProps={{
											style: { zIndex: 2001 }
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Title"}>Title</MenuItem>
										<MenuItem value={"ChartType"}>Chart Type</MenuItem>
									</Select>
								</FormControl>
								<TextField id="search5" variant="standard"
									key={localstate.searchoptions.searchpairs.searchpair5.uuid + 1}
									defaultValue={localstate.searchoptions.searchpairs.searchpair5.value}
									className={classes.searchinput} InputProps={{ disableUnderline: true }}
									onChange={(event) => onChangeSearchValue(event.target.value, 5)} />
								<div style={{ width: "30px" }}>
									{(searchinputs.show5 && !searchinputs.show6) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}

						{/* Search Pair 6 */}
						{(searchinputs.show6) &&
							<div className={classes.searchinputs} style={{ width: "300px" }}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair6.uuid}
										value={localstate.searchoptions.searchpairs.searchpair6.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 6)}
										MenuProps={{
											style: { zIndex: 2001 }
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Title"}>Title</MenuItem>
										<MenuItem value={"ChartType"}>Chart Type</MenuItem>
									</Select>
								</FormControl>
								<TextField id="search6" variant="standard"
									key={localstate.searchoptions.searchpairs.searchpair6.uuid + 1}
									defaultValue={localstate.searchoptions.searchpairs.searchpair6.value}
									className={classes.searchinput} InputProps={{ disableUnderline: true }}
									onChange={(event) => onChangeSearchValue(event.target.value, 6)} />
								<div style={{ width: "30px" }}>
									{(searchinputs.show6) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}


					</div>
				}

				{/* End of Search Inputs */}


				{/* Top Buttons & Pagination */}
				{(!isPrintView) &&
					<React.Fragment>
						<div style={{ height: "5px" }}>&nbsp;</div>
						<div>
							{(!showdeleteconfirmation) &&
								<React.Fragment>

									<Button
										className={classes.buttonmargin}
										variant="contained"
										color="secondary"
										onClick={() => SaveChanges()}
										ref={el => btnSave.current = el}>
										Save Changes
									</Button>

									<Button
										className={classes.buttonmargin}
										variant="contained"
										color="secondary"
										onClick={() => AddRow()}>
										Add Row
									</Button>

									<Button
										className={classes.buttonmargin}
										variant="contained"
										color="secondary"
										onClick={() => RejectIfInvalidSelected("", DeleteSelectedInit)}>
										Delete Selected
									</Button>

									{/* ##########################  Column Toggles  ########################## */}
									<Button
										className={classes.buttonmargin}
										variant="contained"
										color="secondary"
										aria-haspopup="true"
										onClick={ShowColumnMenu}>
										Columns
									</Button>
									<Menu
										id="simple-menu"
										anchorEl={showcolumnmenu}
										keepMounted
										open={Boolean(showcolumnmenu)}
										onClose={CloseColumnMenu}
									>
										<MenuItem onClick={() => ToggleColumn('Name')} className={colstate.Name ? classes.selectedcolumn : ''}>Name</MenuItem>
										<MenuItem onClick={() => ToggleColumn('Title')} className={colstate.Title ? classes.selectedcolumn : ''}>Title</MenuItem>
										<MenuItem onClick={() => ToggleColumn('ChartType')} className={colstate.ChartType ? classes.selectedcolumn : ''}>Chart Type</MenuItem>
									</Menu>

								</React.Fragment>
							}

							{/* Delete Items Confirmation */}
							{(showdeleteconfirmation) &&
								<div>
									<b>Are you sure you want to delete these items?</b>
									<div style={{ padding: "10px 0px" }}>
										{deleteitems.map((row, index) => {
											if (deleteitems.length === index + 1) {
												return (<span key={index}>{row.Name}</span>)
											} else {
												return (<span key={index}>{row.Name}, </span>)
											}
										})}
									</div>
									<Button className={classes.warningbutton} variant="contained" onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
									<Button variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
								</div>
							}


							{(localstate.totalitems > 0) &&
								<TablePagination className={classes.paginationalign}
									style={{ display: "inline-flex", float: "right" }}
									component="div"
									count={localstate.totalitems}
									page={localstate.page}
									onPageChange={handleChangePage}
									rowsPerPage={localstate.rowsperpage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									rowsPerPageOptions={[10, 20]}
								/>
							}
						</div>
					</React.Fragment>
				}
				{/* End of Top Buttons & Pagination */}



				{/* ##########################  Start of Table  ########################## */}
				<table aria-label="caption table" size="small" className={classes.flexgrid} style={{ display: "table", tableLayout: "fixed", minWidth: "100%", borderCollapse: "collapse", borderColor: "grey" }}>
					<EnhancedTableHead
						numSelected={localstate.selectedcount}
						classes={classes}
						order={localstate.order}
						orderBy={localstate.orderby}
						onSelectAllClick={handleSelectAllClick}
						onRequestSort={handleRequestSort}
						rowCount={state.griditems.length}
					/>
					{/* /* ##########################  Row Design  ########################## */}
					{/* If DB reload, don't allow view of table. */}
					{(!localstate.dbreload) &&
						<tbody style={{ display: "table-row-group" }}>
							{(localstate.griditems.length > 0) &&
								localstate.griditems.map((row, index) => {
									//Create all-new refs on each render. Helps avoid issues with grid states.
									rowRefs.current[index + "Checkbox"] = React.createRef();
									rowRefs.current[index + "SaveStatus"] = React.createRef();
									rowRefs.current[index + "Name"] = React.createRef();
									rowRefs.current[index + "Title"] = React.createRef();
									rowRefs.current[index + "Description"] = React.createRef();
									rowRefs.current[index + "ChartType"] = React.createRef();
									rowRefs.current[index + "SortOrder"] = React.createRef();
									rowRefs.current[index + "FlexTable"] = React.createRef();
									rowRefs.current[index + "Measurement"] = React.createRef();
									rowRefs.current[index + "XAxisType"] = React.createRef();
									rowRefs.current[index + "XAxisColumn"] = React.createRef();
									rowRefs.current[index + "ResultLimit"] = React.createRef();
									rowRefs.current[index + "Days"] = React.createRef();
									rowRefs.current[index + "StartDate"] = React.createRef();
									rowRefs.current[index + "EndDate"] = React.createRef();
									rowRefs.current[index + "Market"] = React.createRef();

									return (
										<React.Fragment key={row.ID}>
											<tr className={classes.flexgridrow}>
												{/* Checkbox - Requires inner div to change background color with SaveStatus */}
												<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
													<div style={{ padding: "3px 4px 1px 4px" }}>
														{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
														{/* Don't show checkbox if this is a PendingItem */}
														{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el=>rowRefs.current[index+"Checkbox"]=el} 
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

													</div>
												</td>

												{/* ID */}
												{(colstate.ID) &&
													<td className={classes.flexgridstaticcontainer}>
														{row.ID}
													</td>
												}

												{/* Name - Optional Expand toggle! Change flexgridinput-30 to flexgridinput to remove icon spacer. */}
												{(colstate.Name) &&
													<td className={classes.flexgridinputcontainer}>
														{(!row.ExpandRow) &&
															<div className={classes.flexgridexpand}>
																<ExpandMoreIcon className={classes.transparenticon} color="primary" fontSize="inherit" onClick={() => ExpandRowToggle(index)}></ExpandMoreIcon>
															</div>
														}
														{(row.ExpandRow) &&
															<div className={classes.flexgridexpand}>
																<ExpandLessIcon className={classes.transparenticon} color="primary" fontSize="inherit" onClick={() => ExpandRowToggle(index)}></ExpandLessIcon>
															</div>
														}

														<input
															ref={el => rowRefs.current[index + "Name"] = el}
															className={classes.flexgridinput30}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Name")}
															onKeyUp={(event) => onChangeValue(event, index, "Name")}
															defaultValue={row.Name} />
													</td>
												}

												{/* Title */}
												{(colstate.Title) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "Title"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Title")}
															onKeyUp={(event) => onChangeValue(event, index, "Title")}
															defaultValue={row.Title} />
													</td>
												}

												{/* Description */}
												{(colstate.Description) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "Description"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Description")}
															onKeyUp={(event) => onChangeValue(event, index, "Description")}
															defaultValue={row.Description} />
													</td>
												}

												{/* ChartType */}
												{(colstate.ChartType) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "ChartType"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "ChartType")}
															onKeyUp={(event) => onChangeValue(event, index, "ChartType")}
															defaultValue={row.ChartType} />
													</td>
												}

												{/* SortOrder */}
												{(colstate.SortOrder) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															type="number" step="0.01"
															ref={el => rowRefs.current[index + "SortOrder"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "50px", textAlign: "right" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "SortOrder")}
															onKeyUp={(event) => onChangeValue(event, index, "SortOrder")}
															onBlur={(event) => DetectBlankNumber(event, index, "SortOrder")}
															defaultValue={row.SortOrder} />
													</td>
												}


												{/* FlexTable */}
												{(colstate.FlexTable) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "FlexTable"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "FlexTable")}
															onKeyUp={(event) => onChangeValue(event, index, "FlexTable")}
															defaultValue={row.FlexTable} />
													</td>
												}

												{/* Measurement */}
												{(colstate.Measurement) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "Measurement"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Measurement")}
															onKeyUp={(event) => onChangeValue(event, index, "Measurement")}
															defaultValue={row.Measurement} />
													</td>
												}

												{/* XAxisType */}
												{(colstate.XAxisType) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "XAxisType"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "XAxisType")}
															onKeyUp={(event) => onChangeValue(event, index, "XAxisType")}
															defaultValue={row.XAxisType} />
													</td>
												}

												{/* XAxisColumn */}
												{(colstate.XAxisColumn) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "XAxisColumn"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "XAxisColumn")}
															onKeyUp={(event) => onChangeValue(event, index, "XAxisColumn")}
															defaultValue={row.XAxisColumn} />
													</td>
												}


												{/* ResultLimit */}
												{(colstate.ResultLimit) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															type="number" step="1"
															ref={el => rowRefs.current[index + "ResultLimit"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "50px", textAlign: "right" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "ResultLimit")}
															onKeyUp={(event) => onChangeValue(event, index, "ResultLimit")}
															onBlur={(event) => DetectBlankNumber(event, index, "ResultLimit")}
															defaultValue={row.ResultLimit} />
													</td>
												}

												{/* Days */}
												{(colstate.Days) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															type="number" step="1"
															ref={el => rowRefs.current[index + "Days"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "50px", textAlign: "right" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Days")}
															onKeyUp={(event) => onChangeValue(event, index, "Days")}
															onBlur={(event) => DetectBlankNumber(event, index, "Days")}
															defaultValue={row.Days} />
													</td>
												}

												{/* FixedDates */}
												{(colstate.FixedDates) &&
													<td>
														<div style={{ padding: "4px 4px 1px 4px", textAlign: "center" }}>
															<Checkbox
																className={classes.gridcheckbox}
																disableRipple
																color="default"
																defaultChecked={row.FixedDates === 1 ? true : false}
																checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																icon={<span className={classes.icon} />}
																onChange={(event) => onChangeValue(event, index, "FixedDates")}
															/>
														</div>
													</td>
												}

												{/* StartDate */}
												{(colstate.StartDate) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "StartDate"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "StartDate")}
															onKeyUp={(event) => onChangeValue(event, index, "StartDate")}
															defaultValue={row.StartDate} />
													</td>
												}

												{/* EndDate */}
												{(colstate.EndDate) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "EndDate"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "EndDate")}
															onKeyUp={(event) => onChangeValue(event, index, "EndDate")}
															defaultValue={row.EndDate} />
													</td>
												}

												{/* Market */}
												{(colstate.Market) &&
													<td className={classes.flexgridinputcontainer}>
														<input
															ref={el => rowRefs.current[index + "Market"] = el}
															className={classes.flexgridinput}
															style={{ minWidth: "100px" }}
															onKeyDown={(event) => HandleKeyDown(event, index, "Market")}
															onKeyUp={(event) => onChangeValue(event, index, "Market")}
															defaultValue={row.Market} />
													</td>
												}



											</tr>
											{/* Try: conditional for any render whatsoever! */}
											{(row.ExpandRow === true) &&
												<tr>
													<td colSpan="100%">
														<div style={{ margin: "25px" }}>
															Expanded column container!
														</div>
													</td>
												</tr>
											}
										</React.Fragment>

									)
								}
								)
							}
							{(localstate.griditems.length === 0) &&
								<tr className="flexgridrow"><td colSpan="100%"
									style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
							}
						</tbody>
					}
					{(localstate.dbreload) &&
						<tbody>
							<tr>
								<td colSpan="100%">
									<div style={{ padding: "20px", textAlign: "center", margin: "auto" }}>
										<CircularProgress />
									</div>
								</td>
							</tr>
						</tbody>
					}
				</table>
				{(localstate.totalitems > localstate.rowsperpage) &&
					<TablePagination className={classes.paginationalign}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[10, 20]}
					/>
				}

				<div style={{ fontSize: "18px" }}>
					Information:
				</div>
				ChartTypes: [linechart, ]<br></br>
				XAxisTypes: [distinctcount, salesbydate, productsalesperday]<br></br>
				XAxisColumn: [Model,...]<br></br>
				Selected Rows: {state.selectedcount}<br></br>
				IDs: {state.selectedindexes.map((rowid, index) => (
					<p key={index}>ID: {rowid}</p>
				))}
				<br></br>

				Days: <input type="number" defaultValue={days} onChange={(event) => setDays(event.target.value)} />

				<label>Market:</label>
				<select name="markets" onChange={(event) => setMarket(event.target.value)}>
					<option value="All">All</option>
					<option value="Newegg">Newegg</option>
					<option value="Newegg Business">Newegg Business</option>
					<option value="Amazon">Amazon</option>
					<option value="Back Market">Back Market</option>
				</select>
				<label>Measurement Type:</label>
				<select name="measurementtype" onChange={(event) => setMeasurement(event.target.value)}>
					<option value="unitsperday">Units Per Day</option>
					<option value="totalsalesperday">Total Sales Per Day</option>
					<option value="averageprice">Average Price</option>
				</select>

				<label>Date Time After:</label>
				<div className={classes.searchinputs} style={{ width: "250px" }}>
					<DateTimePicker
						value={datetimeafter}
						onChange={(newvalue) => setDateTimeAfter(newvalue)}
						renderInput={(params) => <TextField
							{...params} variant="standard" className={classes.searchinput}
							inputProps={{
								...params.inputProps,
								placeholder: "tt.mm.jjjj"
							}}
						/>}
					/>
				</div>

				<label>Date Time Before:</label>
				<div className={classes.searchinputs} style={{ width: "250px" }}>
					<DateTimePicker
						value={datetimebefore}
						onChange={(newvalue) => setDateTimeBefore(newvalue)}
						renderInput={(params) => <TextField
							{...params} variant="standard" className={classes.searchinput}
							inputProps={{
								...params.inputProps,
								placeholder: "tt.mm.jjjj"
							}}
						/>}
					/>
				</div>
				
				<hr />


				<br></br>
				<br></br>

				{/* Let's see if we should be doing this after local DB load instead of remounting too quickly... */}
				{(!localstate.dbreload) &&
					<div style={{ width: "100%", height: "300px" }}>

					{/*
						<h2>Sales Per Day Line - Driven By Days Previous</h2>
						<FlexChart
							chartid={1} //Database variables held here
							chartdata={[]}
							datapreloaded={false}
							//Override Variables (options set in view, title, etc). These will override when the component fetches data in PHP
							chartconfig={{
								chartdata:[], // Default array of chart data - allows sending data directly to the flexchart
								chartloaded:false,
								DisableTitle: false,
								//Title:"Overridden Title", //Override Title
								TitleAlign: "center",
								Days: days,
								Markets: market,
								Measurement: measurement,
								colors: ['#01579B', '#222222']
							}}
						/>
					*/}
				
					
				

					{/*
					<h2>Hybrid Chart (has overrides)</h2>
						<FlexChart
							chartid={3}
							chartdata={[]}
							datapreloaded={false}
							chartconfig={{
								chartdata:[], // Default array of chart data - allows sending data directly to the flexchart
								chartloaded:false,
								DisableTitle: false,
								//Title:"Overridden Title", //Override Title
								TitleAlign: "center",
								FixedDates:true,
								StartDate:datetimeafter,
								EndDate:datetimebefore,
								Days: days,
								Markets: market,
								Measurement: measurement,
								colors: ['#01579B', '#222222'],
								TickMarkWidthPx:200,
							}}
					/>
				
					*/}
					
				

						{/* Static Chart: salesperdaybar 
						
						<h2>Static Chart: Salesperdaybar, uses days</h2>
				
						<FlexChart
							//chartid={3}
							chartdata={[]}
							datapreloaded={false}
							chartconfig={{
								ChartType: "salesperdaybar",  //salesperdayline, salesperdaybar
								chartdata:[], // Default array of chart data - allows sending data directly to the flexchart
								chartloaded:false,
								Measurement: "totalsalesperday", //totalsalesperday
								FixedDates: false, //Bool, determines whether we're using dates sent from view or Days (days back)
								Days: 20, //Unused is FixedDates is true
								Markets: "All", //All, Newegg, Newegg Business, Amazon, etc
								Title: "Static Sales Per Day Chart",
								DisableTitle: false, //Option to hide title
								TitleAlign: "center",
								TickMarkWidthPx: 200, //Set to avoid bottomAxis tickmark labels from overlapping. Uses function to determine output.
							}}
						/>*/}
						
						
				
				
						


						

					
{/*
					<FlexChart
						chartid={3}
						chartconfig={{ Days: days, Markets:market }}
					/>
				
					<FlexChart 
						chartdata={[]}
						datapreloaded={false}
						chartconfig={{
							Name:'',
							Title: "7 Day Average Product Sales - Uses ChartConfig arguments, not DB",
							Description:"Sample Description",
							ChartType:"salesperday", // salesperday, 
							AveragePrice:true,
							UnitsPerDay:false,
							ProductIDs:[], //Useful for salesperday
							ResultLimit:"",
							Days:7,
							FixedDates:false,
							StartDate:"",
							EndDate:"",
							Markets:"All",
							UserIDs:"", //Useful for checkin, checkout, repair statuses made by users on flexitems
							ShowLegend:true
						}}
					/>
			
					*/}



						{/* Static Chart: Checkin 
						// make sure parent container have a defined height when using
						// responsive component, otherwise height will be 0 and
						// no chart will be rendered.
				
						
						*/}
						<h2>Checkin Chart: Bar Chart - TO DO</h2>
					
						
				
						<FlexChart
							//chartid={3}
							chartdata={[]}
							datapreloaded={false}
							chartconfig={{
								ChartType: "checkinperdaybar",  //salesperdayline, salesperdaybar, checkinperdaybar, inventoryitemgrades
								UserIDs: [11,14,2], //'All' string or array of user ids.
								Measurement: "unitsperday", //totalsalesperday
								FixedDates: false, //Bool, determines whether we're using dates sent from view or Days (days back)
								Days: 30, //Unused if FixedDates is true
								//FixedDates:true,
								//StartDate:datetimeafter,
								//EndDate:datetimebefore,
								Markets: "All", //All, Newegg, Newegg Business, Amazon, etc
								Title: "Checkin Per Day",
								DisableTitle: false, //Option to hide title
								TitleAlign: "center",
								TickMarkWidthPx: 120, //Set to avoid bottomAxis tickmark labels from overlapping. Uses function to determine output.
							}}
						/>
						

	
					





						{/* Static Chart: Grade Breakdown on Inventory Items 
						// Apply chart data through 
						
*/}
					
						<h2>Grades Chart: Custom, non-Nivo</h2>
					
						
				
						<FlexChart
							//chartid={3}
							datapreloaded={true}
							chartdata={[]} //Feed here!
							chartconfig={{
								ChartType: "inventoryitemgrades",  //salesperdayline, salesperdaybar, checkinperdaybar, inventoryitemgrades
							}}
						/>
						


					</div>
				}

			</div>
		</LocalizationProvider>
        
    );
}

export default ChartBuilder;
