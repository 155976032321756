//Flex Charts:
/*
Major Points:
1. Chart configuration can be stored in the database and are stored and retrieved by ChartID.
2. Chart configurations can be overriden with chartconfig(on component instantiation) vs chartconfig(db)
3. Charts can also be 100% user driven without chartid
4. Some configuration is used for the DB and some is used for the view!

*/


//Flex Line Chart
//Takes argument of array of IDs of charts, returns rendered charts.


///###### TO DO:
// At some point I'd like to continue to allow some config settings to come from a database.
// Likely these database settings will need to be setup in a key-value pair list that fits in expanded row content.
// As it stands right now, there are boatloads of different specs a chart might have - There needs to be discovery before we go into
// creating a whole DB schema at the moment.
// Import: Allow the fetchchart method to grab a chartid for settings - this can be a hybrid model, taking further user input for chart rerenders.
// Charts can be controlled by Functional Component API or DB flexchart entries!


/*How to Use Charts:

#### fetchchart@ChartsController takes either 'chartconfig' or 'chartid' to derive $chartconfig

####ChartConfig Setup:
Charts can be controlled by sending a chart config to this controller via props.

<FlexChart 
	chartconfig={{
		Name:'',
		Title: "7 Day Unit Product Sales",
		Description:"Sample Description",
		ChartType:"salesperday", // salesperday, 
		AveragePrice:false,
		UnitsPerDay:true,
		ProductIDs:[], //Useful for salesperday
		ResultLimit:"",
		Days:7,
		FixedDates:false,
		StartDate:"",
		EndDate:"",
		Market:"",
		UserIDs:[], //Useful for checkin, checkout, repair statuses made by users on flexitems
		ShowLegend:false
	}}
/>


####DB Setup: Use ChartBuilder table for new chart entries, then use chartid as such:
<FlexChart chartid={{
		Name:'',
		Title: "7 Day Unit Product Sales",
		Description:"Sample Description",
		ChartType:"salesperday", // salesperday, 
		AveragePrice:false,
		UnitsPerDay:true,
		ProductIDs:[], //Useful for salesperday
		ResultLimit:"",
		Days:7,
		FixedDates:false,
		StartDate:"",
		EndDate:"",
		Market:"",
		UserIDs:[], //Useful for checkin, checkout, repair statuses made by users on flexitems
		ShowLegend:false
	}}
/>


CONFIGURATION SCHEMA:
ID
Name: Non-blank space name for a chart - may be used in the future for chart fetching if IDs prove difficult to deal with.
Title: Can be blank - Controls chart title in views.
Description: Can be used as a lower description of the data. Can be blank.
ChartType:
	salesperdaybar - Requires [Days, FixedDates=false] OR [StartDate, EndDate, FixedDates=true], optional ProductIDs, Markets
	checkinperdaybar - Uses UserIDs, Days, MeasureBy

ResultLimit - 
FixedDates [bool] - Controls how many results 
StartDate - If fixed dates, start\end dates are used
EndDate
Days - Days ago from today, reporting.
Markets [comma delimited list] - Uses conventional names, Newegg, Newegg Business, etc.
UserIDs [comma delimited list] - Uses IDs from Users table
MeasureBy - Years, Months, Days, Hours, Minutes
ShowLegend [bool]

*/

 


//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import {useLocation} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import fetch from 'cross-fetch';

import Typography from '@mui/material/Typography';



//Datetime formatting
import Moment from 'react-moment';
import moment from 'moment';
import * as d3 from 'd3';

import { timeFormat } from 'd3-time-format';

import theme from '../../ui/theme';


//Nivo
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import { ResponsivePie } from '@nivo/pie';
import { getInitColorSchemeScript } from '@mui/system';



/* ##########################  Configuration  ########################## */


//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:11800,
};



const FlexChart = (props) => {
	//console.log(props);
	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");

	const chartwidthRef = useRef();

	/* ##########################  UseState Variables  ########################## */ 
	const [state, setState] = useState({
		chartid:props.chartid, //Database chart
		chartconfig:props.chartconfig, //Override Config
		chartloaded:false, //Allows declaring the data already exists or that it needs to be loaded
		hidechart:false, //Allows hiding whole chart if results are 0
		datapreloaded:props.datapreloaded, //Allows component to send data pre-loaded, no need for LoadChart()
		chartdata:[props.chartdata], //Allows data to be fed to this controller
		widthinitiated:false,
		chartuuid:uuidv4()
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//Default Axios Post Options
	const defaultpostoptions = {
		withCredentials: true,
		withXSRFToken: true,
		crossDomain: true,
		mode: "no-cors",
		timeout: 11800,
	};



	//Load Charts
	const LoadChart = () => {  
		axios.post(dbendpoint+"/charts/fetchchart", {chartconfig:localstate.chartconfig, chartid:localstate.chartid}, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					
					localstate.chartdata[0] = res.data.chartdata;

					//We can signal the parent to hide the chart
					/*
					if (state.chartconfig.ChartType === "checkinperdaybar") {
						localstate.hidechart = res.data.hidechart;
						if (localstate.hidechart){
							props.setShowCheckin(false);
						}
					}
					if (state.chartconfig.ChartType === "repairperdaybar") {
						localstate.hidechart = res.data.hidechart;
						if (localstate.hidechart){
							props.setShowRepair(false);
						}
					}
					*/
					


					//Try: Format start and end dates using dataset.
					//localstate.chartconfig.StartDate = moment(localstate.chartdata[0].Date, 'YYYY-MM-DD').valueOf();
					//localstate.chartconfig.EndDate = moment(localstate.chartdata[(localstate.chartdata.length-1)].Date, 'YYYY-MM-DD').valueOf();
					//localstate.chartconfig.StartDate = localstate.chartdata[0].Date;
					//localstate.chartconfig.EndDate = localstate.chartdata[(localstate.chartdata.length-1)].Date;


					//Let's try to format the start and finish dates:
					//Assume orderd by date already

					localstate.chartconfig = res.data.chartconfig;

					//Custom AxisLeftLegend - Used in bar chart...
					if (localstate.chartconfig.Measurement==="totalsalesperday"){
						localstate.chartconfig.AxisLeftLegend="Sales"
					}
					if (localstate.chartconfig.Measurement==="averageprice"){
						localstate.chartconfig.AxisLeftLegend="Average Price"
					}
					if (localstate.chartconfig.Measurement==="unitsperday"){
						localstate.chartconfig.AxisLeftLegend="Units"
					}

					
					//Override chartconfig - useful for setting YAxis range
					
					//Maybe we can avoid rendering chart and running function on data if we can detect hidechart here:
					if (!res.data.hidechart){
						localstate.chartloaded = true;
						localstate.hidechart = false;

					} else {
						localstate.chartloaded = true;
						localstate.hidechart = true;
					}
					
				} else {
					//No Status?
					localstate.chartconfig = {
						ChartType:""
					}
					localstate.chartloaded = true;
				}
				if (res.data.Status==="Failure"){
					localstate.chartloaded = true;
					localstate.hidechart = true;
				}

		
				UpdateState(localstate);
			
				
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}


	//This works OK, but let's try to do one that does tickmark every X

	const SetBarChartTickMarksOld = (chartwidth, tickmarkwidth, chartdata) => {
		//Get how many tickmarks we need. ChartWidth/TickMarkWidth rounded down.
		var x = chartwidth/tickmarkwidth;
		var tickmarkstotal = Math.floor(x); //Round down, add one for first tickmark.
		console.log("Tickmarks:"+tickmarkstotal);
		//Set first and last:
		//var tickmarks = [chartdata[0].x, chartdata[chartdata.length-1].x];
		var tickmarks = [];
		//tickmarkstotal -= 2; //We already handled 2 tickmarks! First and last.
		//Now get data jump (to next tickmark) per tickmarks
		var datainterval = chartdata.length/tickmarkstotal;
		console.log("Data interval:"+datainterval);

		var roundedinterval=0;
		//console.log("Tickmarks:"+tickmarkstotal);
		for (var i=0; i<tickmarkstotal; i++){
			//Add tickmark if it's not the same as the previous:
			if (tickmarks[tickmarks.length-1]!==chartdata[roundedinterval].x){
				tickmarks.push(chartdata[roundedinterval].x);
			}
			
			//Rounded intervale plus itself
			roundedinterval = Math.round(datainterval*(i+1));
			console.log("Interval: "+roundedinterval);
			//Break if tickmarks length is already at dataset length
			if (tickmarks.length===chartdata.length){
				break;
			}

		}
		//Add Last tickmark:
		if (tickmarks[tickmarks.length-1]!==chartdata[chartdata.length-1].x){
			tickmarks.push( chartdata[chartdata.length-1].x);
		}
		//Return tickmarks:
		localstate.chartconfig.tickValues = tickmarks;
		UpdateState(localstate);
	}



	const SetBarChartTickMarks = (chartwidth, tickmarkwidth, chartdata) => {
		console.log("Chart Width: "+chartwidth);
		console.log("Tick Mark Width: "+tickmarkwidth);


		//Lots of rounding up and down on last method.
		//Let's try for every X days and reconcile the last 2 tickmarks to see if we should skip 1.
		//We need to allow each date to fit tickmarkwidth.
		//We have chart width. That means each interval needs to be greater than tickmarkwidth.
		//Pixels divided by tickmarkwidth
		var intervals = chartwidth/tickmarkwidth;
		console.log("intervals: "+intervals);
		//Datapoints per interval.
		var datapointsperinterval = chartdata.length/intervals;
		//Round down
		datapointsperinterval = Math.floor(datapointsperinterval);
		var tickmarks = [];
		var disablefirsttick = false;
		//Go front to back so we always have the final tickmark at the end of the series.
		var finished = false;
		for (var i=0; (i<intervals || finished ); i++){
			//Skip first one unless there is only 1
			if (i===0 && disablefirsttick && chartdata.length>1){
				//Do nothing
			} else {
				console.log(((chartdata.length-1)-(i*datapointsperinterval)));
					tickmarks.push(chartdata[((chartdata.length-1)-(i*datapointsperinterval))].x);
			}
			
			//If we can still fit more tickmarks in the series, do it!
			if (typeof chartdata[((chartdata.length-1)-((i+1)*datapointsperinterval))] !== 'undefined'){
				finished=true;
			} else {
				finished = false;
			}
		}
		//Return tickmarks:
		localstate.chartconfig.tickValues = tickmarks;
		localstate.widthinitiated=true;
		//console.log("Finishing up.");
		UpdateState(localstate);
	}

	const ShortCutTickMarks = () => {
		//localstate.chartconfig.tickValues = tickmarks;
		localstate.widthinitiated=true;
		//console.log("Finishing up.");
		UpdateState(localstate);
	}


	
	const handleWindowSize = () => {
		const chartwidthelement = document.getElementById(state.chartuuid);
		//console.log(chartwidthelement);
		if (chartwidthelement){
			var w = chartwidthelement.offsetWidth;

			//If we have declared how wide we think the tickmark labels need to be...
			if (state.chartconfig?.TickMarkWidthPx) {
				//Get length of data:
				if (state.chartconfig.ChartType === "salesperdaybar") {
					var datalength = state.chartdata[0].data.length;
					//OK, So it looks like charts with 1 datapoint cause the SetBarChartTickMarks to break. When we see this again, do something.
					SetBarChartTickMarks(w, state.chartconfig.TickMarkWidthPx, state.chartdata[0].data);
				}
				if (state.chartconfig.ChartType === "checkinperdaybar") {
					var datalength = state.chartdata[0].data.length;
					SetBarChartTickMarks(w, state.chartconfig.TickMarkWidthPx, state.chartdata[0].data);
					//console.log(datalength);
				}
				if (state.chartconfig.ChartType === "repairperdaybar") {
					var datalength = state.chartdata[0].data.length;
					SetBarChartTickMarks(w, state.chartconfig.TickMarkWidthPx, state.chartdata[0].data);
					//console.log(datalength);
				}
				if (state.chartconfig.ChartType === "salesperdayline") {
					var datalength = state.chartdata.data.length;
					//console.log("Line:" + datalength);
				}
			}
		}		
	}


	//Runonce
	useEffect(() => {
		if (!state.chartloaded && !state.datapreloaded){
			//When props changes, this should fire. Props changing is typically the user feeding new chartconfig to chart.
			localstate.chartconfig = props.chartconfig;
			//handleWindowSize();

			//LoadChart also changes state! Repeats forever for some reason.
			LoadChart();
		}	
	},[state]);



	//We want to be able to take props from the parent container. However if the
	//parent controller reloads data, changes state, etc, it'll rerender your charts.
	useEffect(() => {
		var validatedoptions = true;
		//We can avoid doing anything if props aren't within range.
		//This below isn't useful though.
		//if (props.chartconfig.Days<1){validatedoptions=false;};	

		if ((state.chartloaded && validatedoptions) && !state.datapreloaded){
			console.log("Props changed and it was detected after chart was loaded..");
			localstate.chartloaded=false;
			localstate.chartconfig = props.chartconfig;
			//Try wthout:
			UpdateState(localstate);
		}
		
	},[props]);



	//Try without this 11/21/2022
	useEffect(() => {
		//Used for when a chart is first loaded - same as handling window resize - tickmarks need to be fixed.
		//Can we short-circuit this when there is no chart data?
		if (state.chartloaded && !state.hidechart){
			//console.log("Chart loaded, now rendering container.");
			handleWindowSize();
		}
	},[state.chartloaded]);


	window.addEventListener("resize", debounce(handleWindowSize, 500));


	const FlexLineChart = (props) => {
		return (
			<React.Fragment></React.Fragment>
		)
	}

	const FlexSalesLineChart = (props) => {
		return (
			<React.Fragment>
				{/* DIV FOR SIZE CONCERNS */}
				<div style={{height:"1px"}} ref={chartwidthRef} id={state.chartuuid}></div>
				{/* TITLE */}
				{(!state.chartconfig.DisableTitle) &&
					<div style={{ textAlign: (state.chartconfig.TitleAlign ? state.chartconfig.TitleAlign : "left") }}>
						<h2>
							{state.chartconfig.Title}
						</h2>
					</div>
				}
				<ResponsiveLine
					colors={state.chartconfig.colors}
					data={state.chartdata}
					xScale={{
						type: 'time',
						format: '%Y-%m-%d',
						useUTC: false,
						precision: 'day',
					}}
					xFormat="time:%Y-%m-%d"
					yScale={{
						type: 'linear',
						stacked: false,
					}}
					axisLeft={{
						legend: 'linear scale',
						legendOffset: 12
					}}
					axisBottom={{
						format: '%b %d',
						tickValues: 'every 20 days',
						legend: 'time scale',
						legendOffset: -12,
					}}
					curve='linear'
					enablePointLabel={false}
					//pointSymbol={CustomSymbol}
					pointLabelYOffset={-12} //This seems to fix error
					pointSize={5}
					pointBorderWidth={1}
					pointBorderColor={{
						from: 'color',
						modifiers: [['darker', 0.3]],
					}}
					useMesh={true}
					enableSlices={false}
					margin={{ top: 50, right: 110, bottom: 50, left: 60 }}

				/>
			</React.Fragment>

		)
	}

	const FlexSalesBarChart = (props) => {
		return (
			<>
			{(!state.hidechart) &&
			<div style={state.chartconfig.chartstyle}>
				{/* DIV FOR SIZE CONCERNS -  After render, we can then calculate width and tickmarks, then allow render:*/}
				<div style={{ height: "1px" }} ref={chartwidthRef} id={state.chartuuid}></div>

				{(state.widthinitiated) &&
					<>
						{/* TITLE */}
						{(!state.chartconfig.DisableTitle) &&
							<div style={{ textAlign: (state.chartconfig.TitleAlign ? state.chartconfig.TitleAlign : "left") }}>
								<h2>
									{state.chartconfig.Title}
								</h2>
							</div>
						}
						<ResponsiveBar
						padding={.1}
							indexScale={{ type: 'band', round: false }}
							colors={(bar) => bar.data.color}
							enableLabel={false}
							borderRadius={3}
							tooltip={function (bar) {
								return (
									<div style={{
										backgroundColor: "#FFF",
										WebkitBoxShadow: "0px 0px 15px -5px #000000",
										boxShadow: "0px 0px 15px -5px #000000",
										border: "1px solid #AAA",
										padding: "5px",
										textAlign: "center"
									}}>
										<Moment element='span' format="MMMM D, YYYY">{bar.data.x}</Moment>
										<br></br>
										<b>{(state.chartconfig.Measurement === "totalsalesperday" || state.chartconfig.Measurement === "averageprice") ? "$" : ""}
											{bar.data.y}</b>
									</div>
								)
							}}


							//data={sampledata}
							data={state.chartdata[0].data}
							keys={[
								'y'
							]}
							indexBy="x"


							xScale={{
								type: 'time',
								//format:"native"
								format: '%Y-%m-%d',
								//useUTC: false,
								precision: 'day',
							}}
							//xFormat="time:%Y-%m-%d"

							axisBottom={{
								tickValues: (state.chartconfig.tickValues ? state.chartconfig.tickValues : ""), //Can we use market days?
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								//legend: 'id',
								//legendPosition: 'middle',
								//legendOffset: 32
							}}


							axisTop={null}
							axisRight={null}

							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: state.chartconfig.AxisLeftLegend,
								legendPosition: 'middle',
								legendOffset: -60
							}}
							labelSkipWidth={12}
							labelSkipHeight={12}
							labelTextColor={{
								from: 'color',
								modifiers: [
									[
										'darker',
										1.6
									]
								]
							}}
							/*legends={[
								{
									dataFrom: 'keys',
									anchor: 'bottom-right',
									direction: 'column',
									justify: false,
									translateX: 120,
									translateY: 0,
									itemsSpacing: 2,
									itemWidth: 100,
									itemHeight: 20,
									itemDirection: 'left-to-right',
									itemOpacity: 0.85,
									symbolSize: 20,
									effects: [
										{
											on: 'hover',
											style: {
												itemOpacity: 1
											}
										}
									]
								}
							]}*/

							margin={state.chartconfig.margin}

						/>
					</>

				}
			</div>
			}
			</>
			
		)
	}

	const FlexCheckinBarChart = (props) => {
		const colors = { 
		'datasum0': '#3066a9', //Blue
		'datasum1': '#74b213', //Green
		'datasum2': '#ff811b', //Orange
		'datasum3': '#97e3d5', //Cyan
		'datasum4': '#ea4246', //Red
		'datasum5': '#e8c1a0', //Burnt Orange
		'datasum6': '#8c564b', //Brown
		'datasum7': '#f1e15b', //Yellow
		'datasum8': '#9467bd', //Purple
		};

		const getColor = bar => colors[bar.id];
		return (
				<React.Fragment>
				{/* DIV FOR SIZE CONCERNS - Render chart after width is initiated. */}
				<div style={{height:"1px"}} ref={chartwidthRef} id={state.chartuuid}></div>
				{(state.widthinitiated) &&
					<>
						{/* TITLE */}
						{(!state.chartconfig.DisableTitle) &&
							<div style={{ textAlign: (state.chartconfig.TitleAlign ? state.chartconfig.TitleAlign : "left") }}>
								<h2>
									{state.chartconfig.Title}
								</h2>
							</div>
						}
						<ResponsiveBar
							indexScale={{ type: 'band', round: false }}
							//colors={(bar)=>bar.data.color}
							colors={getColor}

							enableLabel={false}
							borderRadius={3}
							tooltip={function (bar) {
								return (
									<div style={{
										backgroundColor: "#FFF",
										WebkitBoxShadow: "0px 0px 15px -5px #000000",
										boxShadow: "0px 0px 15px -5px #000000",
										border: "1px solid #AAA",
										padding: "5px",
										textAlign: "center"
									}}>
										<Moment element='span' format="MMMM D, YYYY">{bar.data.x}</Moment>
										<br></br>
										<b>{(state.chartconfig.Measurement === "totalsalesperday" || state.chartconfig.Measurement === "averageprice") ? "$" : ""}
											<table>
												<tbody>
													{state.chartdata[0].users.map((user, index) => (
														<tr key={index}>
															<td style={{textAlign:"left"}}>
																{user.firstname} {user.lastname}:
															</td>
															<td style={{textAlign:"right", backgroundColor:colors['datasum'+index], minWidth:"40px"}}>
																{(bar.data[("datasum" + index)]) ? bar.data[("datasum" + index)] : "0"}
															</td>
														</tr>
													))}
												</tbody>
												
											</table>
											
										</b>
									</div>
								)
							}}


							//data={sampledata}
							data={state.chartdata[0].data}
							keys={state.chartdata[0].datakeys}
							indexBy="x"
							groupMode="grouped"


							xScale={{
								type: 'time',
								//format:"native"
								format: '%Y-%m-%d',
								//useUTC: false,
								precision: 'day',
							}}
							//xFormat="time:%Y-%m-%d"

							axisBottom={{
								tickValues: (state.chartconfig.tickValues ? state.chartconfig.tickValues : ['']),
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								//legend: 'id',
								//legendPosition: 'middle',
								//legendOffset: 32
							}}


							axisTop={null}
							axisRight={null}

							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: state.chartconfig.AxisLeftLegend,
								legendPosition: 'middle',
								legendOffset: -60
							}}
							labelSkipWidth={12}
							labelSkipHeight={12}
							labelTextColor={{
								from: 'color',
								modifiers: [
									[
										'darker',
										1.6
									]
								]
							}}
							/*legends={[
								{
									dataFrom: 'keys',
									anchor: 'bottom-right',
									direction: 'column',
									justify: false,
									translateX: 120,
									translateY: 0,
									itemsSpacing: 2,
									itemWidth: 100,
									itemHeight: 20,
									itemDirection: 'left-to-right',
									itemOpacity: 0.85,
									symbolSize: 20,
									effects: [
										{
											on: 'hover',
											style: {
												itemOpacity: 1
											}
										}
									]
								}
							]}*/

							margin={{ top: 50, right: 50, bottom: 50, left: 80 }}

						/>


					</>
				}

			</React.Fragment>			
		)
	}



	const FlexRepairBarChart = (props) => {
		const colors = { 
			'datasum0': '#3066a9', //Blue
			'datasum1': '#74b213', //Green
			'datasum2': '#ff811b', //Orange
			'datasum3': '#97e3d5', //Cyan
			'datasum4': '#ea4246', //Red
			'datasum5': '#e8c1a0', //Burnt Orange
			'datasum6': '#8c564b', //Brown
			'datasum7': '#f1e15b', //Yellow
			'datasum8': '#9467bd', //Purple
		};
		const getColor = bar => colors[bar.id];
		return (
				<React.Fragment>
				{/* DIV FOR SIZE CONCERNS - Render chart after width is initiated. */}
				<div style={{height:"1px"}} ref={chartwidthRef} id={state.chartuuid}></div>
				{(state.widthinitiated) &&
					<>
						{/* TITLE */}
						{(!state.chartconfig.DisableTitle) &&
							<div style={{ textAlign: (state.chartconfig.TitleAlign ? state.chartconfig.TitleAlign : "left") }}>
								<h2>
									{state.chartconfig.Title}
								</h2>
							</div>
						}
						<ResponsiveBar
							indexScale={{ type: 'band', round: false }}
							//colors={(bar)=>bar.data.color}
							colors={getColor}

							enableLabel={false}
							borderRadius={3}
							tooltip={function (bar) {
								return (
									<div style={{
										backgroundColor: "#FFF",
										WebkitBoxShadow: "0px 0px 15px -5px #000000",
										boxShadow: "0px 0px 15px -5px #000000",
										border: "1px solid #AAA",
										padding: "5px",
										textAlign: "center"
									}}>
										<Moment element='span' format="MMMM D, YYYY">{bar.data.x}</Moment>
										<br></br>
										<b>{(state.chartconfig.Measurement === "totalsalesperday" || state.chartconfig.Measurement === "averageprice") ? "$" : ""}
											<table>
												<tbody>
													{state.chartdata[0].users.map((user, index) => (
														<tr key={index}>
															<td style={{textAlign:"left"}}>
																{user.firstname} {user.lastname}:
															</td>
															<td style={{textAlign:"right", backgroundColor:colors['datasum'+index], minWidth:"40px"}}>
																{(bar.data[("datasum" + index)]) ? bar.data[("datasum" + index)] : "0"}
															</td>
														</tr>
													))}
												</tbody>
												
											</table>
										</b>
									</div>
								)
							}}


							//data={sampledata}
							data={state.chartdata[0].data}
							keys={state.chartdata[0].datakeys}
							indexBy="x"
							groupMode="grouped"


							xScale={{
								type: 'time',
								//format:"native"
								format: '%Y-%m-%d',
								//useUTC: false,
								precision: 'day',
							}}
							//xFormat="time:%Y-%m-%d"

							axisBottom={{
								tickValues: (state.chartconfig.tickValues ? state.chartconfig.tickValues : ['']),
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								//legend: 'id',
								//legendPosition: 'middle',
								//legendOffset: 32
							}}


							axisTop={null}
							axisRight={null}

							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: state.chartconfig.AxisLeftLegend,
								legendPosition: 'middle',
								legendOffset: -60
							}}
							labelSkipWidth={12}
							labelSkipHeight={12}
							labelTextColor={{
								from: 'color',
								modifiers: [
									[
										'darker',
										1.6
									]
								]
							}}
							/*legends={[
								{
									dataFrom: 'keys',
									anchor: 'bottom-right',
									direction: 'column',
									justify: false,
									translateX: 120,
									translateY: 0,
									itemsSpacing: 2,
									itemWidth: 100,
									itemHeight: 20,
									itemDirection: 'left-to-right',
									itemOpacity: 0.85,
									symbolSize: 20,
									effects: [
										{
											on: 'hover',
											style: {
												itemOpacity: 1
											}
										}
									]
								}
							]}*/

							margin={{ top: 50, right: 50, bottom: 50, left: 80 }}

						/>


					</>
				}

			</React.Fragment>			
		)
	}




	const FlexGradeBarChart = (props) => {
		//const colors = { 'datasum0': '#3066a9', 'datasum1': '#74b213', 'datasum2': '#ff811b' };
		//const getColor = bar => colors[bar.id];
		return (
				<React.Fragment>
					This

			</React.Fragment>			
		)
	}

	const FlexPieChartPreloadedData = (props) => {
		//const colors = { 'datasum0': '#3066a9', 'datasum1': '#74b213', 'datasum2': '#ff811b' };
		//const getColor = bar => colors[bar.id];
		var chartdatainput = state.chartdata[0];
		console.log(chartdatainput);
		const colors = { 
			'X': '#3066a9', //Blue
			'A': '#249a00', //Green - A
			'B': '#ccff00', //Yellow Green - B
			'C': '#fff600', //Yellow - C
			'Repair': '#ffd200', //Yellow Orange - Repair
			'Parts': '#ff0000', //Red - Parts
			'Scrap': '#404040', //Grey - Scrap
			'Ungraded': '#c6c6c6', //Light Grey -  Ungraded
			'Unknown': '#9467bd', //Purple
			};
	
			const getColor = bar => colors[bar.id];

		return (
			<React.Fragment>
				{/* DIV FOR SIZE CONCERNS - Render chart after width is initiated. */}
				<div style={state.chartconfig.chartstyle}>
					<div style={{ height: "1px" }} ref={chartwidthRef} id={state.chartuuid}></div>

					<>
						{/* TITLE */}
						{(!state.chartconfig.DisableTitle) &&
							<div style={{ textAlign: (state.chartconfig.TitleAlign ? state.chartconfig.TitleAlign : "left") }}>
								<h2>
									{state.chartconfig.Title}
								</h2>
							</div>
						}
						<ResponsivePie
							data={chartdatainput}
							colors={getColor}
							enableArcLabels={true}
							enableArcLinkLabels={false}
							startAngle={-90}
							endAngle={270}

							//Sometimes things run off the view, leave some margin.
							margin={{ top: 40, right: 80, bottom: 20, left: 20 }}
							innerRadius={0.5}
							padAngle={0.7}
							cornerRadius={0}
							activeOuterRadiusOffset={8}
							borderWidth={1}
							borderColor={{
								from: 'color',
								modifiers: [
									[
										'darker',
										0.2
									]
								]
							}}
							arcLinkLabelsSkipAngle={10}
							arcLinkLabelsTextColor="#333333"
							arcLinkLabelsThickness={2}
							arcLinkLabelsColor={{ from: 'color' }}
							arcLabelsSkipAngle={10}
							arcLabelsTextColor={{
								from: 'color',
								modifiers: [
									[
										'darker',
										2
									]
								]
							}}
							defs={[
								{
									id: 'dots',
									type: 'patternDots',
									background: 'inherit',
									color: 'rgba(255, 255, 255, 0.3)',
									size: 4,
									padding: 1,
									stagger: true
								},
								{
									id: 'lines',
									type: 'patternLines',
									background: 'inherit',
									color: 'rgba(255, 255, 255, 0.3)',
									rotation: -45,
									lineWidth: 6,
									spacing: 10
								}
							]}
							fill={[
								{
									match: {
										id: 'ruby'
									},
									id: 'dots'
								},
								{
									match: {
										id: 'c'
									},
									id: 'dots'
								},
								{
									match: {
										id: 'go'
									},
									id: 'dots'
								},
								{
									match: {
										id: 'python'
									},
									id: 'dots'
								},
								{
									match: {
										id: 'scala'
									},
									id: 'lines'
								},
								{
									match: {
										id: 'lisp'
									},
									id: 'lines'
								},
								{
									match: {
										id: 'elixir'
									},
									id: 'lines'
								},
								{
									match: {
										id: 'javascript'
									},
									id: 'lines'
								}
							]}
							legends={[
								{
									anchor: 'top-right',
									direction: 'column',
									justify: false,
									translateX: 20,
									translateY: 10,
									itemsSpacing: 2,
									itemWidth: 100,
									itemHeight: 18,
									itemTextColor: '#999',
									itemDirection: 'left-to-right',
									itemOpacity: 1,
									symbolSize: 18,
									symbolShape: 'circle',
									effects: [
										{
											on: 'hover',
											style: {
												itemTextColor: '#000'
											}
										}
									]
								}
							]}

						/>


					</>
				</div>
			
			

		</React.Fragment>			
	)
	}

	return (
		
		(state.chartloaded || state.datapreloaded) &&
			<React.Fragment>
				{(state.chartconfig.ChartType==='linechart') &&
					<FlexLineChart chart={state}/>
				}
				{(state.chartconfig.ChartType==='salesperdayline') &&
					<FlexSalesLineChart chart={state}/>
				}
				{(state.chartconfig.ChartType==='salesperdaybar') &&
					<FlexSalesBarChart chart={state}/>
				}
				{(state.chartconfig.ChartType==='checkinperdaybar') &&
					<FlexCheckinBarChart chart={state}/>
				}
				{(state.chartconfig.ChartType==='repairperdaybar') &&
					<FlexRepairBarChart chart={state}/>
				}
				{(state.chartconfig.ChartType==='inventoryitemgrades') &&
					<FlexGradeBarChart chart={state}/>
				}
				{(state.chartconfig.ChartType==='piechartpreloadeddata') &&
					<FlexPieChartPreloadedData chart={state}/>
				}
			</React.Fragment>
		
    );
}

export default FlexChart;



	{/*
				<Typography variant="h6" gutterBottom>{state.chartconfig['Title']}</Typography>
				<ResponsiveContainer width="100%" height="100%">
					
					<BarChart
						width={500}
						height={300}
						data={props.chart.chartdata}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						
						<XAxis dataKey="Date" scale="time" type="number" domain={[Moment('2018-01-01').format('YYYY-MM-DD'),Moment('2018-01-02').format('YYYY-MM-DD')]} />


						domain={['auto', 'auto']}

						domain={[state.chartconfig.StartDate,state.chartconfig.EndDate]}

						tickFormatter={(unixTimestamp) => moment(unixTimestamp).format("YYYY-MM-DD")}

						tickFormatter={v => moment.utc(v).format('YYYY-MM-DD')}

				
						<XAxis 
						dataKey="Date" 
						type="number"
						scale="time"
						tickFormatter={(unixTimestamp) => moment(unixTimestamp).format("YYYY-MM-DD")}
						
						
						domain={[state.chartconfig.StartDate,state.chartconfig.EndDate]}
					
						 allowDataOverflow={true}
						/>
						<YAxis type="number" domain={[0, state.chartconfig['max']]} />
						<Tooltip />
						{(state.chartconfig['ShowLegend']) &&
							<Legend />
						}
						<Bar dataKey="WeekendSales" stackId="a" fill="#01579B" />
						<Bar dataKey="WeekdaySales" stackId="a" fill="#74b213" />
					</BarChart>
				</ResponsiveContainer>	




								xScale={{ format: "%Y-%m-%d", type: "time", precision:"day" }}

								axisBottom={{ tickValues:[state.chartconfig.StartDate, state.chartconfig.EndDate]}}

	*/}