import { createTheme, adaptV4Theme } from '@mui/material/styles';


const themeName = 'deluxetheme1';
const palette = {
  primary: { main: '#01579B' },
  secondary: { main: '#ECEFF1' }
};

let theme = createTheme({
	palette:{
		primary: { main: '#01579B' },
  		secondary: { main: '#DFDFDF' }
	},
	simpleselect:{
		"& .MuiSelect-select": { padding: "0,50" }
	}
	
}, themeName);

export default theme;

//I suspect adaptV4Theme may not be needed.
//export default createTheme({ palette, themeName });
