import {useLocation} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//let endpoint = "http://localhost:8080/api";
var hostbase = process.env.REACT_APP_ANGULAR_LEGACY_BASE;


//MainPage receives props
const Checkin = props => {
	document.title="Checkin";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Inventory"));
	dispatch(setCurrentMenuItem("/checkin"));

	//New, Try url params to shoot the user into the right checkin page
	const params = new URLSearchParams(useLocation().search);
	var lotparameter = params.get('lotid');
	console.log("LOTID: "+lotparameter);
	if (lotparameter){
		lotparameter = "&lotid="+lotparameter;
	} else {
		lotparameter="";
	}

	//Hide Scroll Bars for injected content.
	return (
		<div style={{ textAlign: 'center', overflow: "hidden"}}>
			<div style={{ height: (window.innerHeight - 35) + "px" }}>
				<iframe src={hostbase+"/legacy/checkin?react=true"+lotparameter} style={{ width: "100%", border:"none" }} height={window.innerHeight - 35}></iframe>
			</div>

		</div>
	)
}


export default Checkin;