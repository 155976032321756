import React, {useState, createContext} from 'react';


export const ErrorContext = createContext();

// This context provider is passed to any component requiring the context
export const ErrorProvider = ({ children }) => {
	
	//Current Error
	const [currenterror, setCurrenterror] = useState({
		errmsg:'Default Current Error',
		errshow:false,
		errtimeout:2,
		errtype:'warning',
		link:"/error/example",
		linktext:"Link Example",
	});

	//Save old errors - Look at these later on? Experimental!
	const [preverrors, setPreverrors] = useState([]);

	const NewError = (newerr) => {
		//Move current error to previous errors
		//Works to append!
		//setPreverrors(preverrors => preverrors.concat({errmsg: currenterror.errmsg, errshow: currenterror.errshow}));
		//Works to prepend!
		setPreverrors(preverrors => [{errmsg: currenterror.errmsg, errshow: currenterror.errshow}].concat(preverrors));
		setCurrenterror(newerr);
		//Timeout our error
		setTimeout(() => {
			setCurrenterror({...newerr, errshow:false});
		}, newerr.errtimeout*1000);
	};

	//Try hide function
	const HideError = () =>{
		setPreverrors(preverrors => [{errmsg: currenterror.errmsg, errshow: currenterror.errshow}].concat(preverrors));
		setCurrenterror({...currenterror, errshow:false});
	}

	//Return Provider
	return (
	  <ErrorContext.Provider
		value={{
		  currenterror,
		  NewError,
		  HideError,
		  preverrors
		}}
	  >
		{children}
	  </ErrorContext.Provider>
	);
  };