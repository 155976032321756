//Order Inspection Table

//About: This is a grid with inspections with a heavy dependence on the Inspection redux feature - Dual purpose!

//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
} from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';
//New Error Message
import { NewErrorMessage } from '../../features/error/NewErrorMessage';
import {
	newErrorMessage,
	setErrorTimeout
} from '../../features/error/errormessageSlice';
//Inspection Slice
import { Inspection } from '../../features/orders/Inspection';
import {
	incrementByAmount
} from '../../features/orders/inspectionSlice';


//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';
import '../../css/highlight.css';

//Datetime formatting
import Moment from 'react-moment';
import 'moment-timezone';

//MaterialUI
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

//Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import HelpIcon from '@mui/icons-material/Help';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


//Datetime Pickers
//Try new moment adapter:
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

//DB
var dbendpoint = process.env.REACT_APP_DB_API4;
var hostbase = process.env.REACT_APP_BASE;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 300000,
};


const InspectionView = (props) => {
	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole, userName } = appContext;
	//Receives order (with orderitems and manifest?)
	const [order, updateOrder] = useState(props.order);

	//Clone State! Useful for updates.
	let orderstate = Object.assign({}, order);

	const [viewinit, setViewInit] = useState(false)

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");

	//To Do: Reload Order Function


	const orderitemRefs = useRef([]);
	const assigneditemRefs = useRef([]);
	//Generally we'll create refs by ItemID
	//orderitem.current[orderitem.ItemID]


	//Have a need to init inspection
	//DB Reloads
	useEffect(() => {
		if (!viewinit) {
			EvaluateItems();
			setViewInit(true);
			//console.log("Eval");
		} else {
			//Rehighlight CurrentOrderItem
			if (currentorderitemid) {
				for (var i = 0; i < orderstate.orderitems.length; i++) {
					if (orderstate.orderitems[i].ItemID === currentorderitemid) {
						HighlightItem(orderstate.orderitems[i], true); //True=refresh
					}
				}
			}
			//console.log("Ignore DB Reload.");
			//Boostrap a resizer for the columns:
			//InitColumnResizers();

			//Right Here, we should try to recover the selected
		}
	},);

	//Evaluate Items
	const EvaluateItems = () => {
		for (var i = 0; i < orderstate.orderitems.length; i++) {
			//Set Refs:
			orderitemRefs.current["container" + orderstate.orderitems[i].ItemID] = React.createRef();
			orderitemRefs.current["selection" + orderstate.orderitems[i].ItemID] = React.createRef();
			orderitemRefs.current["working" + orderstate.orderitems[i].ItemID] = React.createRef();

			//console.log("Order Number: " + orderstate.OrderID);

			if (orderstate.orderitems[i].sku !== null) {
				//Product
				//Some skus don't have a product assigned....
				if (orderstate.orderitems[i].sku.product !== null) {
					orderstate.orderitems[i].Product = orderstate.orderitems[i].sku.product.Name;
				}


				//Match ID
				for (var m = 0; m < orderstate.orderitems[i].manifest.length; m++) {
					if (orderstate.orderitems[i].manifest[m].specs !== null) {
						orderstate.orderitems[i].manifest[m].specs.productmatch = 1;
						for (var n = 0; n < orderstate.orderitems[i].manifest[m].specs.modelalias.length; n++) {
							if (orderstate.orderitems[i].sku.ProductID === orderstate.orderitems[i].manifest[m].specs.modelalias[n].ProductID) {
								//console.log("ProductID Match: " + orderstate.orderitems[i].sku.ProductID);
								orderstate.orderitems[i].manifest[m].specs.productmatch = 3;
							}
						}
					}

				}


				//Marshal in component names for easier render
				//Checks by Sku Component ID comparison to Alias Component IDs.
				for (var j = 0; j < orderstate.orderitems[i].sku.skucomponents.length; j++) {
					//CPU
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "CPU") {
						orderstate.orderitems[i].CPU = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
						//console.log("Checking CPU: " + orderstate.orderitems[i].CPU);
						for (var m = 0; m < orderstate.orderitems[i].manifest.length; m++) {
							if (orderstate.orderitems[i].manifest[m].specs !== null) {
								orderstate.orderitems[i].manifest[m].specs.cpumatch = 1; //Assume no match just yet
								for (var n = 0; n < orderstate.orderitems[i].manifest[m].specs.cpualias.length; n++) {
									//console.log("With this: " + orderstate.orderitems[i].manifest[m].specs.cpualias[n].RawValue);
									if (orderstate.orderitems[i].manifest[m].specs.cpualias[n].ComponentID === orderstate.orderitems[i].sku.skucomponents[j].details.ID) {
										//console.log("CPU Match: " + orderstate.orderitems[i].CPU);
										orderstate.orderitems[i].manifest[m].specs.cpumatch = 3;
									}
								}
							}

						}
					}
					//RAM
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "RAM") {
						orderstate.orderitems[i].RAM = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
						//console.log("Checking RAM: " + orderstate.orderitems[i].RAM);
						for (var m = 0; m < orderstate.orderitems[i].manifest.length; m++) {
							if (orderstate.orderitems[i].manifest[m].specs !== null) {
								orderstate.orderitems[i].manifest[m].specs.rammatch = 1; //Assume no match just yet
								for (var n = 0; n < orderstate.orderitems[i].manifest[m].specs.ramalias.length; n++) {
									//console.log("With this: " + orderstate.orderitems[i].manifest[m].specs.ramalias[n].RawValue);
									if (orderstate.orderitems[i].manifest[m].specs.ramalias[n].ComponentID === orderstate.orderitems[i].sku.skucomponents[j].details.ID) {
										//console.log("RAM Match: " + orderstate.orderitems[i].RAM);
										orderstate.orderitems[i].manifest[m].specs.rammatch = 3;
									}
								}
							}

						}
					}
					//HDD1
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "Hard Drive") {
						orderstate.orderitems[i].HDD = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
						//console.log("Checking HDD: " + orderstate.orderitems[i].HDD);
						for (var m = 0; m < orderstate.orderitems[i].manifest.length; m++) {
							if (orderstate.orderitems[i].manifest[m].specs !== null) {
								orderstate.orderitems[i].manifest[m].specs.hddmatch = 1; //Assume no match just yet
								for (var n = 0; n < orderstate.orderitems[i].manifest[m].specs.hddalias.length; n++) {
									//console.log("With this: " + orderstate.orderitems[i].manifest[m].specs.hddalias[n].RawValue);
									if (orderstate.orderitems[i].manifest[m].specs.hddalias[n].ComponentID === orderstate.orderitems[i].sku.skucomponents[j].details.ID) {
										//console.log("HDD Match: " + orderstate.orderitems[i].HDD);
										orderstate.orderitems[i].manifest[m].specs.hddmatch = 3;
									}
								}
							}

						}
					}
					//Optical
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "Optical") {
						orderstate.orderitems[i].Optical = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
						//console.log("Checking Optical: " + orderstate.orderitems[i].Optical);
						for (var m = 0; m < orderstate.orderitems[i].manifest.length; m++) {
							if (orderstate.orderitems[i].manifest[m].specs !== null) {
								orderstate.orderitems[i].manifest[m].specs.opticalmatch = 1; //Assume no match just yet
								//Since aliases are referenced by Raw Values such as specs.OpticalDesc, we can assume a blank value simply isn't detected.
								if (orderstate.orderitems[i].manifest[m].specs.OpticalDesc === "") {
									orderstate.orderitems[i].manifest[m].specs.OpticalDesc = "Not Detected";
									orderstate.orderitems[i].manifest[m].specs.opticalmatch = 2; //Warning 
								} else {
									for (var n = 0; n < orderstate.orderitems[i].manifest[m].specs.opticalalias.length; n++) {
										//console.log("With this: " + orderstate.orderitems[i].manifest[m].specs.opticalalias[n].RawValue);
										if (orderstate.orderitems[i].manifest[m].specs.opticalalias[n].ComponentID === orderstate.orderitems[i].sku.skucomponents[j].details.ID) {
											//console.log("Optical Match: " + orderstate.orderitems[i].Optical);
											orderstate.orderitems[i].manifest[m].specs.opticalmatch = 3;
										}
									}
								}

							}

						}
					}
					//Webcam
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "Webcam") {
						orderstate.orderitems[i].Webcam = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
					}
					//Resolution
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "Resolution") {
						orderstate.orderitems[i].Resolution = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
						//console.log("Checking Resolution: " + orderstate.orderitems[i].Resolution);
						for (var m = 0; m < orderstate.orderitems[i].manifest.length; m++) {
							if (orderstate.orderitems[i].manifest[m].specs !== null) {
								orderstate.orderitems[i].manifest[m].specs.resolutionmatch = 1; //Assume no match just yet
								for (var n = 0; n < orderstate.orderitems[i].manifest[m].specs.resolutionalias.length; n++) {
									//console.log("With this: " + orderstate.orderitems[i].manifest[m].specs.resolutionalias[n].RawValue);
									if (orderstate.orderitems[i].manifest[m].specs.resolutionalias[n].ComponentID === orderstate.orderitems[i].sku.skucomponents[j].details.ID) {
										//console.log("Resolution Match: " + orderstate.orderitems[i].Resolution);
										orderstate.orderitems[i].manifest[m].specs.resolutionmatch = 3;
									}
								}
							}

						}
					}
					//COA
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "COA") {
						orderstate.orderitems[i].COA = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
						//console.log("Checking COA: " + orderstate.orderitems[i].COA);
						for (var m = 0; m < orderstate.orderitems[i].manifest.length; m++) {
							if (orderstate.orderitems[i].manifest[m].specs !== null) {
								orderstate.orderitems[i].manifest[m].specs.coamatch = 1; //Assume no match just yet
								for (var n = 0; n < orderstate.orderitems[i].manifest[m].specs.coaalias.length; n++) {
									//console.log("With this: " + orderstate.orderitems[i].manifest[m].specs.coaalias[n].RawValue);
									if (orderstate.orderitems[i].manifest[m].specs.coaalias[n].ComponentID === orderstate.orderitems[i].sku.skucomponents[j].details.ID) {
										//console.log("COA Match: " + orderstate.orderitems[i].COA);
										orderstate.orderitems[i].manifest[m].specs.coamatch = 3;
									}
								}
							}

						}
					}
					//BodyGrade
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "Body Grade") {
						orderstate.orderitems[i].BodyGrade = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
					}
					//LCD Grade
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "LCD Grade") {
						orderstate.orderitems[i].LCDGrade = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
					}
					//Grade
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "Grade") {
						orderstate.orderitems[i].Grade = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
					}
					//Other Features
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "Other Features") {
						orderstate.orderitems[i].Features = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
					}
					//Add-ons
					if (orderstate.orderitems[i].sku.skucomponents[j].ComponentType === "Add-on") {
						orderstate.orderitems[i].Features = orderstate.orderitems[i].sku.skucomponents[j].details.Name;
					}

				}

			}

		
			for (var m = 0; m < orderstate.orderitems[i].manifest.length; m++) {
				if (orderstate.orderitems[i].manifest[m].specs !== null){
					if (orderstate.orderitems[i].manifest[m].specs.productmatch === 1 ||
						orderstate.orderitems[i].manifest[m].specs.cpumatch === 1 ||
						orderstate.orderitems[i].manifest[m].specs.rammatch === 1 ||
						orderstate.orderitems[i].manifest[m].specs.hddmatch === 1 ||
						orderstate.orderitems[i].manifest[m].specs.opticalmatch === 1 ||
						orderstate.orderitems[i].manifest[m].specs.resolutionmatch === 1 ||
						orderstate.orderitems[i].manifest[m].specs.coamatch === 1
					) {
						//console.log("Resolved: " + orderstate.orderitems[i].manifest[m].Resolved);
						orderstate.orderitems[i].manifest[m].defectflag = true;
					}
				}
			}
		}
		
		updateOrder(orderstate);
	}



	const [currentorderitemid, setCurrentOrderItem] = useState(null);


	const HighlightItem = (orderitem, refreshbool) => {
		let itemid = orderitem.ItemID;
		//If already selected, unselect
		if ((itemid === currentorderitemid) && !refreshbool) {
			orderitemRefs.current["container" + currentorderitemid].classList.add(classes.defaultitemcontainer);
			orderitemRefs.current["container" + currentorderitemid].classList.remove(classes.selecteditemcontainer);
			orderitemRefs.current["selection" + currentorderitemid].classList.remove("bluehighlight");
			for (var i = 0; i < orderitem.manifest.length; i++) {
				//console.log("Here:" + orderitem.manifest[i].ID);
				assigneditemRefs.current["itemcontainer" + orderitem.manifest[i].ID].classList.add(classes.defaultitemcontainer);
				assigneditemRefs.current["itemcontainer" + orderitem.manifest[i].ID].classList.remove(classes.selecteditemcontainer);
				assigneditemRefs.current["itemselection" + orderitem.manifest[i].ID].classList.remove("greenhighlight");
			}
			setCurrentOrderItem(null);
			//console.log("This");

		} else {
			//Reset all before setting next.
			if (currentorderitemid !== null) {
				orderitemRefs.current["container" + currentorderitemid].classList.add(classes.defaultitemcontainer);
				orderitemRefs.current["container" + currentorderitemid].classList.remove(classes.selecteditemcontainer);
				orderitemRefs.current["selection" + currentorderitemid].classList.remove("bluehighlight");
				for (var i = 0; i < orderstate.orderitems.length; i++) {
					for (var j = 0; j < orderstate.orderitems[i].manifest.length; j++) {
						assigneditemRefs.current["itemcontainer" + orderstate.orderitems[i].manifest[j].ID].classList.add(classes.defaultitemcontainer);
						assigneditemRefs.current["itemcontainer" + orderstate.orderitems[i].manifest[j].ID].classList.remove(classes.selecteditemcontainer);
						assigneditemRefs.current["itemselection" + orderstate.orderitems[i].manifest[j].ID].classList.remove("greenhighlight");
					}
				}
			}
			//Setting
			orderitemRefs.current["container" + itemid].classList.add(classes.selecteditemcontainer);
			orderitemRefs.current["container" + itemid].classList.remove(classes.defaultitemcontainer);
			orderitemRefs.current["selection" + itemid].classList.add("bluehighlight");
			orderitemRefs.current["selection" + itemid].classList.add("in");
			//orderitemRefs.current[itemid].classList.remove(classes.whiteborder);
			for (var i = 0; i < orderitem.manifest.length; i++) {
				assigneditemRefs.current["itemcontainer" + orderitem.manifest[i].ID].classList.remove(classes.defaultitemcontainer);
				assigneditemRefs.current["itemcontainer" + orderitem.manifest[i].ID].classList.add(classes.selecteditemcontainer);
				assigneditemRefs.current["itemselection" + orderitem.manifest[i].ID].classList.add("greenhighlight");
				assigneditemRefs.current["itemselection" + orderitem.manifest[i].ID].classList.add("in");
			}
			setCurrentOrderItem(itemid);
		}
	}

	//Send potential specs to DB for assignment to order item
	const AssignManifestItem = (manifestitem) => {
		//console.log(manifestitem);
		if (currentorderitemid) {
			//console.log("Current Order Item ID: " + currentorderitemid);
			//console.log("Current Manifest Item ID: " + manifestitem.ID);
			var potentialspecid = null;
			if (manifestitem.potentialspecs.length > 0) {
				//console.log("Current Potential Spec ID: " + manifestitem.potentialspecs[manifestitem.currentspecindex].ID);
				potentialspecid = manifestitem.potentialspecs[manifestitem.currentspecindex].ID;
			}

			//We'll need to reload the order?

			const postdata = {
				internalid: orderstate.InternalID,
				orderitemid: currentorderitemid,
				manifestitemid: manifestitem.ID,
				specid: potentialspecid

			};
			axios.post(dbendpoint + "/orderinspection/assignmanifestitem", postdata, defaultpostoptions).then(res => {
				//API should be setup to send 200 response with status. Merge paginated requests.
				if (res.status === 200) {
					//If ValidateUser() fails to verify user, it sends back 'login' error. 
					if (res.data.Status === "login") {
						//Not logged in. Reload page causes redirect to /login
						window.location.reload(false);
					}
					//All new API calls should return a status.
					if (res.data.Status === "Success") {
						console.log(res);
						orderstate = res.data.item;
						updateOrder(orderstate);
						setViewInit(false);
					}
					if (res.data.Status === "Failure") {
						//Failure error
						//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
					}
				} else {
					//Non-200 message from server.
					//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
				}
			});
		} else {
			//alert("No order item selected!");
		}
	}

	const AssignRemaining = (orderitem) => {

		orderitemRefs.current["working" + orderitem.ItemID].classList.remove(classes.hidden); //This automatically resets when the work is finished because the component is rerendered.

		var manifestarray = [];
		var specid = null;
		for (var i = 0; i < orderstate.unassignedmanifest.length; i++) {
			specid = null;
			if (orderstate.unassignedmanifest[i].potentialspecs!==null){
				if (orderstate.unassignedmanifest[i].potentialspecs.length > 0){
					specid = orderstate.unassignedmanifest[i].potentialspecs[orderstate.unassignedmanifest[i].currentspecindex].ID
				}
			}
			manifestarray.push({
				internalid: orderstate.InternalID,
				orderitemid: orderitem.ItemID,
				manifestitemid: orderstate.unassignedmanifest[i].ID,
				specid: specid
			})
		}

		const postdata = {
			internalid: orderstate.InternalID,
			manifestitems:manifestarray
		};
		
		axios.post(dbendpoint + "/orderinspection/assignbulk", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//console.log(res);
					orderstate = res.data.item;
					updateOrder(orderstate);
					setViewInit(false);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
		
	}

	const UnassignItem = (manifestitem) => {
		//console.log(manifestitem);

		//console.log("Current Order Item ID: "+currentorderitemid);
		//console.log("Current Manifest Item ID: " + manifestitem.ID);
		//console.log("Current Potential Spec ID: "+manifestitem.potentialspecs[manifestitem.currentspecindex].ID);
		//We'll need to reload the order?

		const postdata = {
			internalid: orderstate.InternalID,
			//orderitemid: currentorderitemid,
			manifestitemid: manifestitem.ID,
			//specid: manifestitem.potentialspecs[manifestitem.currentspecindex].ID

		};
		axios.post(dbendpoint + "/orderinspection/unassignmanifestitem", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//console.log(res);
					orderstate = res.data.item;
					updateOrder(orderstate);
					setViewInit(false);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});

	}

	const SetCurrentSpec = (manifestitemindex, specindex) => {
		orderstate.unassignedmanifest[manifestitemindex].currentspecindex = specindex;
		updateOrder(orderstate);
	}

	const ToggleDetails = (orderitemindex, amanifestitemindex) => {
		//console.log("IDs: " + orderitemindex, amanifestitemindex);
		orderstate.orderitems[orderitemindex].manifest[amanifestitemindex].expanddetails = !orderstate.orderitems[orderitemindex].manifest[amanifestitemindex].expanddetails;
		updateOrder(orderstate);
	}

	const ToggleAliasList = (assignedmanifestitem, aliastype) => {
		if (aliastype==="product"){
			assignedmanifestitem.showproductalias = !assignedmanifestitem.showproductalias;
		}
		if (aliastype==="cpu"){
			assignedmanifestitem.showcpualias = !assignedmanifestitem.showcpualias;
		}
		if (aliastype==="ram"){
			assignedmanifestitem.showramalias = !assignedmanifestitem.showramalias;
		}
		if (aliastype==="hdd"){
			assignedmanifestitem.showhddalias = !assignedmanifestitem.showhddalias;
		}
		if (aliastype==="resolution"){
			assignedmanifestitem.showresolutionalias = !assignedmanifestitem.showresolutionalias;
		}
		if (aliastype==="optical"){
			assignedmanifestitem.showopticalalias = !assignedmanifestitem.showopticalalias;
		}
		if (aliastype==="coa"){
			assignedmanifestitem.showcoaalias = !assignedmanifestitem.showcoaalias;
		}
		updateOrder(orderstate);
	}

	
	const RemoveAlias = (alias) => {
		const postdata = {
			alias:alias,
			internalorderid:orderstate.InternalID
		};
		axios.post(dbendpoint + "/orderinspection/removealias", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//console.log(res);
					orderstate = res.data.item;
					updateOrder(orderstate);
					setViewInit(false);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}


	const AddAlias = (aliastype, orderitem, rawvalue, componenttype) => {
		//console.log(aliastype);
		//console.log(orderitem.sku.product.Name);
		//console.log(rawvalue);
		// 'AliasName'=>$request['aliasvalues.AliasName'],
		// 			'AliasType'=>$request['aliasvalues.AliasType'],
		// 			'RawValue'=>$request['aliasvalues.RawValue'],
		// 			'ProductID'=>$request['aliasvalues.ProductID'],
		// 			'ComponentID'=>$request['aliasvalues.ComponentID']


		//Components will need to be matched to Component IDs, but Model does not
		var componentid = 0;
		var productid = 0;
		var aliasname = "";
		if (aliastype!=="Model"){
			for (var i=0; i<orderitem.sku.skucomponents.length; i++){
				if (orderitem.sku.skucomponents[i].ComponentType===componenttype){
					componentid = orderitem.sku.skucomponents[i].ComponentID;
					aliasname = orderitem.sku.skucomponents[i].details.Name;
				}
			}
		} else {
			productid = orderitem.sku.product.ProductID;
			aliasname = orderitem.sku.product.Name;
		}

		const postdata = {
			aliasvalues:{
				AliasName:aliasname,
				AliasType:aliastype,
				RawValue:rawvalue,
				ProductID: productid,
				ComponentID: componentid,
			},		
			internalorderid:orderstate.InternalID
		};



		axios.post(dbendpoint + "/orderinspection/addalias", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//console.log(res);
					orderstate = res.data.item;
					updateOrder(orderstate);
					setViewInit(false);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}

	const ResolveManifestItem = (assignedmanifestitem) => {

		const postdata = {
			ID:assignedmanifestitem.ID,
			internalorderid:orderstate.InternalID
		};



		axios.post(dbendpoint + "/orderinspection/resolveitem", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//console.log(res);
					orderstate = res.data.item;
					updateOrder(orderstate);
					setViewInit(false);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}


	const UnresolveManifestItem = (assignedmanifestitem) => {

		const postdata = {
			ID:assignedmanifestitem.ID,
			internalorderid:orderstate.InternalID
		};



		axios.post(dbendpoint + "/orderinspection/unresolveitem", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					//console.log(res);
					orderstate = res.data.item;
					updateOrder(orderstate);
					setViewInit(false);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}





	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			{(viewinit) &&
				<div style={{ maxWidth: "1130px" }}>
					{/* 	##########    Order Items that HAVE asset assignments, then another column with those manifest items  #########
					May be side-by-side with unassigned manifest items (the next section)
					*/}
					{orderstate.orderitems.map((orderitem, index) => {
						return (
							<React.Fragment key={"K4" + orderitem.ItemID}>
								{(orderitem.manifest.length > 0) &&
									<React.Fragment>
										<div style={{ display: "inline-block", width: "50%", verticalAlign: "top" }}>
											<div className={classes.defaultitemcontainer} ref={el => orderitemRefs.current["container" + orderitem.ItemID] = el}>
												<div key={"K1" + orderitem.ItemID} className={classes.inspectionitem}
													ref={el => orderitemRefs.current["selection" + orderitem.ItemID] = el}>
													<Grid container justifyContent="space-between"
														className={classes.inspectionitemheader + " " + classes.hoverunit}
														onClick={() => HighlightItem(orderitem, false)}
													>
														<Grid item xs={9}><b>Sku: {orderitem.Code}</b></Grid>
														<Grid item xs={3} style={{ textAlign: "right" }}><div className={parseInt(orderitem.FilledQty)>orderitem.Quantity ? classes.inspectionoverfull : ""}>
														<b>Filled: {orderitem.FilledQty} / {orderitem.Quantity}</b>
														</div></Grid>
													</Grid>
													<div className={classes.inspectionitemdetails}>
														<div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", paddingBottom: "5px" }} title={orderitem.sku.Title}>{orderitem.sku.Title}</div>
														<div style={{ paddingLeft: "5px" }}>
															<table className={classes.inspectionspecs} style={{width:"100%"}}>
																<tbody>
																	<tr>
																	<td style={{width:"20%"}}>
																			Product:
																		</td>
																		<td>
																			{(orderitem.sku.product) ? orderitem.sku.product.Name : "None"}
																		</td>
																	</tr>
																	{(orderitem.CPU) &&
																		<tr>
																			<td>
																				CPU:
																			</td>
																			<td>
																				{orderitem.CPU}
																			</td>
																		</tr>
																	}
																	{(orderitem.RAM) &&
																		<tr>
																			<td>
																				RAM:
																			</td>
																			<td>
																				{orderitem.RAM}
																			</td>
																		</tr>
																	}
																	{(orderitem.HDD) &&
																		<tr>
																			<td>
																				HDD:
																			</td>
																			<td>
																				{orderitem.HDD}
																			</td>
																		</tr>
																	}
																	{(orderitem.Optical) &&
																		<tr>
																			<td>
																				Optical:
																			</td>
																			<td>
																				{orderitem.Optical}
																			</td>
																		</tr>
																	}
																	{(orderitem.Webcam) &&
																		<tr>
																			<td>
																				Webcam:
																			</td>
																			<td>
																				{orderitem.Webcam}
																			</td>
																		</tr>
																	}
																	{(orderitem.Resolution) &&
																		<tr>
																			<td>
																				Resolution:
																			</td>
																			<td>
																				{orderitem.Resolution}
																			</td>
																		</tr>
																	}
																	{(orderitem.COA) &&
																		<tr>
																			<td>
																				COA:
																			</td>
																			<td>
																				{orderitem.COA}
																			</td>
																		</tr>
																	}
																	{(orderitem.BodyGrade) &&
																		<tr>
																			<td>
																				Body Grade:
																			</td>
																			<td>
																				{orderitem.BodyGrade}
																			</td>
																		</tr>
																	}
																	{(orderitem.LCDGrade) &&
																		<tr>
																			<td>
																				LCD Grade:
																			</td>
																			<td>
																				{orderitem.LCDGrade}
																			</td>
																		</tr>
																	}
																	{(orderitem.Features) &&
																		<tr>
																			<td>
																				Other Features:
																			</td>
																			<td>
																				{orderitem.Features}
																			</td>
																		</tr>
																	}
																	{(orderitem.Instructions) &&
																		<tr>
																			<td>
																				Instructions:
																			</td>
																			<td>
																				{orderitem.Instructions}
																			</td>
																		</tr>
																	}
																</tbody>
															</table>
															{(orderstate.unassignedmanifest.length > 0) &&
																		<div style={{ textAlign: "center" }}>
																			<a className={classes.hoverunit} onClick={() => AssignRemaining(orderitem)}>
																				Assign Remaining Items
																			</a>
																			<div style={{ float: "right", fontSize: "inherit" }}>
																				<CircularProgress className={classes.hidden} ref={el => orderitemRefs.current["working" + orderitem.ItemID] = el} size={16} />
																			</div>
																		</div>
																	}
														</div>
													</div>
												</div>
											</div>

										</div>
										<div style={{ display: "inline-block", width: "50%", verticalAlign: "top" }}>
											{orderitem.manifest.map((assignedmanifestitem, amanifestindex) => {
												{/* Assigned Manifest Items - These have locked-in flexspecs, hopefully - flexordermanifest.specs (hasOne) */ }
												{/* Try a ref for each assigned item. We can try and highlight assigned when a SKU is selected */ }

												return (
													<div key={"K3" + assignedmanifestitem.ID}
														className={classes.defaultitemcontainer}
														ref={el => assigneditemRefs.current["itemcontainer" + assignedmanifestitem.ID] = el}
													>
														<div className={classes.inspectionitem}
															ref={el => assigneditemRefs.current["itemselection" + assignedmanifestitem.ID] = el}>
															<Grid container justifyContent="space-between" className={classes.inspectionitemheader}>
																<Grid item xs={9}>
																<b>Assigned Serial:&nbsp;</b>
																<a href={hostbase + "/inventorymanager?SerialNumber=" + encodeURIComponent(assignedmanifestitem.AssetSerialNumber)} target="_blank">
																	<b>{assignedmanifestitem.AssetSerialNumber}</b>
																	</a>
																	</Grid>
																<Grid item xs={3} style={{ textAlign: "right" }}><a className={classes.hoverunit} onClick={() => UnassignItem(assignedmanifestitem)}>
																	Reassign
																</a></Grid>
															</Grid>
															<div className={classes.inspectionitemdetails}>
																{(assignedmanifestitem.FlexSpecID && assignedmanifestitem.specs) &&
																	<>
																		<table className={classes.inspectionspecs} style={{width:"100%"}}>
																			<tbody>
																				<tr>
																				<td style={{width:"20%"}}>
																						Spec Date:
																					</td>
																					<td>
																						<Moment element='span' format="MMMM D, YYYY [at] h:mma">{assignedmanifestitem.specs.created_at}</Moment>
																						&nbsp;	&#40;{assignedmanifestitem.specs.SpecStep}&#41;
																					</td>
																				</tr>

																				<tr className={(assignedmanifestitem.specs.productmatch === 1) ? 
																					((assignedmanifestitem.specs.productmatch === 1 && assignedmanifestitem.Resolved===0) ? 
																					classes.inspectionrowerror : classes.inspectionrowwarning) : ""}>
																					<td>
																						Product:
																					</td>
																					<td>
																					{(assignedmanifestitem.specs.Model) &&
																						<>
																							{(assignedmanifestitem.specs.productmatch === 1) &&
																							<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}
																						{(assignedmanifestitem.specs.productmatch === 2) &&
																							<HelpIcon color="warning" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}
																						{(assignedmanifestitem.specs.productmatch === 3) &&
																							<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}&nbsp;
																							<span className={classes.hoverunit + " " + classes.unselectable}
																								onClick={() => ToggleAliasList(assignedmanifestitem, "product")} >
																								{assignedmanifestitem.specs.Model}
																								{(!assignedmanifestitem.showproductalias) &&
																									<ExpandMoreIcon style={{ fontSize: "12px" }} />
																								}
																								{(assignedmanifestitem.showproductalias) &&
																									<ExpandLessIcon style={{ fontSize: "12px" }} />
																								}
																							</span><br></br>
																								{(assignedmanifestitem.showproductalias) &&
																									<table style={{border:"1px solid #DDD"}}>
																										<tbody>
																											{(assignedmanifestitem.specs.modelalias) &&
																												<tr>
																													<td colSpan="2" style={{textAlign:"center", fontSize:"12px", backgroundColor:"#DDD"}}>
																														<b>Product Alias List</b>
																													</td>
																												</tr>
																											}
																										{assignedmanifestitem.specs.modelalias.map((alias, aliasindex) => {
																											return (
																												<tr>
																													<td style={{textAlign:"center", width:"25px"}}><RemoveCircleIcon className={classes.hoverunit}
																															style={{ fontSize: "14px" }} color="error"
																															onClick={() => RemoveAlias(alias)}
																														/></td>
																													<td>
																														{alias.AliasName}&nbsp;
																													</td>
																												</tr>


																													)
																										})}
																										{(orderitem.sku.product && (assignedmanifestitem.specs.productmatch === 1)) &&
																											<tr>
																												<td colSpan="2" style={{textAlign:"center"}}>
																													Add to Product: <a className={classes.hoverunit} onClick={()=>AddAlias("Model", orderitem, assignedmanifestitem.specs.Model, "")}>{orderitem.sku.product.Name}</a>
																												</td>
																											</tr>
																										}
																										</tbody>
																									</table>
																								}

																							</>
																								
																							}
																							{(!assignedmanifestitem.specs.Model) &&
																								<>None</>
																							}
																						</td>
																				</tr>
																				{(assignedmanifestitem.specs.CPU !== null) &&
																					<tr className={(assignedmanifestitem.specs.cpumatch === 1) ? 
																						((assignedmanifestitem.specs.cpumatch === 1 && assignedmanifestitem.Resolved===0) ? 
																						classes.inspectionrowerror : classes.inspectionrowwarning) : ""}>
																						<td>
																							CPU:
																						</td>
																						<td>
																						{(assignedmanifestitem.specs.cpumatch === 1) &&
																								<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}
																							{(assignedmanifestitem.specs.cpumatch === 2) &&
																								<HelpIcon color="warning" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}
																							{(assignedmanifestitem.specs.cpumatch === 3) &&
																								<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}&nbsp;
																						<span className={classes.hoverunit + " " + classes.unselectable}
																							onClick={() => ToggleAliasList(assignedmanifestitem, "cpu")} >
																							{assignedmanifestitem.specs.CPU}
																							{(!assignedmanifestitem.showcpualias) &&
																								<ExpandMoreIcon style={{ fontSize: "12px" }} />
																							}
																							{(assignedmanifestitem.showcpualias) &&
																								<ExpandLessIcon style={{ fontSize: "12px" }} />
																							}
																						</span><br></br>
																						{(assignedmanifestitem.showcpualias) &&
																									<table style={{border:"1px solid #DDD"}}>
																										<tbody>
																											{(assignedmanifestitem.specs.cpualias) &&
																												<tr>
																													<td colSpan="2" style={{textAlign:"center", fontSize:"12px", backgroundColor:"#DDD"}}>
																														<b>CPU Alias List</b>
																													</td>
																												</tr>
																											}
																										{assignedmanifestitem.specs.cpualias.map((alias, aliasindex) => {
																											return (
																												<tr>
																													<td style={{textAlign:"center", width:"25px"}}><RemoveCircleIcon className={classes.hoverunit}
																															style={{ fontSize: "14px" }} color="error"
																															onClick={() => RemoveAlias(alias)}
																														/></td>
																													<td>
																														{alias.AliasName}&nbsp;
																													</td>
																												</tr>


																													)
																										})}
																										{(orderitem.sku.product && (assignedmanifestitem.specs.cpumatch === 1)) &&
																											<tr>
																												<td colSpan="2" style={{textAlign:"center"}}>
																													Add to CPU: <a className={classes.hoverunit} onClick={() => AddAlias("Component", orderitem, assignedmanifestitem.specs.CPU, "CPU")}>
																											{orderitem.sku.skucomponents.map((component) => {
																												if (component.ComponentType === "CPU") {
																													return (<>{component.details.Name}</>)
																												}
																											})}
																										</a>
																												</td>
																											</tr>
																										}
																										</tbody>
																									</table>
																								}
																						</td>
																					</tr>
																				}
																				{(assignedmanifestitem.specs.TotalRAM !== null) &&
																					<tr className={(assignedmanifestitem.specs.rammatch === 1) ? 
																						((assignedmanifestitem.specs.rammatch === 1 && assignedmanifestitem.Resolved===0) ? 
																						classes.inspectionrowerror : classes.inspectionrowwarning) : ""}>
																						<td>
																							RAM:
																						</td>
																						<td>
																						{(assignedmanifestitem.specs.rammatch === 1) &&
																								<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}
																							{(assignedmanifestitem.specs.rammatch === 2) &&
																								<HelpIcon color="warning" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}
																							{(assignedmanifestitem.specs.rammatch === 3) &&
																								<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}&nbsp;
																						<span className={classes.hoverunit + " " + classes.unselectable}
																							onClick={() => ToggleAliasList(assignedmanifestitem, "ram")} >
																							{assignedmanifestitem.specs.TotalRAM}
																							{(!assignedmanifestitem.showramalias) &&
																								<ExpandMoreIcon style={{ fontSize: "12px" }} />
																							}
																							{(assignedmanifestitem.showramalias) &&
																								<ExpandLessIcon style={{ fontSize: "12px" }} />
																							}
																						</span><br></br>
																						{(assignedmanifestitem.showramalias) &&
																							<table style={{ border: "1px solid #DDD" }}>
																								<tbody>
																									{(assignedmanifestitem.specs.ramalias) &&
																										<tr>
																											<td colSpan="2" style={{ textAlign: "center", fontSize: "12px", backgroundColor: "#DDD" }}>
																												<b>RAM Alias List</b>
																											</td>
																										</tr>
																									}
																									{assignedmanifestitem.specs.ramalias.map((alias, aliasindex) => {
																										return (
																											<tr>
																												<td style={{ textAlign: "center", width: "25px" }}><RemoveCircleIcon className={classes.hoverunit}
																													style={{ fontSize: "14px" }} color="error"
																													onClick={() => RemoveAlias(alias)}
																												/></td>
																												<td>
																													{alias.AliasName}&nbsp;
																												</td>
																											</tr>


																										)
																									})}
																									{(orderitem.sku.product && (assignedmanifestitem.specs.rammatch === 1)) &&
																										<tr>
																											<td colSpan="2" style={{ textAlign: "center" }}>
																												Add to RAM: <a className={classes.hoverunit} onClick={() => AddAlias("Component", orderitem, assignedmanifestitem.specs.TotalRAM, "RAM")}>
																													{orderitem.sku.skucomponents.map((component) => {
																														if (component.ComponentType === "RAM") {
																															return (<>{component.details.Name}</>)
																														}
																													})}
																												</a>
																											</td>
																										</tr>
																									}
																								</tbody>
																							</table>
																						}
																						</td>
																					</tr>
																				}
																				{/* Move HDD1 Size down when expand details is on */}
																				{((assignedmanifestitem.specs.HDD1Size !== null) && !assignedmanifestitem.expanddetails) &&
																					<tr className={(assignedmanifestitem.specs.hddmatch === 1) ? 
																						((assignedmanifestitem.specs.hddmatch === 1 && assignedmanifestitem.Resolved===0) ? 
																						classes.inspectionrowerror : classes.inspectionrowwarning) : ""}>
																						<td>
																							HDD 1:
																						</td>
																						<td>
																						{(assignedmanifestitem.specs.hddmatch === 1) &&
																								<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}
																							{(assignedmanifestitem.specs.hddmatch === 2) &&
																								<HelpIcon color="warning" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}
																							{(assignedmanifestitem.specs.hddmatch === 3) &&
																								<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}&nbsp;
																						<span className={classes.hoverunit + " " + classes.unselectable}
																							onClick={() => ToggleAliasList(assignedmanifestitem, "hdd")} >
																							{assignedmanifestitem.specs.HDD1Size}
																							{(!assignedmanifestitem.showhddalias) &&
																								<ExpandMoreIcon style={{ fontSize: "12px" }} />
																							}
																							{(assignedmanifestitem.showhddalias) &&
																								<ExpandLessIcon style={{ fontSize: "12px" }} />
																							}
																						</span><br></br>
																						{(assignedmanifestitem.showhddalias) &&
																							<table style={{ border: "1px solid #DDD" }}>
																								<tbody>
																									{(assignedmanifestitem.specs.hddalias) &&
																										<tr>
																											<td colSpan="2" style={{ textAlign: "center", fontSize: "12px", backgroundColor: "#DDD" }}>
																												<b>HDD Alias List</b>
																											</td>
																										</tr>
																									}
																									{assignedmanifestitem.specs.hddalias.map((alias, aliasindex) => {
																										return (
																											<tr>
																												<td style={{ textAlign: "center", width: "25px" }}><RemoveCircleIcon className={classes.hoverunit}
																													style={{ fontSize: "14px" }} color="error"
																													onClick={() => RemoveAlias(alias)}
																												/></td>
																												<td>
																													{alias.AliasName}&nbsp;
																												</td>
																											</tr>


																										)
																									})}
																									{(orderitem.sku.product && (assignedmanifestitem.specs.hddmatch === 1)) &&
																										<tr>
																											<td colSpan="2" style={{ textAlign: "center" }}>
																												Add to HDD: <a className={classes.hoverunit} onClick={() => AddAlias("Component", orderitem, assignedmanifestitem.specs.HDD1Size, "Hard Drive")}>
																													{orderitem.sku.skucomponents.map((component) => {
																														if (component.ComponentType === "Hard Drive") {
																															return (<>{component.details.Name}</>)
																														}
																													})}
																												</a>
																											</td>
																										</tr>
																									}
																								</tbody>
																							</table>
																						}
																						</td>
																					</tr>
																				}
																				{(assignedmanifestitem.specs.SystemType === "1") &&
																					<tr className={(assignedmanifestitem.specs.opticalmatch === 1) ? 
																						((assignedmanifestitem.specs.opticalmatch === 1 && assignedmanifestitem.Resolved===0) ? 
																						classes.inspectionrowerror : classes.inspectionrowwarning) : ""}>
																						<td>
																							Optical:
																						</td>
																					<td>
																					{(assignedmanifestitem.specs.opticalmatch === 1) &&
																								<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}
																							{(assignedmanifestitem.specs.opticalmatch === 2) &&
																								<HelpIcon color="warning" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}
																							{(assignedmanifestitem.specs.opticalmatch === 3) &&
																								<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}&nbsp;
																						{(assignedmanifestitem.specs.OpticalDesc !== "") &&
																							<>
																								<span className={classes.hoverunit + " " + classes.unselectable}
																									onClick={() => ToggleAliasList(assignedmanifestitem, "optical")} >
																									{assignedmanifestitem.specs.OpticalDesc}
																									{(!assignedmanifestitem.showopticalalias) &&
																										<ExpandMoreIcon style={{ fontSize: "12px" }} />
																									}
																									{(assignedmanifestitem.showopticalalias) &&
																										<ExpandLessIcon style={{ fontSize: "12px" }} />
																									}
																								</span><br></br>
																							{(assignedmanifestitem.showopticalalias) &&
																								<table style={{ border: "1px solid #DDD" }}>
																									<tbody>
																										{(assignedmanifestitem.specs.opticalalias) &&
																											<tr>
																												<td colSpan="2" style={{ textAlign: "center", fontSize: "12px", backgroundColor: "#DDD" }}>
																													<b>Optical Alias List</b>
																												</td>
																											</tr>
																										}
																										{assignedmanifestitem.specs.opticalalias.map((alias, aliasindex) => {
																											return (
																												<tr>
																													<td style={{ textAlign: "center", width: "25px" }}><RemoveCircleIcon className={classes.hoverunit}
																														style={{ fontSize: "14px" }} color="error"
																														onClick={() => RemoveAlias(alias)}
																													/></td>
																													<td>
																														{alias.AliasName}&nbsp;
																													</td>
																												</tr>


																											)
																										})}
																										{(orderitem.sku.product && (assignedmanifestitem.specs.opticalmatch === 1)) &&
																											<tr>
																												<td colSpan="2" style={{ textAlign: "center" }}>
																													Add to Optical: <a className={classes.hoverunit} onClick={() => AddAlias("Component", orderitem, assignedmanifestitem.specs.OpticalDesc, "Optical")}>
																														{orderitem.sku.skucomponents.map((component) => {
																															if (component.ComponentType === "Hard Drive") {
																																return (<>{component.details.Name}</>)
																															}
																														})}
																													</a>
																												</td>
																											</tr>
																										}
																									</tbody>
																								</table>
																							}
																							</>
																						}
																						{(assignedmanifestitem.specs.OpticalDesc === "") &&
																							<>
																								Not Detected
																							</>
																						}
																					</td>
																					</tr>
																				}
																				{/* Find out AIO and Tablet System Type */}
																				{((assignedmanifestitem.specs.Resolution !== null) && (assignedmanifestitem.specs.SystemType === "2")) &&
																					<>
																						<tr className={(assignedmanifestitem.specs.resolutionmatch === 1) ? 
																						((assignedmanifestitem.specs.resolutionmatch === 1 && assignedmanifestitem.Resolved===0) ? 
																						classes.inspectionrowerror : classes.inspectionrowwarning) : ""}>
																							<td>
																								Res:
																							</td>
																							<td>
																							{(assignedmanifestitem.specs.resolutionmatch === 1) &&
																									<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}
																								{(assignedmanifestitem.specs.resolutionmatch === 2) &&
																									<HelpIcon color="warning" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}
																								{(assignedmanifestitem.specs.resolutionmatch === 3) &&
																									<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}&nbsp;
																							<span className={classes.hoverunit + " " + classes.unselectable}
																										onClick={() => ToggleAliasList(assignedmanifestitem, "resolution")} >
																										{assignedmanifestitem.specs.Resolution}
																										{(!assignedmanifestitem.showresolutionalias && assignedmanifestitem.specs.Resolution!=="0x0") &&
																											<ExpandMoreIcon style={{ fontSize: "12px" }} />
																										}
																										{(assignedmanifestitem.showresolutionalias && assignedmanifestitem.specs.Resolution!=="0x0") &&
																											<ExpandLessIcon style={{ fontSize: "12px" }} />
																										}
																									</span><br></br>
																						{(assignedmanifestitem.showresolutionalias) &&
																							<table style={{ border: "1px solid #DDD" }}>
																								<tbody>
																									{(assignedmanifestitem.specs.resolutionalias) &&
																										<tr>
																											<td colSpan="2" style={{ textAlign: "center", fontSize: "12px", backgroundColor: "#DDD" }}>
																												<b>Resolution Alias List</b>
																											</td>
																										</tr>
																									}
																									{assignedmanifestitem.specs.resolutionalias.map((alias, aliasindex) => {
																										return (
																											<tr>
																												<td style={{ textAlign: "center", width: "25px" }}><RemoveCircleIcon className={classes.hoverunit}
																													style={{ fontSize: "14px" }} color="error"
																													onClick={() => RemoveAlias(alias)}
																												/></td>
																												<td>
																													{alias.AliasName}&nbsp;
																												</td>
																											</tr>


																										)
																									})}
																									{(orderitem.sku.product && (assignedmanifestitem.specs.resolutionmatch === 1)) &&
																										<tr>
																											<td colSpan="2" style={{ textAlign: "center" }}>
																												Add to Resolution: <a className={classes.hoverunit} onClick={() => AddAlias("Component", orderitem, assignedmanifestitem.specs.Resolution, "Resolution")}>
																													{orderitem.sku.skucomponents.map((component) => {
																														if (component.ComponentType === "Resolution") {
																															return (<>{component.details.Name}</>)
																														}
																													})}
																												</a>
																											</td>
																										</tr>
																									}
																								</tbody>
																							</table>
																						}
																							</td>
																						</tr>
																						<tr className={(assignedmanifestitem.specs.WifisFound==="0") ? classes.inspectionrowerror : ""}>
																							<td>
																								Wifis Found:
																							</td>
																							<td>
																							{(assignedmanifestitem.specs.WifisFound==="0") &&
																									<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}
																								{(parseInt(assignedmanifestitem.specs.WifisFound) < 4 && assignedmanifestitem.specs.WifisFound!=="0") &&
																									<HelpIcon color="warning" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}
																								{(parseInt(assignedmanifestitem.specs.WifisFound) >= 4) &&
																									<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}&nbsp;
																								{assignedmanifestitem.specs.WifisFound}
																								{(parseInt(assignedmanifestitem.specs.WifisFound) < 4 && assignedmanifestitem.specs.WifisFound!=="0") &&
																									<> - Low Count</>
																								}
																							</td>
																						</tr>
																						<tr className={(parseFloat(assignedmanifestitem.specs.Batt1Health)<40) ? classes.inspectionrowerror : ""}>
																							<td>
																								Batt 1:
																							</td>
																							<td>
																							{(parseFloat(assignedmanifestitem.specs.Batt1Health)<40) &&
																									<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}
																								{(parseFloat(assignedmanifestitem.specs.Batt1Health)<60 && (parseFloat(assignedmanifestitem.specs.Batt1Health)>40)) &&
																									<HelpIcon color="warning" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}
																								{(parseFloat(assignedmanifestitem.specs.Batt1Health)>=60) &&
																									<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}&nbsp;
																								{assignedmanifestitem.specs.Batt1Health}
																							</td>
																						</tr>
																						<tr>
																							<td>
																								Keys Hit:
																							</td>
																							<td>
																							{(parseFloat(assignedmanifestitem.specs.KeysHit)<60) &&
																									<HelpIcon color="warning" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}
																								{(parseFloat(assignedmanifestitem.specs.KeysHit)>=60) &&
																									<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																								}&nbsp;
																								{assignedmanifestitem.specs.KeysHit}
																								{(parseInt(assignedmanifestitem.specs.KeysHit) < 60 || assignedmanifestitem.specs.KeysHit==="0") &&
																									<> - Low Count</>
																								}
																							</td>
																						</tr>
																					</>
																				}
																				{(assignedmanifestitem.specs.OS !== null) &&
																					<tr className={(assignedmanifestitem.specs.coamatch === 1) ? 
																						((assignedmanifestitem.specs.coamatch === 1 && assignedmanifestitem.Resolved===0) ? 
																						classes.inspectionrowerror : classes.inspectionrowwarning) : ""}>
																						<td>
																							COA:
																						</td>
																						<td>
																						{(assignedmanifestitem.specs.coamatch === 1) &&
																								<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}
																							{(assignedmanifestitem.specs.coamatch === 2) &&
																								<HelpIcon color="warning" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}
																							{(assignedmanifestitem.specs.coamatch === 3) &&
																								<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																							}&nbsp;
																						<span className={classes.hoverunit + " " + classes.unselectable}
																							onClick={() => ToggleAliasList(assignedmanifestitem, "coa")} >
																							{assignedmanifestitem.specs.OS}
																							{(!assignedmanifestitem.showcoaalias) &&
																								<ExpandMoreIcon style={{ fontSize: "12px" }} />
																							}
																							{(assignedmanifestitem.showcoaalias) &&
																								<ExpandLessIcon style={{ fontSize: "12px" }} />
																							}
																						</span><br></br>
																						{(assignedmanifestitem.showcoaalias) &&
																							<table style={{ border: "1px solid #DDD" }}>
																								<tbody>
																									{(assignedmanifestitem.specs.coaalias) &&
																										<tr>
																											<td colSpan="2" style={{ textAlign: "center", fontSize: "12px", backgroundColor: "#DDD" }}>
																												<b>COA Alias List</b>
																											</td>
																										</tr>
																									}
																									{assignedmanifestitem.specs.coaalias.map((alias, aliasindex) => {
																										return (
																											<tr>
																												<td style={{ textAlign: "center", width: "25px" }}><RemoveCircleIcon className={classes.hoverunit}
																													style={{ fontSize: "14px" }} color="error"
																													onClick={() => RemoveAlias(alias)}
																												/></td>
																												<td>
																													{alias.AliasName}&nbsp;
																												</td>
																											</tr>


																										)
																									})}
																									{(orderitem.sku.product && (assignedmanifestitem.specs.coamatch === 1)) &&
																										<tr>
																											<td colSpan="2" style={{ textAlign: "center" }}>
																												Add to COA: <a className={classes.hoverunit} onClick={() => AddAlias("Component", orderitem, assignedmanifestitem.specs.OS, "COA")}>
																													{orderitem.sku.skucomponents.map((component) => {
																														if (component.ComponentType === "COA") {
																															return (<>{component.details.Name}</>)
																														}
																													})}
																												</a>
																											</td>
																										</tr>
																									}
																								</tbody>
																							</table>
																						}
																						</td>
																					</tr>
																				}
																			{/* #### MORE #### */}
																			{(assignedmanifestitem.expanddetails === true) &&
																			<>
																			<tr>
																					<td>
																						
																					</td>
																					<td>
																					{(assignedmanifestitem.specs.ChecklistSpecs === 0) &&
																							<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}
																						{(assignedmanifestitem.specs.ChecklistSpecs === 1) &&
																							<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						} &nbsp;
																						Checklist Specs
																					</td>
																				</tr>
																			<tr>
																					<td>
																						
																					</td>
																					<td>
																					{(assignedmanifestitem.specs.ChecklistMissingDevices === 0) &&
																							<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}
																						{(assignedmanifestitem.specs.ChecklistMissingDevices === 1) &&
																							<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}&nbsp;
																						Checklist Device Drivers
																					</td>
																				</tr>
																				<tr>
																					<td>
																						
																					</td>
																					<td>
																					{(assignedmanifestitem.specs.ChecklistStress === 0) &&
																							<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}
																						{(assignedmanifestitem.specs.ChecklistStress === 1) &&
																							<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}&nbsp;
																						Checklist Stress Time: {assignedmanifestitem.specs.StressTime}
																					</td>
																				</tr>

																				<tr>
																					<td>
																						
																					</td>
																					<td>
																					{(assignedmanifestitem.specs.ChecklistBattery === 0) &&
																							<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}
																						{(assignedmanifestitem.specs.ChecklistBattery === 1) &&
																							<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}&nbsp;
																						Checklist Battery
																					</td>
																				</tr>
																				<tr>
																					<td>
																						
																					</td>
																					<td>
																					{(assignedmanifestitem.specs.ChecklistWifi === 0) &&
																							<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}
																						{(assignedmanifestitem.specs.ChecklistWifi === 1) &&
																							<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}&nbsp;
																						Checklist Wifi
																					</td>
																				</tr>
																				<tr>
																					<td>
																						
																					</td>
																					<td>
																					{(assignedmanifestitem.specs.ChecklistWebcam === 0) &&
																							<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}
																						{(assignedmanifestitem.specs.ChecklistWebcam === 1) &&
																							<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}&nbsp;
																					Checklist Webcam
																					</td>
																				</tr>
																				<tr>
																					<td>
																						
																					</td>
																					<td>
																					{(assignedmanifestitem.specs.ChecklistActivation === 0) &&
																							<WarningIcon color="error" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}
																						{(assignedmanifestitem.specs.ChecklistActivation === 1) &&
																							<CheckCircleIcon color="success" style={{ fontSize: "16px", verticalAlign: "middle" }} />
																						}&nbsp;
																					Checklist Activation
																					</td>
																				</tr>
																				{(assignedmanifestitem.specs.HDD1Size) &&
																					<>
																						<tr className={(assignedmanifestitem.specs.hddmatch === 1) ? classes.inspectionrowerror : ""} style={{borderTop:"1px solid #CCC"}}>
																							<td>
																								HDD 1:
																							</td>
																							<td>
																								<span className={classes.hoverunit + " " + classes.unselectable}
																									onClick={() => ToggleAliasList(assignedmanifestitem, "hdd")} >
																									{assignedmanifestitem.specs.HDD1Size}
																									{(!assignedmanifestitem.showhddalias) &&
																										<ExpandMoreIcon style={{ fontSize: "12px" }} />
																									}
																									{(assignedmanifestitem.showhddalias) &&
																										<ExpandLessIcon style={{ fontSize: "12px" }} />
																									}
																								</span><br></br>
																								{(assignedmanifestitem.showhddalias) &&
																							<table style={{ border: "1px solid #DDD" }}>
																								<tbody>
																									{(assignedmanifestitem.specs.hddalias) &&
																										<tr>
																											<td colSpan="2" style={{ textAlign: "center", fontSize: "12px", backgroundColor: "#DDD" }}>
																												<b>HDD Alias List</b>
																											</td>
																										</tr>
																									}
																									{assignedmanifestitem.specs.hddalias.map((alias, aliasindex) => {
																										return (
																											<tr>
																												<td style={{ textAlign: "center", width: "25px" }}><RemoveCircleIcon className={classes.hoverunit}
																													style={{ fontSize: "14px" }} color="error"
																													onClick={() => RemoveAlias(alias)}
																												/></td>
																												<td>
																													{alias.AliasName}&nbsp;
																												</td>
																											</tr>


																										)
																									})}
																									{(orderitem.sku.product && (assignedmanifestitem.specs.hddmatch === 1)) &&
																										<tr>
																											<td colSpan="2" style={{ textAlign: "center" }}>
																												Add to HDD: <a className={classes.hoverunit} onClick={() => AddAlias("Component", orderitem, assignedmanifestitem.specs.HDD1Size, "Hard Drive")}>
																													{orderitem.sku.skucomponents.map((component) => {
																														if (component.ComponentType === "Hard Drive") {
																															return (<>{component.details.Name}</>)
																														}
																													})}
																												</a>
																											</td>
																										</tr>
																									}
																								</tbody>
																							</table>
																						}
																							</td>
																						</tr>
																					<tr>
																						<td>
																							Model:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD1Model}
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Interface:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD1InterfaceType}
																						</td>
																					</tr>
																					<tr>
																						<td>
																							SMART:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD1SMARTStatus}
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Serial:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD1SerialNumber}
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Bad Sec:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD1ReallocatedSectors}
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Hours:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD1PowerOnHours}
																						</td>
																					</tr>
																					<tr style={{borderBottom:"1px solid #CCC"}}>
																						<td>
																							Temp:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD1Temp}
																						</td>
																					</tr>
																					</>
																				}

																				{/* HDD2 */}
																				{(assignedmanifestitem.specs.HDD2Size) &&
																					<>
																						<tr style={{borderTop:"1px solid #CCC"}}>
																							<td>
																								HDD 2:
																							</td>
																							<td>
																									{assignedmanifestitem.specs.HDD2Size}
																							</td>
																						</tr>
																					<tr>
																						<td>
																							Model:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD2Model}
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Interface:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD2InterfaceType}
																						</td>
																					</tr>
																					<tr>
																						<td>
																							SMART:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD2SMARTStatus}
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Serial:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD2SerialNumber}
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Bad Sec:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD2ReallocatedSectors}
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Hours:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD2PowerOnHours}
																						</td>
																					</tr>
																					<tr style={{borderBottom:"1px solid #CCC"}}>
																						<td>
																							Temp:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.HDD2Temp}
																						</td>
																					</tr>
																					</>
																				}
																				{(assignedmanifestitem.specs.Video1Desc) &&
																					<tr>
																						<td>
																						Video 1:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.Video1Desc}
																						</td>
																					</tr>
																				}
																				{(assignedmanifestitem.specs.Video2Desc) &&
																					<tr>
																						<td>
																						Video 2:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.Video2Desc}
																						</td>
																					</tr>
																				}
																				{(assignedmanifestitem.specs.Network1) &&
																					<tr>
																						<td>
																							Network1:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.Network1}
																						</td>
																					</tr>
																				}
																				{(assignedmanifestitem.specs.Network2) &&
																					<tr>
																						<td>
																							Network2:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.Network2}
																						</td>
																					</tr>
																				}
																				{(assignedmanifestitem.specs.Network3) &&
																					<tr>
																						<td>
																							Network3:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.Network3}
																						</td>
																					</tr>
																				}
																				{(assignedmanifestitem.specs.Network4) &&
																					<tr>
																						<td>
																							Network4:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.Network4}
																						</td>
																					</tr>
																				}
																				{(assignedmanifestitem.specs.Network5) &&
																					<tr>
																						<td>
																							Network5:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.Network5}
																						</td>
																					</tr>
																				}
																				{(assignedmanifestitem.specs.Network6) &&
																					<tr>
																						<td>
																							Network6:
																						</td>
																						<td>
																							{assignedmanifestitem.specs.Network6}
																						</td>
																					</tr>
																				}
																				{(assignedmanifestitem.defectflag && assignedmanifestitem.Resolved===0) &&
																				<tr>
																				<td colSpan="2" style={{textAlign:"center"}}>
																					<a href="javascript: void(0)" onClick={()=>ResolveManifestItem(assignedmanifestitem)}>Mark resolved by {userName}</a>
																				</td>
																			</tr>
																				}
																				
																				
																			</>
																			}



																				{(assignedmanifestitem.expanddetails === false) &&
																					<>
																					{(assignedmanifestitem.Resolved===1) &&
																					<tr> 
																						<td colSpan="2" style={{ textAlign: "center", fontWeight:"normal", fontStyle:"italic"}}>
																							Resolved on <Moment element='span' format="MMMM D, YYYY [at] h:mma">{assignedmanifestitem.ResolvedDate}</Moment><br></br>
																							 by {assignedmanifestitem.ResolvedBy} &nbsp;&nbsp;<a href="javascript: void(0)" onClick={()=>UnresolveManifestItem(assignedmanifestitem)}>Undo</a>
																						</td>
																					</tr>
																					}
																					
																					<tr> 
																						<td colSpan="2" style={{ textAlign: "center" }}>
																							<span className={classes.hoverunit}
																								onClick={() => ToggleDetails(index, amanifestindex)} >
																								More<ExpandMoreIcon style={{ fontSize: "12px" }} />
																							</span>
																						</td>
																					</tr>
																					</>

																			}
																			{(assignedmanifestitem.expanddetails === true) &&
																				<>
																					{(assignedmanifestitem.Resolved === 1) &&
																						<tr>
																							<td colSpan="2" style={{ textAlign: "center", fontWeight:"normal", fontStyle:"italic" }}>
																							Resolved on <Moment element='span' format="MMMM D, YYYY [at] h:mma">{assignedmanifestitem.ResolvedDate}</Moment><br></br>
																							 by {assignedmanifestitem.ResolvedBy} &nbsp;&nbsp;<a href="javascript: void(0)" onClick={()=>UnresolveManifestItem(assignedmanifestitem)}>Undo</a>
																							</td>
																						</tr>
																					}
																					<tr>
																						<td colSpan="2" style={{ textAlign: "center" }}>
																							<span className={classes.hoverunit}
																								onClick={() => ToggleDetails(index, amanifestindex)} >
																								Less<ExpandLessIcon style={{ fontSize: "12px" }} />
																							</span>
																						</td>
																					</tr>
																				</>
																			}
																			</tbody>
																		</table>

																	</>

																}

																{(!assignedmanifestitem.FlexSpecID || !assignedmanifestitem.specs) &&
																<div style={{ textAlign: "center" }}>
																	No specifications.
																	</div>
																}

															</div>

														</div>
													</div>
												)
											})}
										</div>
									</React.Fragment>
								}

								{/* After all order items with assignments, add a div to divide it from unassigned.*/}

							</React.Fragment>
						);
					}
					)}


					{/* 	##########    Order Items that DON't have assets assigned #########
					May be side-by-side with unassigned manifest items (the next section)
					 */}
					<div style={{ display: "inline-block", width: "50%", verticalAlign: "top" }}>
						{orderstate.orderitems.map((orderitem, index) => {
							//     I once tried to use itemRef.current[someintegerID] - When you try to reference this by integer,
							//     it uses it as an array index and not the actual keyed value
							//console.log(orderitem.ItemID);
							return (
								<React.Fragment key={"K2" + orderitem.ItemID}>
									{((orderitem.manifest.length === 0) && orderitem.sku!==null) &&
										
										<div className={classes.defaultitemcontainer} ref={el => orderitemRefs.current["container" + orderitem.ItemID] = el}>
											<div key={"K1" + orderitem.ItemID} className={classes.inspectionitem}
												ref={el => orderitemRefs.current["selection" + orderitem.ItemID] = el}>
												<Grid container justifyContent="space-between"
													className={classes.inspectionitemheader + " " + classes.hoverunit}
													onClick={() => HighlightItem(orderitem, false)}
												>
													<Grid item xs={9}><b>Sku: {orderitem.Code}</b></Grid>
													<Grid item xs={3} style={{ textAlign: "right"}}><b>Filled: {orderitem.FilledQty} / {orderitem.Quantity}</b>
														</Grid>
												</Grid>
												<div className={classes.inspectionitemdetails}>
													<div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", paddingBottom: "5px" }} title={orderitem.sku.Title}>{orderitem.sku.Title}</div>
													<div style={{ paddingLeft: "5px" }}>
														<table className={classes.inspectionspecs} style={{width:"100%"}}>
															<tbody>
																<tr>
																	<td style={{width:"20%"}}>
																		Product:
																	</td>
																	<td>
																		{(orderitem.sku.product) ? orderitem.sku.product.Name : "None"}
																	</td>
																</tr>
																{(orderitem.CPU) &&
																	<tr>
																		<td>
																			CPU:
																		</td>
																		<td>
																			{orderitem.CPU}
																		</td>
																	</tr>
																}
																{(orderitem.RAM) &&
																	<tr>
																		<td>
																			RAM:
																		</td>
																		<td>
																			{orderitem.RAM}
																		</td>
																	</tr>
																}
																{(orderitem.HDD) &&
																	<tr>
																		<td>
																			HDD:
																		</td>
																		<td>
																			{orderitem.HDD}
																		</td>
																	</tr>
																}
																{(orderitem.Optical) &&
																	<tr>
																		<td>
																			Optical:
																		</td>
																		<td>
																			{orderitem.Optical}
																		</td>
																	</tr>
																}
																{(orderitem.Webcam) &&
																	<tr>
																		<td>
																			Webcam:
																		</td>
																		<td>
																			{orderitem.Webcam}
																		</td>
																	</tr>
																}
																{(orderitem.Resolution) &&
																	<tr>
																		<td>
																			Resolution:
																		</td>
																		<td>
																			{orderitem.Resolution}
																		</td>
																	</tr>
																}
																{(orderitem.COA) &&
																	<tr>
																		<td>
																			COA:
																		</td>
																		<td>
																			{orderitem.COA}
																		</td>
																	</tr>
																}
																{(orderitem.BodyGrade) &&
																	<tr>
																		<td>
																			Body Grade:
																		</td>
																		<td>
																			{orderitem.BodyGrade}
																		</td>
																	</tr>
																}
																{(orderitem.LCDGrade) &&
																	<tr>
																		<td>
																			LCD Grade:
																		</td>
																		<td>
																			{orderitem.LCDGrade}
																		</td>
																	</tr>
																}
																{(orderitem.Features) &&
																	<tr>
																		<td>
																			Other Features:
																		</td>
																		<td>
																			{orderitem.Features}
																		</td>
																	</tr>
																}
																
															</tbody>
														</table>
														{(orderstate.unassignedmanifest.length > 0) &&
																	<div style={{ textAlign: "center" }}>
																		<a className={classes.hoverunit} onClick={() => AssignRemaining(orderitem)}>
																			Assign Remaining Items
																		</a>
																		<div style={{ float: "right", fontSize: "inherit" }}>
																			<CircularProgress className={classes.hidden} ref={el => orderitemRefs.current["working" + orderitem.ItemID] = el} size={16} />
																		</div>
																	</div>
																}
													</div>
												</div>
											</div>
										</div>
									}

									{/* Order Items without Sku - Old Orders - Disable functionality*/}
									{(orderitem.sku===null) &&
										<div className={classes.defaultitemcontainer} ref={el => orderitemRefs.current["container" + orderitem.ItemID] = el}>
											<div key={"K1" + orderitem.ItemID} className={classes.inspectionitem}
												ref={el => orderitemRefs.current["selection" + orderitem.ItemID] = el}>
												<Grid container justifyContent="space-between"
													className={classes.inspectionitemheader}
												>
													<Grid item xs={9}><b>Sku: {orderitem.Code}</b></Grid>
													<Grid item xs={3} style={{ textAlign: "right" }}><b>Filled: {orderitem.FilledQty} / {orderitem.Quantity}</b></Grid>
												</Grid>
												<div className={classes.inspectionitemdetails}>
													<div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", paddingBottom: "5px" }} title={orderitem.ProductName}>{orderitem.ProductName}</div>
													<div style={{ paddingLeft: "5px" }}>
													Outdated order item missing SKU. Cannot assign items. 
													</div>
												</div>
											</div>
										</div>
						}
									
								</React.Fragment>
							);
						}
						)}
					</div>

					{/* 	##########    UNASSIGNED MANIFEST ITEMS #########
					Items not already assigned to an Order Item (ID)
					These items in particular are pulled from the API as unassigned manifest items. 
					They may or MAY NOT include potential specs. 
					*/}
					<div style={{ display: "inline-block", width: "50%", verticalAlign: "top" }}>
						{orderstate.unassignedmanifest.map((manifestitem, manifestitemindex) => {

							return (
								<React.Fragment key={manifestitem.ID}>
									{(manifestitem.OrderItemID === null) &&
										<div className={classes.defaultitemcontainer}>
											<Grid container justifyContent="space-between" className={classes.unassigneditemheader + ' ' + classes.hoverunit} onClick={() => AssignManifestItem(manifestitem)}>
												<Grid item xs={9}>
												<b>Serial:&nbsp;{manifestitem.AssetSerialNumber}</b>
												</Grid>
												<Grid item xs={3} style={{ textAlign: "right" }}><b></b></Grid>
											</Grid>
											<div className={classes.inspectionitem}>
												{(manifestitem.potentialspecs.length > 0) &&
													<>
														<table className={classes.inspectionspecs} style={{width:"100%"}}>
																<tbody>
																<tr>
																<td style={{width:"20%"}}>
																				Date:
																			</td>
																			<td>
																			<Moment element='span' format="MMMM D, YYYY [at] h:mma">{manifestitem.potentialspecs[manifestitem.currentspecindex].created_at}</Moment>
																			&nbsp;	&#40;{manifestitem.potentialspecs[manifestitem.currentspecindex].SpecStep}&#41;</td>
																		</tr>
																<tr>
																			<td>
																				Model:
																			</td>
																			<td>
																				{manifestitem.potentialspecs[manifestitem.currentspecindex].Model}
																			</td>
																		</tr>
																		<tr>
																			<td>
																				CPU:
																			</td>
																			<td>
																				{manifestitem.potentialspecs[manifestitem.currentspecindex].CPU}
																			</td>
																		</tr>
																		<tr>
																			<td>
																				RAM:
																			</td>
																			<td>
																				{manifestitem.potentialspecs[manifestitem.currentspecindex].TotalRAM}
																			</td>
																		</tr>
																		<tr>
																		<td>
																			HDD:
																		</td>
																		<td>
																			{manifestitem.potentialspecs[manifestitem.currentspecindex].HDD1Size}
																		</td>
																	</tr>
																	{(manifestitem.potentialspecs[manifestitem.currentspecindex].SystemType === "2") &&
																		<>
																			<tr>
																				<td>
																					Resolution:
																				</td>
																				<td>
																					{manifestitem.potentialspecs[manifestitem.currentspecindex].Resolution}
																				</td>
																			</tr>
																			<tr>
																				<td>
																					Keys Hit:
																				</td>
																				<td>
																					{manifestitem.potentialspecs[manifestitem.currentspecindex].KeysHit}
																				</td>
																			</tr>
																		</>

																	}

																	{(manifestitem.potentialspecs[manifestitem.currentspecindex].SystemType === "1") &&
																		<tr>
																			<td>
																				Optical:
																			</td>
																			<td>
																				{manifestitem.potentialspecs[manifestitem.currentspecindex].Optical}
																			</td>
																		</tr>
																	} 
																	<tr>
																		<td>
																			COA:
																		</td>
																		<td>
																			{manifestitem.potentialspecs[manifestitem.currentspecindex].OS}
																		</td>
																	</tr>

																</tbody>
																</table>
														
													</>
												}


												{(manifestitem.potentialspecs.length === 0) &&
													<div style={{ textAlign: "center", padding:"8px" }}>
														No potential specifications found.
													</div>
												}

												{(manifestitem.potentialspecs.length !== 0) &&
													<div style={{ textAlign: "center" }}>
														<a className={((manifestitem.potentialspecs[manifestitem.currentspecindex + 1]) ? classes.hoverunit : classes.disabledhoverunit) + " " + classes.unselectable}
															onClick={() => (manifestitem.potentialspecs[manifestitem.currentspecindex + 1]) ? SetCurrentSpec(manifestitemindex, manifestitem.currentspecindex + 1) : null}
														>
															<ChevronLeftIcon style={{ position: "relative", top: "4px", fontSize: "18px" }} />Previous Record
														</a>
														<div style={{ display: "inline-block", color: "#4183c4", padding: "0px 6px" }} >|</div>
														<a className={((manifestitem.potentialspecs[manifestitem.currentspecindex - 1]) ? classes.hoverunit : classes.disabledhoverunit) + " " + classes.unselectable}
															onClick={() => (manifestitem.potentialspecs[manifestitem.currentspecindex - 1]) ? SetCurrentSpec(manifestitemindex, manifestitem.currentspecindex - 1) : null}
														>
															Next Record<ChevronRightIcon style={{ position: "relative", top: "4px", fontSize: "18px" }} />
														</a>
													</div>
												}





											</div>
										</div>


									}

								</React.Fragment>
							)
						})}
					</div>
				</div>
			}

		</LocalizationProvider>


	)


}

export default InspectionView;