import React, { useState } from "react";
import axios from "axios";
import {
	NOT_LOGGED_IN,
	LOG_IN_FORM,
	SIGN_UP_FORM,
	LOGGED_IN,
} from "../constants/AuthStatus";

const AppContext = React.createContext();

const AppProvider = (props) => {
	let hostName = "";
	if (process.env.NODE_ENV === "development") {
		hostName = "http://localhost:8000/v4";
	} else if (process.env.NODE_ENV === "production") {
		hostName = process.env.REACT_APP_DB_API4;
	}

	const [authStatus, setAuthStatus] = useState(NOT_LOGGED_IN);
	const [errorMessage, setErrorMessage] = useState("");
	const [userId, setUserId] = useState(0);
	const [userName, setUserName] = useState(""); //Plaintext first and last (not for use in DB)
	const [userNameInput, setUserNameInput] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");
	//User's Username!
	const [userUserName, setUserUserName] = useState("");
	const [userRole, setUserRole] = useState("");
	const [userPerms, setUserPerms] = useState({});
	const [userTZ, setUserTZ] = useState("this");
	//New Init Status!
	const [appStarted, setAppStarted] = useState(false);
	//New Current Menu!
	const [currentmenu, setCurrentMenu] = useState("");

	function changeAuthStatusLogin() {
		setAuthStatus(LOG_IN_FORM);
	}

	function changeAuthStatusSignup() {
		setAuthStatus(SIGN_UP_FORM);
	}

	function handleUserNameInput(changeEvent) {
		let updatedUserName = changeEvent.target.value;
		setUserNameInput(updatedUserName);
	}

	function handleUserEmail(changeEvent) {
		let updatedUserEmail = changeEvent.target.value;
		setUserEmail(updatedUserEmail);
	}

	function handleUserUserName(changeEvent) {
		console.log(changeEvent);
		if (changeEvent.key==="Enter"){
			alert();
		}
		let updatedUserUserName = changeEvent.target.value;
		setUserUserName(updatedUserUserName);
	}

	function handleUserPassword(changeEvent) {
		let updatedUserPassword = changeEvent.target.value;
		setUserPassword(updatedUserPassword);
	}



	const signup = () => {
		axios.defaults.withCredentials = true;
		axios.defaults.withXSRFToken =  true;
		// CSRF COOKIE
		axios.get(hostName + "/sanctum/csrf-cookie").then(
			(response) => {
				//console.log(response);
				// SIGNUP / REGISTER
				axios
					.post(hostName + "/register", {
						name: userNameInput,
						email: userEmail,
						password: userPassword,
						username: userUserName //New!
					})
					.then(
						(response) => {
							//console.log(response);
							// GET USER
							axios.get(hostName + "/user").then(
								(response) => {
									//console.log(response);
									setUserId(response.data.id);
									setUserName(response.data.firstname+" "+response.data.lastname);
									setUserUserName(response.data.username);
									setErrorMessage("");
									setAuthStatus(LOGGED_IN);
								},
								// GET USER ERROR
								(error) => {
									setErrorMessage("Could not complete the sign up");
								}
							);
						},
						// SIGNUP ERROR
						//To do... see if we need to setup error for username
						(error) => {
							if (error.response.data.errors.name) {
								setErrorMessage(error.response.data.errors.name[0]);
							} else if (error.response.data.errors.email) {
								setErrorMessage(error.response.data.errors.email[0]);
							} else if (error.response.data.errors.password) {
								setErrorMessage(error.response.data.errors.password[0]);
							} else if (error.response.data.message) {
								setErrorMessage(error.response.data.message);
							} else {
								setErrorMessage("Could not complete the sign up");
							}
						}
					);
			},
			// COOKIE ERROR
			(error) => {
				setErrorMessage("Could not complete the sign up");
			}
		);
	};


	const login = () => {
		axios.defaults.withCredentials = true;
		axios.defaults.withXSRFToken =  true;
		// CSRF COOKIE
		axios.get(hostName + "/sanctum/csrf-cookie").then(
			(response) => {
				//console.log(response);
				// LOGIN
				axios
					.post(hostName + "/login", {
						//email: userEmail,
						username: userUserName,
						password: userPassword,
					})
					.then(
						(response) => {
							//console.log(response);
							// GET USER
							axios.get(hostName + "/user").then(
								(response) => {
									//console.log(response);
									setUserId(response.data.user.id);
									setUserName(response.data.firstname+" "+response.data.lastname); //Long form of name - is this missing .user on response data?
									setUserUserName(response.data.user.username); //Simplified name for login
									setUserRole(response.data.user.role);
									setUserPerms(response.data.user.userperms);
									setUserTZ(response.data.user.timezone);
									setUserPassword("");
									setErrorMessage("");
									setAuthStatus(LOGGED_IN);
									setAppStarted(true);
								},
								// GET USER ERROR
								(error) => {
									setErrorMessage("Could not complete the login");
								}
							);
						},
						// LOGIN ERROR
						(error) => {
							if (error.response) {
								setErrorMessage(error.response.data.message);
							} else {
								setErrorMessage("Could not complete the login");
							}
						}
					);
			},
			// COOKIE ERROR
			(error) => {
				setErrorMessage("Could not complete the login");
			}
		);
	};

	//Test: We want to change Context variables if it looks like we're already logged in. Doublecheck by querying DB?
	const checklogin = () => {
		axios.defaults.withCredentials = true;
		axios.defaults.withXSRFToken =  true;
		// CSRF COOKIE
		axios.get(hostName + "/sanctum/csrf-cookie").then(
			(response) => {
				axios.get(hostName + "/checklogin").then(
					(response) => {
						//The API only sends back 401 unauthorized, so any good response means we're logged in.

						//If we really don't want to rerender (and we don't), then we really don't need to be resetting all this
						if (authStatus !== "logged_in"){
							//Recover possible lost login here for newly opened tabs:
							setUserId(response.data.id);
							setUserName(response.data.firstname+" "+response.data.lastname);
							setUserUserName(response.data.username);
							setUserRole(response.data.role);
							setUserPerms(response.data.userperms);
							setUserTZ(response.data.timezone);
							setAuthStatus(LOGGED_IN);
							setAppStarted(true);
						}
					},
					// GET USER ERROR
					(error) => {
						//console.log(error);
						console.log("Error Code: 404");
						setAppStarted(true);
						setAuthStatus(NOT_LOGGED_IN);
						//Currently flashes user back to login screen.
					}
				);

			},
			// COOKIE ERROR, Timeout
			(error) => {
				console.log(error);
				console.log("Error Code: 506");
				setAppStarted(true);
				setAuthStatus(NOT_LOGGED_IN);
				//setErrorMessage("Could not complete the login");
			}
		);
	}

	function logout() {
		axios.defaults.withCredentials = true;
		axios.defaults.withXSRFToken =  true;
		axios.defaults.credentials = true;
		axios.get(hostName + "/logout");
		setUserId(0);
		setUserName("");
		setUserUserName("");
		setUserNameInput("");
		setUserEmail("");
		setUserPassword("");
		setUserRole("");
		setAuthStatus(NOT_LOGGED_IN);
	}

	//Continuous Auth Check Part 1
	function useInterval(callback, delay) {
		const intervalRef = React.useRef();
		const callbackRef = React.useRef(callback);
		React.useEffect(() => {
			callbackRef.current = callback;
		}, [callback]);
		// Set up the interval:
		React.useEffect(() => {
			if (typeof delay === 'number') {
				intervalRef.current = window.setInterval(() => callbackRef.current(), delay);
				// Clear interval if the components is unmounted or the delay changes:
				return () => window.clearInterval(intervalRef.current);
			}
		}, [delay]);
		// Returns a ref to the interval ID in case you want to clear it manually:
		return intervalRef;
	}

	//Continuous Auth Check Part 2
	const authintervalRef = useInterval(() => {    //   <-------- Pass new anonymous callback function into new useInterval!
		checklogin();
		//If already logged in, use 30 seconds. If not logged in, use 10 seconds.
	}, authStatus === "logged_in" ? 30000 : 10000);


	return (
		<AppContext.Provider
			value={{
				authStatus,
				changeAuthStatusLogin,
				changeAuthStatusSignup,
				userId,
				userName,
				userUserName,
				userRole,
				userPerms,
				userTZ,
				userNameInput,
				userEmail,
				userPassword,
				handleUserNameInput,
				handleUserEmail,
				setAuthStatus,
				handleUserUserName,
				handleUserPassword,
				setUserId,
				setUserUserName,
				setErrorMessage,
				setUserPerms,
				setUserTZ,
				setUserName,
				setUserRole,
				setCurrentMenu,
				setAppStarted,
				currentmenu,
				signup,
				login,
				logout,
				checklogin,
				errorMessage,
				appStarted
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
};

//I think the destructured is always {Consumer, Provider}
//Remember that <AppProvider> will need to be wrapped on context parent container (likley App.js)
export { AppContext, AppProvider };
