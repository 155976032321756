import React, { useContext } from 'react';
import { ErrorContext } from './ErrorContext';


const ErrorMessage = () => {
	const errors = useContext(ErrorContext);

	return(
		<div>
			{(errors.currenterror.errtype==='ok') && 
				<span style={{backgroundColor:'#086b00', color:'white', display:'inline-block', borderRadius:'2px', paddingTop:'3px', paddingBottom:'3px',paddingLeft:'8px', paddingRight:'8px', boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.24)"}}>
					{errors.currenterror.errmsg}&nbsp;
					{(errors.currenterror.link) &&
						<a href={errors.currenterror.link} target="_blank" rel="noreferrer" style={{
							color:"#FFF",
							textDecoration:"underline"
						}}>{errors.currenterror.linktext}</a>
					}
				</span>
			}
			{(errors.currenterror.errtype==='warning') && 
				<span style={{backgroundColor:'#e58700', color:'white', display:'inline-block', borderRadius:'2px', paddingTop:'3px', paddingBottom:'3px',paddingLeft:'8px', paddingRight:'8px', boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.24)"}}>
					{errors.currenterror.errmsg}&nbsp;
					{(errors.currenterror.link) &&
						<a href={errors.currenterror.link} target="_blank" rel="noreferrer" style={{
							color:"#FFF",
							textDecoration:"underline"
						}}>{errors.currenterror.linktext}</a>
					}
				</span>
			}
			{(errors.currenterror.errtype==='danger') && 
				<span style={{backgroundColor:'#d42300', color:'white', display:'inline-block', borderRadius:'2px', paddingTop:'3px', paddingBottom:'3px',paddingLeft:'8px', paddingRight:'8px', boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.24)"}}>
					{errors.currenterror.errmsg}&nbsp;
					{(errors.currenterror.link) &&
						<a href={errors.currenterror.link} target="_blank" rel="noreferrer" style={{
							color:"#FFF",
							textDecoration:"underline"
						}}>{errors.currenterror.linktext}</a>
					}
				</span>
			}
			{(errors.currenterror.errtype==='neutral') && 
				<span style={{backgroundColor:'#AAA', color:'white', display:'inline-block', borderRadius:'2px', paddingTop:'3px', paddingBottom:'3px',paddingLeft:'8px', paddingRight:'8px', boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.24)"}}>
					{errors.currenterror.errmsg}&nbsp;
					{(errors.currenterror.link) &&
						<a href={errors.currenterror.link} target="_blank" rel="noreferrer" style={{
							color:"#FFF",
							textDecoration:"underline"
						}}>{errors.currenterror.linktext}</a>
					}
				</span>
			}
		</div>		
	)
}


export default ErrorMessage;