//Restrict Number Function
//Passes newvalue, oldvalue, event from user input, and type of number we'd like to restrict
const RestrictInputNumber = (newvalue, oldvalue, event, type) => {
	//In some cases, backspace makes newvalue blank. Can we assume 0?
	if (newvalue.length===0){
		newvalue="0";
	}
	//console.log(event);
	console.log("New Value: "+newvalue+"        Old Value: "+oldvalue);
	console.log("Simple Test: "+(parseFloat(newvalue)===parseFloat(oldvalue)));
	console.log("Event Value: "+event.target.value);
	//Are these items the same?
	var identicalvalues = (parseFloat(newvalue)===parseFloat(oldvalue));
	if (type==="float"){
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (event.key === "." || event.key === "Period" || event.key === "NumpadDecimal") {
			console.log("Decimal key detected in Restrict Number");
			console.log("Returning false.");
			return false;
		}
		if (event.key === "Backspace") {
			console.log("Backspace key detected!");
			//If the newvalue doesn't have a decimal, but the old one did, we try to keep the decimal value
			//New issue: If an input is changed from "43.5" to "43."", the system sends only "43". It doesn't detect a decimal!
			//Index is always -1!
			if ((oldvalue.indexOf(".") > -1) && (index === -1) && identicalvalues) {
				console.log("Returning false.");
				return false;
			}
		}
		if (index > -1) {
			if ((len - 1) > (index + 2)) {
				//We are out past 2 decimals!
				console.log("Past 2 decimal places, returning oldvalue: "+oldvalue);
				return oldvalue;
			}
		}
		console.log("Returning newvalue:"+ newvalue);
		return newvalue;
	}

	//In Progress:
	if (type==="positiveinteger"){
		//If a decimal is pressed, let's see if we can kind of ignore it.
		if (event.key === "." || event.key === "Period" || event.key === "NumpadDecimal") {
			console.log("Decimal key detected in Restrict Number");
			console.log("Returning false.");
			return false;
		}

		if (newvalue <0 ){
			console.log("Returning 0");
			return "0";
		} else {
			//return parseInt(newvalue); //We can't use indexOf on an integer!
			console.log("Returning: "+newvalue);
			return newvalue;
		}
	}
	
}


export default RestrictInputNumber;