import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	selectProgressState
} from './progressbarSlice';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';


export function ProgressBar() {
	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const progress = useSelector(selectProgressState);

	const [expandresults, setExpandResults] = useState(false);
	const ExpandResults = () => {
		setExpandResults(!expandresults);
	}

	const CopyErrors = (errors) =>{
		let errorstrings = errors.slice();
		for (var i=0; i<errorstrings.length; i++){
			errorstrings[i] = "Error "+(i+1)+": "+errorstrings[i];
		}
		const textToCopy = errorstrings.join('\n');
		navigator.clipboard.writeText(textToCopy)
	}

	return (
		<React.Fragment>
				{(progress.progressstate.show) &&
				<div style={{position:"relative", height:"33px"}}>
					<div style={{ border: "2px solid #CCC", padding: "2px", width: "260px", zIndex: 1199, backgroundColor: "#FFF", height: (expandresults) ? "" : "33px", position:"absolute", right:"0px"}}>
						<div style={{ display: "inline-block", width: "220px", borderRadius: "4px", border: "1px solid #074507" }}>
							{/* Background of Idle Bar - Is Green when all looks OK. Is orange when an error was found. */}
							<div style={{ backgroundColor: (progress.progressstate.fail === 0) ? "#85bd85" : "#bdb285", position: "relative" }}>
								<div style={{ position: "absolute", width: "100%", textAlign: "center" }}>
									{(progress.progressstate.fail === 0) &&
										<React.Fragment>
											{(progress.progressstate.finished === progress.progressstate.total) &&
												<h4 style={{ margin: "2px", color: "#FFF" }}>Completed {progress.progressstate.total} {progress.progressstate.type}</h4>
											}
											{(progress.progressstate.finished !== progress.progressstate.total) &&
												<h4 style={{ margin: "2px", color: "#FFF" }}>{progress.progressstate.finished} / {progress.progressstate.total}</h4>
											}
										</React.Fragment>
									}
									{(progress.progressstate.fail !== 0) &&
										<h4 style={{ margin: "2px", color: "#FFF" }}>Passed: {progress.progressstate.pass} / Failed: {progress.progressstate.fail}</h4>
									}

								</div>
								<div style={{ width: progress.progressstate.percent, backgroundColor: (progress.progressstate.fail === 0) ? "green" : "#f35600", height: "23px", textAlign: "center" }}>
								</div>
							</div>

						</div>
						<div style={{ display: "inline-block", height: "26px", textAlign: "center", verticalAlign: "top", paddingLeft: "5px" }}>
							<IconButton className={classes.transparenticon} size="medium" aria-label="expand" onClick={() => { ExpandResults() }}>
								{(!expandresults) &&
									<ExpandMoreIcon color="primary" sx={{ fontSize: "25px" }}></ExpandMoreIcon>
								}
								{(expandresults) &&
									<ExpandLessIcon color="primary" sx={{ fontSize: "25px" }}></ExpandLessIcon>
								}
							</IconButton>
						</div>
						{(expandresults) &&
							<div style={{ padding: "5px" }}>
								Pass: {progress.progressstate.pass}<br></br>
								Fail: {progress.progressstate.fail}<br></br>
								Percent: {progress.progressstate.percent}<br></br>
								Total:{progress.progressstate.total}<br></br>
								Finished:{progress.progressstate.finished}<br></br>
								Timeout ID: {progress.progressstate.timeoutid}<br></br>
								{progress.progressstate.errors.map((error, errorindex) => {
									if (errorindex < 3){
										return (
											<div key={errorindex}>
												Error {errorindex+1}: {error}
											</div>
										)
									}
								})}

								{(progress.progressstate.errors.length > 3) &&
									<div>
										<a className={classes.hoverunit} onClick={() =>CopyErrors(progress.progressstate.errors)}>Copy All Errors</a>
									</div>
								}

							
							</div>
						}

					</div>
				</div>
				
			}
		</React.Fragment>
		
	)
}
