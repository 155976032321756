import React, { useState, useRef } from 'react';


function ToyBox() {
  const [elements, setElements] = useState([]);
  const inputRef = useRef();

  const handleAddElement = () => {
    const newElement = inputRef.current.value;
    setElements(prevElements => [...prevElements, newElement]);
    inputRef.current.value = 'Pending Item';
  };




	/*
	//Sorting of arrays by objects:
	//localstate.orderby:"SerialNumber" - Used in Item Pricing
	pricing.sort(function(a,b){
		//Numeric Values
		if (localstate.orderby==='Cost'){
			return a[localstate.orderby]-b[localstate.orderby];
		} else {
			//Alphabetic
			if (a[localstate.orderby] < b[localstate.orderby]){return -1;}
			if (a[localstate.orderby] > b[localstate.orderby]){return 1;}
			return 0;
		}
	});
	//Reverse if needed
	if (localstate.order==="desc"){
		pricing.reverse();
	}
	*/





  return (
    <div>
      <input type="text" ref={inputRef} />
      <button onClick={handleAddElement}>Add</button>
      <ul>
        {elements.map((element, index) => (
          <li key={index}>{element}</li>
        ))}
      </ul>
    </div>
  );
}

export default ToyBox;