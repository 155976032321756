//Don't forget to add this to your reducer (store.js)

import { createSlice } from '@reduxjs/toolkit'

export const errormessageSlice = createSlice({
  name: 'errormessage',
  initialState: {
	errorstate:{
		errmsg:'Default Current Error',
		errshow:false,
		errtimeout:2,
		errtype:'warning',
		link:"/error/example",
		linktext:"Link Example",
	}
  },
  reducers: {
	newErrorMessage: (state, action) =>{
		state.errorstate=action.payload;
	},
	errormessageTimeout: (state) => {
		state.errorstate.errshow = false;
	},
  },
})

export const { newErrorMessage, errormessageTimeout } = errormessageSlice.actions;

export const setErrorTimeout = (timeout) => (dispatch) => {
	setTimeout(() => {
		dispatch(errormessageTimeout())
	}, timeout*1000)
}

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

// // The function below is called a selector and allows us to select a value from
// // the state. Selectors can also be defined inline where they're used instead of
// // in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = (state) => state.counter.value
export const selectErrorMessage = (state) => state.errormessage;

export default errormessageSlice.reducer
