import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";

var dbendpoint = process.env.REACT_APP_DB_API4;


//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

export const flexDocumentSlice = createSlice({
  name: 'document',
  initialState: {
	documentstate:{
		value: 66, //Test Value
		documentid: null,
		docloaded: false,
		data: {}
	},
   
  },
  reducers: {
	setDocument: (state, action) =>{
		console.log(action);
		state.documentstate=action.payload;
	},
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
  },
})

export const { setDocument, increment, decrement, incrementByAmount } = flexDocumentSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount))
  }, 1000)
}

export const loadAsync = (documentid) => (dispatch) => {
	var postdata = {
		documentid:documentid,
		data:null
	};
	axios.post(dbendpoint+"/document", postdata, defaultpostoptions).then(res => {
		//API should be setup to send 200 response with status. Merge paginated requests.
		if (res.status===200){
			//If ValidateUser() fails to verify user, it sends back 'login' error. 
			if (res.data.Status==="login"){
				//Not logged in. Reload page causes redirect to /login
				window.location.reload(false);
			}
			//All new API calls should return a status.
			if (res.data.Status==="Success"){
				console.log(res);
			}
			if (res.data.Status==="Failure"){
				//Failure error
				//errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
			}
		} else {
			//Non-200 message from server.
			//errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
		}
	});
  }



// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectDocument = (state) => state.document.documentstate;

export default flexDocumentSlice.reducer
