//User View & Edit Perms

//Don't Forget:
//Update default values, be sure to add as fillable in DB.
//Log out and log back in for changes to take effect


//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useHistory} from 'react-router-dom';

//Contexts
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//Datetime formatting
import Moment from 'react-moment';



import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//Icons
import SaveIcon from '@mui/icons-material/Save';

//Buttons
import Button from '@mui/material/Button';

//Datetime Pickers
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


/* ##########################  Configuration  ########################## */

//DB
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

//Axios Long Post
const longpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 20000,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
const input = [1, 1, 2, 3, 3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function (acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	}, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};



const UserView = (props) => {
	//URL Param for ID
	let { id } = useParams();
	console.log(id);

	let history = useHistory();

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const btnSave = useRef();

	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		//Need to initialize a blank item
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		pagereload: false,
		itemloaded: false, //POSSIBLY MOST IMPORTANT! Using defaultValue on an input causes issues if you try to render before item is loaded.
		pendingupdate: false,
		productsearchterm: '',
		itemdata: {
			id: id,
			firstname: "default",
			lastname: "default",
			username: "default",
			role: "default",
			email: "default",
			created_at: "default",
			timeoutminutes: 0,
			disabled: true,
			userperms: {
				//Demo Data
				createDemoData: 0,
				readDemoData: 0,
				updateDemoData: 0,
				deleteDemoData: 0,
				auditDemoData: 0,
				//User Data
				createUser: 0,
				readUser: 0,
				updateUser: 0,
				deleteUser: 0,
				//Calendar Data
				createCalendarEvent: 0,
				readCalendarEvent: 0,
				updateCalendarEvent: 0,
				deleteCalendarEvent: 0,
				//Product Data
				createProduct: 0,
				readProduct: 0,
				updateProduct: 0,
				deleteProduct: 0,
				//HDD Spec Data
				createHDDSpec: 0,
				readHDDSpec: 0,
				updateHDDSpec: 0,
				deleteHDDSpec: 0,

			}
		},
		defaultpassword:"",
		disableddefault: true
	});

	//Some inputs will need to work via refs. Let's try to codify it, possibly even use it elsewhere.
	//Try Object....
	const itemRefs = useRef({

	})

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Item
	function LoadItem() {

		//To Do: Chain load item and previous item state.
		//Warn user if the current record is old.
		//If admin, allow rollbacks.
		const postdata = {
			id: localstate.itemdata.id
		};
		axios.post(dbendpoint + "/users/getuser", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					localstate.itemdata = res.data.item;
					//Cost is always nice to have pre-formatted
					//localstate.itemdata.Cost = parseFloat(res.data.item.Cost).toFixed(2);
					localstate.dbreload = false;
					localstate.itemloaded = true; //Now allow render
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.dbreload = false;
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}



	useEffect(() => {
		//To Do: On load of item, set title to the serial number.
		document.title = "User";
		if (state.dbreload) {
			//Avoid duplicate loads.
			localstate.dbreload = false;
			//Diagnostic:
			console.log("Localstate ID: " + localstate.itemdata.ID);
			LoadItem();
		}
	}, [state]);


	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue, event) => {
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (event.key === "." || event.key === "Period" || event.key === "NumpadDecimal") {
			console.log("Decimal key detected in Restrict Number");
			return false;
		}
		if (event.key === "Backspace") {
			console.log("Backspace key detected!");
			//If the newvalue doesn't have a decimal, but the old one did, we try to keep the decimal value
			if ((oldvalue.indexOf(".") > -1) && (index === -1)) {
				return false;
			}
		}
		if (index > -1) {
			if ((len - 1) > (index + 2)) {
				//We are out past 2 decimals!
				return oldvalue;
			}
		}
		return newvalue;
	}


	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		let postdata = {
			user: localstate.itemdata
		}
		axios.post(dbendpoint + "/users/update", postdata, defaultpostoptions).then(res => {
			//API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					btnSave.current.style.color = "#01579B";
					btnSave.current.style.backgroundColor = "rgba(0, 0, 0, 0)";
					//Attempt to replace: btnSave.current.disabled = true;
					localstate.pendingsaves = false;
					localstate.dbreload = true;
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 15, errtype: "warning" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" })
			}
		});
	}



	//Changes to item
	const onChangeValue = (event, itemkey, isUserPerm) => {
		var oldvalue = localstate.itemdata[itemkey];
		var newvalue = event.target.value;
		if (event.key !== "Tab" &&
			event.key !== "ArrowDown" &&
			event.key !== "ArrowUp" &&
			event.key !== "ShiftLeft" &&
			event.key !== "ShiftRight"
		) {

			if (itemkey === "disabled") {
				if (event.target.checked) {
					localstate.itemdata.disabled = 1;
				} else {
					localstate.itemdata.disabled = 0;
				}
			}

			if (itemkey === "Cost" || itemkey === "Price" || itemkey === "Margin") {
				newvalue = RestrictNumber(newvalue, oldvalue, event); //Returns value or FALSE
				if (newvalue) {
					itemRefs.current.CostEl.value = newvalue;
					localstate.itemdata[itemkey] = newvalue;
				} else {
					//Handle possible backspace.
					//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
					if (event.key === "Backspace") {
						//If the newvalue doesn't have a decimal, but the old one did, we try to keep the decimal value by ignoring the ref.
						if ((oldvalue.indexOf(".") > -1) && (event.target.value.indexOf(".") === -1)) {
							localstate.itemdata[itemkey] = parseFloat(newvalue).toFixed(2);
						}
					}
				}
				console.log("Debug: This localstate value should match the view: " + localstate.itemdata[itemkey]);
			}

			//All others
			if (itemkey === "firstname" || itemkey === "lastname" || itemkey === "username" || itemkey === "email" || itemkey === "timeoutminutes" || itemkey==="password" || itemkey==="role") {
				localstate.itemdata[itemkey] = newvalue;
			}

			//User Perms are All Booleans!
			if (isUserPerm) {
				if (event.target.checked) {
					localstate.itemdata.userperms[itemkey] = 1;
				} else {
					localstate.itemdata.userperms[itemkey] = 0;
				}
			}

			localstate.pendingsaves = true;
			btnSave.current.style.color = "white";
			btnSave.current.style.backgroundColor = "#01579B";
			UpdateState(localstate);
		}

	}



	//Try new method to target what is sent from a datetime picker:
	const onChangeDatetimeValue = (newvalue, itemkey) => {
		localstate.itemdata[itemkey] = newvalue;
		localstate.pendingsaves = true;
		btnSave.current.style.color = "white";
		btnSave.current.style.backgroundColor = "#01579B";
		UpdateState(localstate);

	}

	const DeleteUser = () =>{
		axios.post(dbendpoint + "/users/delete", {userid:localstate.itemdata.id}, defaultpostoptions).then(res => {
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					history.push('/users');

				}
				if (res.data.Status === "Failure") {
					//Failure error
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 15, errtype: "warning" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" })
			}
		});
	}



	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			{(localstate.itemloaded) &&
				<div style={{ padding: "8px", minWidth: "750px", maxWidth: "1200px", textAlign: "center", margin: "auto", marginBottom: "100px" }}>

					{/* Standard Page Header with right floated error message space */}
					<div style={{ position: "relative", height: "85px", paddingTop: "5px" }}>
						<div style={{ textAlign: "center" }}>
							<h2>User: {localstate.itemdata.firstname} {localstate.itemdata.lastname}</h2>
						</div>
						{(userPerms.updateUser === 1) &&
							<div>
								<Box sx={{ '& button': { m: 1 } }}>
									<Button variant="outlined"
										size="medium"
										disabled={localstate.pendingsaves ? false : true}
										onClick={() => SaveChanges(false)}
										ref={el => btnSave.current = el}>
										<SaveIcon />&nbsp;&nbsp;Save Changes
									</Button>
								</Box>
							</div>
						}
						<div style={{ height: "26px" }}>
							{(errors.currenterror.errshow) &&
								<React.Fragment>
									<ErrorMessage />
								</React.Fragment>
							}
						</div>
					</div>

					<Typography variant="h6">
						<Grid container spacing={0}>
							<div className={classes.itemtableheader}>User Details</div>
							<Grid item sm={12} md={6}>
								<div className={classes.flextabledebug}>
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td style={{ width: "140px" }}>
													ID
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														{localstate.itemdata.id}
													</div>

												</td>
											</tr>

											<tr>
												<td>
													First Name:
												</td>
												<td>
													{(userPerms.updateUser === 1) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.firstname}
															onKeyUp={(event) => onChangeValue(event, "firstname", false)}
														/>
													}
													{(userPerms.updateUser === 0) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.firstname}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Last Name:
												</td>
												<td>
													{(userPerms.updateUser === 1) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.lastname}
															onKeyUp={(event) => onChangeValue(event, "lastname", false)}
														/>
													}
													{(userPerms.updateUser === 0) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.lastname}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Username:
												</td>
												<td>
													{(userPerms.updateUser === 1) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.username}
															onKeyUp={(event) => onChangeValue(event, "username", false)}
														/>
													}
													{(userPerms.updateUser === 0) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.username}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Role:
												</td>
												<td>
													{(userPerms.updateUser === 1) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.role}
															onKeyUp={(event) => onChangeValue(event, "role", false)}
														/>
													}
													{(userPerms.updateUser === 0) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.role}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Email:
												</td>
												<td>
													{(userPerms.updateUser === 1) &&
														<input className={classes.flexiteminput}
															type="text"
															defaultValue={localstate.itemdata.email}
															onKeyUp={(event) => onChangeValue(event, "email", false)}
														/>
													}
													{(userPerms.updateUser === 0) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.email}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Timeout:
												</td>
												<td>
													{(userPerms.updateUser === 1) &&
														<input className={classes.flexiteminput}
															type="number"
															step="1"
															defaultValue={localstate.itemdata.timeoutminutes}
															onKeyUp={(event) => onChangeValue(event, "timeoutminutes", false)}
														/>
													}
													{(userPerms.updateUser === 0) &&
														<div className={classes.flexitemstaticinput}>
															{localstate.itemdata.timeoutminutes}
														</div>
													}
												</td>
											</tr>
											<tr>
												<td>
													Date:
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														<Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.created_at}</Moment>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													Last Login:
												</td>
												<td>
													<div className={classes.flexitemstaticinput}>
														<Moment element='span' format="MMMM D, YYYY [at] h:mma">{localstate.itemdata.lastlogin}</Moment>
													</div>
												</td>
											</tr>
											<tr>
												<td style={{ width: "140px", paddingTop: "8px" }}>
													Disabled:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.disabled === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "disabled", false)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.disabled === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											{(userPerms.updateUser === 1) &&
												<tr>
												<td>
													Password:
												</td>
												<td>
													<input className={classes.flexiteminput}
														type="password"
														defaultValue={localstate.defaultpassword}
														onKeyUp={(event) => onChangeValue(event, "password", false)}
													/>
												</td>
											</tr>
											}
											{(userPerms.deleteUser === 1) &&
												<tr>
													<td>
													</td>
													<td>
													<Button variant="contained"
															sx={{backgroundColor:"red"}}
															onClick={() => DeleteUser()}
															>
																Delete User
														</Button>
													</td>
											</tr>
											}
										</tbody>
									</table>
								</div>
							</Grid>
							<Grid item sm={12} md={6}>
								<div className={classes.flextabledebug}>

									{/* Demo Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Demo Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Demo Data:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createDemoData === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createDemoData", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createDemoData === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Demo Data:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readDemoData === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readDemoData", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readDemoData === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Demo Data:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateDemoData === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateDemoData", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateDemoData === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Demo Data:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteDemoData === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteDemoData", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteDemoData === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Audit Demo Data:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.auditDemoData === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "auditDemoData", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.auditDemoData === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>



										{/* Demo Data Documents Perms */}
										<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Demo Data Documents
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Demo Data Document:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createDemoDataDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createDemoDataDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createDemoDataDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Demo Data Document:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readDemoDataDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readDemoDataDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readDemoDataDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Demo Data Document:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateDemoDataDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateDemoDataDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateDemoDataDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Demo Data Document:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteDemoDataDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteDemoDataDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteDemoDataDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>

									{/* User Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													User Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create User:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createUser === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createUser", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createUser === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read User:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readUser === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readUser", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readUser === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update User:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateUser === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateUser", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateUser === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete User:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteUser === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteUser", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteUser === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>




									{/* Item Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Item Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Item:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Item:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Item:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Item:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Audit Item:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.auditItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "auditItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.auditItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Bulk Edit:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.bulkEditItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "bulkEditItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.bulkEditItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Bulk Cost:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.bulkCostItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "bulkCostItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.bulkCostItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>


									{/* Sku Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Sku Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Sku:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createSku === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createSku", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createSku === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Sku:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readSku === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readSku", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readSku === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Sku:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateSku === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateSku", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateSku === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Sku:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteSku === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteSku", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteSku === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>


									{/* Sku Integration Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Sku Integration Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Sku Integration:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createSkuIntegration === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createSkuIntegration", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createSkuIntegration === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Sku Integration:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readSkuIntegration === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readSkuIntegration", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readSkuIntegration === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Sku Integration:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateSkuIntegration === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateSkuIntegration", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateSkuIntegration === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Sku Integration:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteSkuIntegration === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteSkuIntegration", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteSkuIntegration === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>

									{/* Calendar Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Calendar Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Event:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createCalendarEvent === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createCalendarEvent", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createCalendarEvent === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Event:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readCalendarEvent === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readCalendarEvent", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readCalendarEvent === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Event:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateCalendarEvent === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateCalendarEvent", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateCalendarEvent === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Event:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteCalendarEvent === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteCalendarEvent", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteCalendarEvent === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>




									{/* Product Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Product Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Product:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createProduct === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createProduct", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createProduct === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Product:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readProduct === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readProduct", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readProduct === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Product:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateProduct === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateProduct", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateProduct === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Product:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteProduct === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteProduct", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteProduct === 1 ? true : false)}
														/>
													}
												</td>
											</tr>

											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Product Marketing Doc:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createProductMarketingDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createProductMarketingDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createProductMarketingDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Product Marketing Doc:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readProductMarketingDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readProductMarketingDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readProductMarketingDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Product Marketing Doc:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateProductMarketingDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateProductMarketingDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateProductMarketingDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Product Marketing Doc:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteProductMarketingDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteProductMarketingDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteProductMarketingDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>


											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Product Tech Doc:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createProductTechDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createProductTechDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createProductTechDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Product Tech Doc:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readProductTechDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readProductTechDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readProductTechDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Product Tech Doc:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateProductTechDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateProductTechDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateProductTechDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Product Tech Doc:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteProductTechDoc === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteProductTechDoc", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteProductTechDoc === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											
										</tbody>
									</table>



									{/* Product Type Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Product Type Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Product Type:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createProductType === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createProductType", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createProductType === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Product Type:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readProductType === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readProductType", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readProductType === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Product Type:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateProductType === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateProductType", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateProductType === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Product Type:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteProductType === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteProductType", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteProductType === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>



									{/* Listing Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Listing Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Listing:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createListing === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createListing", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createListing === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Listing:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readListing === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readListing", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readListing === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Listing:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateListing === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateListing", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateListing === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Listing:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteListing === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteListing", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteListing === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>


									{/* component Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Component Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Component:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createComponent === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createComponent", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createComponent === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Component:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readComponent === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readComponent", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readComponent === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Component:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateComponent === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateComponent", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateComponent === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Component:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteComponent === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteComponent", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteComponent === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>

									{/* Component Type Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Component Type Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Component Type:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createComponentType === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createComponentType", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createComponentType === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Component Type:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readComponentType === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readComponentType", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readComponentType === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Component Type:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateComponentType === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateComponentType", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateComponentType === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Component Type:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteComponentType === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteComponentType", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteComponentType === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>



									{/* Order Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Order Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Order:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createOrder === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createOrder", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createOrder === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Order:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readOrder === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readOrder", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readOrder === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Order:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateOrder === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateOrder", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateOrder === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Order:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteOrder === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteOrder", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteOrder === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>



									{/* Order Item Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Order Item Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Order Item:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createOrderItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createOrderItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createOrderItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Order Item:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readOrderItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readOrderItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readOrderItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Order Item:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateOrderItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateOrderItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateOrderItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Order Item:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteOrderItem === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteOrderItem", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteOrderItem === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>


									{/* HDD Specs Data Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													HDD Specs Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create HDD Specs:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createHDDSpec === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createHDDSpec", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createHDDSpec === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read HDD Specs:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readHDDSpec === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readHDDSpec", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readHDDSpec === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update HDD Specs:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateHDDSpec === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateHDDSpec", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateHDDSpec === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete HDD Specs:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteHDDSpec === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteHDDSpec", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteHDDSpec === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>

									{/* Spec Alias Perms */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Spec Alias Data
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Create Spec Alias:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.createSpecAlias === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "createSpecAlias", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.createSpecAlias === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Spec Alias:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readSpecAlias === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readSpecAlias", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readSpecAlias === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Spec Alias:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateSpecAlias === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateSpecAlias", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateSpecAlias === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Delete Spec Alias:
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.deleteSpecAlias === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "deleteSpecAlias", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.deleteSpecAlias === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>



									{/* Dashboard - Mainpage Graphs */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Dashboard Graphs
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													User's Checkin
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readCheckinGraph === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readCheckinGraph", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readCheckinGraph === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													User's Repair
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readRepairGraph === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readRepairGraph", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readRepairGraph === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>


									{/* Order Inspection */}
									<table className={classes.itemtable}>
										<tbody>
											<tr>
												<td colSpan="2" style={{ textAlign: "center", textDecoration: "underline" }}>
													Order Inspection
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Read Inspection
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.readInspection === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "readInspection", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.readInspection === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
											<tr>
												<td style={{ width: "200px", paddingTop: "8px" }}>
													Update Inspection
												</td>
												<td style={{ verticalAlign: "bottom", paddingBottom: "4px" }}>
													{(userPerms.updateUser === 1) &&
														<Checkbox
															checked={(localstate.itemdata.userperms.updateInspection === 1 ? true : false)}
															className={classes.gridcheckbox}
															onClick={(event) => onChangeValue(event, "updateInspection", true)} />
													}
													{(userPerms.updateUser === 0) &&
														<Checkbox
															disabled
															checked={(localstate.itemdata.userperms.updateInspection === 1 ? true : false)}
														/>
													}
												</td>
											</tr>
										</tbody>
									</table>

								</div>
							</Grid>


						</Grid>
					</Typography>


				</div>
			}
		</LocalizationProvider>
	)
}

export default UserView;
