import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//let endpoint = "http://localhost:8080/api";
var hostbase = process.env.REACT_APP_ANGULAR_LEGACY_BASE;


//MainPage receives props
const Reports = props => {
	document.title="Reports";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Reports"));
	dispatch(setCurrentMenuItem("/reports"));
	//Hide Scroll Bars for injected content.
	return (
		<div style={{ textAlign: 'center', overflow: "hidden"}}>
			<div style={{ height: (window.innerHeight - 35) + "px" }}>
				<iframe src={hostbase+"/legacy/reports/reports?react=true"} style={{ width: "100%", border:"none" }} height={window.innerHeight - 35}></iframe>
			</div>

		</div>
	)
}


export default Reports;