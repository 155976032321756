import React, { useContext, useState } from "react";
import { AppContext } from "./contexts/AppContext";
//import { MdEmail } from "react-icons/md";
//import { GoKey } from "react-icons/go";
//import { FaRegEye } from "react-icons/fa";
//import { FaRegEyeSlash } from "react-icons/fa";

//import AuthMenu from "./AuthMenu";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import deluxelogo from '../images/deluxe.png';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)({
	width:"100%",
	color:"#01579B",
	boxShadow: 'none',
	textTransform: 'none',
	fontSize: 16,
	padding: '6px 12px',
	border: '1px solid',
	lineHeight: 1.5,
	backgroundColor: '#EEE',
	borderColor: '#00458f',
	fontFamily: [
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	'&:hover': {
		backgroundColor: '#DDD',
		borderColor: '#004983',
		boxShadow: 'none',
	},
	'&:active': {
		boxShadow: 'none',
		backgroundColor: '#004983',
		borderColor: '#005cbf',
	},
	'&:focus': {
		boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
	},
});

const AuthLogin = () => {
	const classes = useClasses(flexstyles);
	const appContext = useContext(AppContext);
	const {
		userEmail,
		userUserName,
		userPassword,
		handleUserEmail,
		handleUserUserName,
		handleUserPassword,
		login,
		errorMessage,
	} = appContext;

	//Unused right now
	const [hidePassword, setHidePassword] = useState(true);
	const showHiddenPassword = hidePassword ? "" : "hidden";
	const showRevealedPassword = hidePassword ? "hidden" : "";
	function togglePassword() {
		setHidePassword(!hidePassword);
	}


	return (
		<div style={{paddingTop:"20px"}}>
			<Box sx={{ width: 275, margin: "auto" }}>
			<Card variant="outlined" sx={{ backgroundColor: "#01579B", textAlign: "center", p: 3, my: 4 }}>
				<div>
					<img src={deluxelogo} />
				</div>

				<div className={classes.authinputcontainer}>
					<InputBase
						placeholder="Username"
						classes={{
							root: classes.authinputroot,
							input: classes.authinput,
						}}
						inputProps={{ 'aria-label': 'username' }}
						value={userUserName}
						onChange={handleUserUserName}
					/>
				</div>
				<div style={{ width: "100%", height: "8px" }}>
					&nbsp;
				</div>
				<div className={classes.authinputcontainer}>
					<InputBase
						placeholder="Password"
						type="password"
						classes={{
							root: classes.authinputroot,
							input: classes.authinput,
						}}
						inputProps={{ 'aria-label': 'username' }}
						value={userPassword}
						onChange={handleUserPassword}
					/>
				</div>
				<div style={{ width: "100%", height: "8px" }}>
					&nbsp;
				</div>
				<CustomButton variant="contained" disableRipple
					onClick={() => login()}>
					Login
				</CustomButton>

			</Card>
		</Box>
			{errorMessage}
		</div>
		
	);
};

export default AuthLogin;
