import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
//import { render } from 'react-dom'

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import App from './App';
import * as serviceWorker from './serviceWorker';



const container = document.getElementById('root');
const root = createRoot(container);



root.render(
	<BrowserRouter basename='/'>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>
);



// render(
// 	<BrowserRouter basename='/'>
// 		<Provider store={store}>
// 			<App />
// 		</Provider>
// 	</BrowserRouter>,
// 	document.querySelector('#root')
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

