import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//let endpoint = "http://localhost:8080/api";
var hostbase = process.env.REACT_APP_ANGULAR_LEGACY_BASE;

//MainPage receives props
const PriceCalculator = props => {
	document.title="Inventory Manager";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Inventory"));
	dispatch(setCurrentMenuItem("/pricecalculator"));
	//Hide Scroll Bars for injected content.
	return (
		<div style={{ textAlign: 'center', overflow: "hidden", paddingTop:"5px"}}>
			<div style={{ height: (window.innerHeight - 35) + "px" }}>
				<iframe src={hostbase+"/legacy/calculators?react=true"} style={{ width: "100%", border:"none" }} height={window.innerHeight - 35}></iframe>
			</div>

		</div>
	)
}


export default PriceCalculator;