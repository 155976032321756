//Recent Orders Widget

/* ##########################  Configuration Sections  ########################## */	
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions


//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

import React, { useState, useEffect, useContext, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import fetch from 'cross-fetch';
import MarketIcon from "../common/MarketIcon";







/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:11800,
};


const RecentOrders = (props) => {
	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");


	/* ##########################  UseState Variables  ########################## */ 
	/* Let's see if we can use the regular getorders method on our API */
	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the orders data. Set to false when we're just updating current view items.
		orders:[],		//Empty object
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws an error.
		order:"desc",
		orderby:"OrderDate",
		selectedcount:0,
		rowsperpage:100,
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		searchoptions:{
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs:{
				//Reserved for basic searchpairs. Possible injection of search parameter 'nameparameter' here.
				//Modes: like, left, right, strict, not
				searchpair1:{type:"", value: "", mode:"like", uuid:uuidv4()},
				searchpair2:{type:"", value: "", mode:"datebefore", uuid:uuidv4()},
				searchpair3:{type:"", value: "", mode:"left", uuid:uuidv4()},
				searchpair4:{type:"", value: "", mode:"right", uuid:uuidv4()},
				searchpair5:{type:"", value: "", mode:"like", uuid:uuidv4()},
				searchpair6:{type:"", value: "", mode:"like", uuid:uuidv4()},
				//Reserved for AutoComplete searchpairs.
				searchpair7:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair8:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair9:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair10:{type:"", value: "", mode:"strict", uuid:uuidv4()},
			},
			nestedrelationships:{
				
			},
			itemlist:""
		}
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Load Orders
	function LoadItems(){  
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs,
				nestedrelationships:localstate.searchoptions.nestedrelationships
			}
		};

		//Uses same method as Orders (react) page
		axios.post(dbendpoint+"/orders/ordersnew?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					localstate.orders = res.data.pagedata.data;
					UpdateState(localstate);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.orders=[];
					localstate.totalitems = 0;
					UpdateState(localstate);
				}
			} else {
				//Non-200 message from server.
			}
		});
	}


	useEffect(() => {
		if (state.dbreload){
			//Avoid duplicate loads.
			localstate.dbreload = false;
			LoadItems();
		}
	},);





	/* ##########################  Render Function  ########################## */
	return (
		<div style={{ overflowY: "scroll", height: "100%", "&::WebkitScrollbar": {display:"none",width:0, height:0}, msOverflowStyle: "none", scrollbarWidth:"none" }}>
				<table style={{ width: "100%", tableLayout: "fixed", overflow: "auto" }}>
					<thead>
						<tr>
							<td style={{ textAlign: "center", width: "7%" }}>
								Market
							</td>
							<td style={{
								whiteSpace: "nowrap",
								width: "85%",
								overflow: "hidden",
								textOverflow: "ellipsis",
								textAlign: "left"
							}}>
								Product
							</td>
							<td style={{ width: "8%", textAlign: "right" }}>
								Price
							</td>
						</tr>
					</thead>
					<tbody>
						{(localstate.orders.length > 0) ?
							localstate.orders.map((order, orderindex) => {
								return (
									order.orderitems.map((orderitem, itemindex) => {
										return (
											<tr key={itemindex}>
												<td><MarketIcon height="20px" storename={order.Store} verticalalignment="bottom" /></td>
												<td style={{ width: "90%", textAlign: "left", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", paddingRight: "10px" }}>
													{orderitem.ProductName}</td>
												<td style={{textAlign:"right"}}>{orderitem.TotalPrice}</td>
											</tr>
										)
									})
								)
							})
							: null}
					</tbody>
				</table>
		</div>

	);
}

export default RecentOrders;
