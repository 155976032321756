import { createSlice } from '@reduxjs/toolkit'

export const mainmenuSlice = createSlice({
  name: 'mainmenu',
  initialState: {
    mainmenustate: {
		//Home page:
		currentsection: false,
		currentitem: false
	}
  },
  reducers: {
	//When selecting a section from the menu itself, give the option to collapse the section!
	toggleCurrentMenuSelection: (state, action) => {
		if (state.mainmenustate.currentsection===action.payload){
			state.mainmenustate.currentsection = false;
		} else {
			state.mainmenustate.currentsection = action.payload;
		}
	},

	setCurrentMenuSection: (state, action) => {
		state.mainmenustate.currentsection = action.payload;
	},
	setCurrentMenuItem: (state, action) => {
		//state.mainmenustate.currentsection = "Orders";
		state.mainmenustate.currentitem = action.payload;
	},

  },
})

export const { setCurrentMenuSection, setCurrentMenuItem, toggleCurrentMenuSelection } = mainmenuSlice.actions

export const selectMainMenu = (state) => state.mainmenu.mainmenustate;

export default mainmenuSlice.reducer
