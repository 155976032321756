const AudioMessage = (message) =>{
	if ('speechSynthesis' in window) {
		// Speech Synthesis supported 🎉
		var msg = new SpeechSynthesisUtterance();
		msg.text = message;
		window.speechSynthesis.speak(msg);
	} else {
		console.log("Sorry, your browser doesn't support text to speech!");
	}
}

export default AudioMessage;

