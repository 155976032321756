
import React, { useState, useEffect, useRef, useContext } from 'react';

//Auth
import { AppContext, AppProvider } from "./Components/Auth/contexts/AppContext"
import { ProgressProvider } from './Components/common/ProgressContext';
import { ErrorProvider } from './Components/common/ErrorContext';

import AuthContainer from "./Components/Auth/AuthContainer"
import AuthLogout from "./Components/Auth/AuthLogout"
import LoginPage from "./Components/Auth/LoginPage"

import {
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
  useParams
} from "react-router-dom";
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


//Theme generated from color picker and subsequent tool:
//https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=01579B&secondary.color=ECEFF1
//https://react-theming.github.io/create-mui-theme/
//Theme sets default Material UI for all elements. We also use theme in our new classes and overrides in FlexCss"

//CSS Styles
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

//import { useTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'; 
import deluxetheme1 from "./ui/theme/index";
import flexstyles from './css/FlexCss';
import useCustomStyles from './ui/useCustomStyles';
import useClasses from './ui/useClasses';
import { useMediaQuery } from "@mui/material";

//Main Menu
import { MainMenu } from './features/mainmenu/MainMenu';



import 'typeface-roboto';
import deluxelogo from './Components/images/deluxe.png';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Collapse from '@mui/material/Collapse';
 
import MainPage from "./Components/MainPage"; //Legacy Dashboard
import BoilerplateTable from "./Components/Boilerplate/BoilerplateTable";
import BoilerplateKeyValues from "./Components/Boilerplate/BoilerplateKeyValues";
import BoilerplateTableTest from "./Components/Boilerplate/BoilerplateTableTest";
import ChartBuilder from "./Components/Boilerplate/ChartBuilder";
import Toybox from "./Components/Boilerplate/Toybox";


//Login
import Login from "./Components/Login";

//Admin Section
import UserTable from "./Components/Admin/UserTable";
import UserView from "./Components/Admin/UserView";
import ClientAccess from "./Components/Admin/ClientAccess";
import Locations from "./Components/Admin/Locations";
import IntegrationsTable from "./Components/Admin/IntegrationsTable";
import ReportDownloads from "./Components/Admin/ReportDownloads";
import LogTable from "./Components/Admin/LogTable";
import ItemPrograms from "./Components/Admin/ItemPrograms";

//Products
import Products from "./Components/Products/Products"; //Legacy
import ProductsTable from "./Components/Products/ProductsTable";
import ProductTypesTable from "./Components/Products/ProductTypesTable";
import ProductView from "./Components/Products/ProductView";
import SkusTable from "./Components/Products/SkusTable";
import SkuIntegrationsTable from "./Components/Products/SkuIntegrationsTable";
import ComponentsTable from "./Components/Products/ComponentsTable";
import ComponentTypesTable from "./Components/Products/ComponentTypesTable";

//Hardware Agent
import SpecsTable from "./Components/Specs/SpecsTable";
import SpecAliasTable from "./Components/Specs/SpecAliasTable";
import BatteryTest from "./Components/Specs/BatteryTest";
import HDDSpecs from "./Components/Specs/HDDSpecs";

//Inventory Manager
//import Items from "./Components/Inventory/Items"; //Legacy - Killed 9/10/2024
import Checkin from "./Components/Inventory/Checkin"; //Legacy
import Checkout from "./Components/Inventory/Checkout"; //Legacy
import InventoryManager from "./Components/Inventory/InventoryManager";
import Purchasing from "./Components/Inventory/Purchasing";
import Item from "./Components/Inventory/Item";
import RowMonitor from "./Components/Inventory/RowMonitor";
import Costing from "./Components/Inventory/Costing";
import ListingsTable from "./Components/Inventory/ListingsTable";


//Orders
import OrdersTable from "./Components/Orders/OrdersTable";
import OrderInspection from "./Components/Orders/OrderInspection";
import Orders from "./Components/Orders/Orders"; //Legacy - Soon to be new React
import OrdersView from "./Components/Orders/OrdersView";
import OrderProcessing from "./Components/Orders/OrderProcessing"; //Legacy
//import OrderManifests from "./Components/Orders/OrderManifests"; //Legacy
import OrderViewLegacy from "./Components/Orders/OrderViewLegacy"; //Front for legacy

//Reports
import Reports from "./Components/Reports/Reports"; //Legacy
import SalesGraph from "./Components/Reports/SalesGraph"; //Legacy
import TopProducts from "./Components/Reports/TopProducts"; //Legacy
import ProductPerformance from "./Components/Reports/ProductPerformance"; //Legacy

//Calculator
import PriceCalculator from "./Components/common/PriceCalculator"; //Legacy

//Test of React Redux
import { Counter } from './features/counter/Counter';



//Config
let appbase = process.env.REACT_APP_BASE;

//CSS
//const styles = (deluxetheme1) => (flexstyles);


const App = () => {
	/* CSS and Media Queries */
	//const theme = useTheme();
	//const classes = useCustomStyles(styles, theme);
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");
	let history = useHistory();

	//Get current location
	let location = useLocation();
	const [state, setState] = useState({
		loginstate: false,
		userrole: '',
		username:'',
		appstart: true,
		authchecked:false,
		timeout: 0,
		selectedmenu:"",
		sessioninterval:false,
		navigate:false,
		navigateto:"/",
		hidemenus:false,
		viewid:uuidv4(),
		//Bool for view t/f
		//inventorymanager:invmgr,
		//Try! Orders Table State
		orderstablestate:{}
		
	})

	const updateState = (stateObject) => {
		console.log("updateState");
		setState(stateObject)
	}

	//Clone State for mutability
	let localstate = Object.assign({}, state);

	axios.defaults.withCredentials = true;
	var requestconfig = {
		withCredentials: true,
		withXSRFToken: true,
		crossDomain: true,
		mode: 'no-cors'
	}


	function ToggleMenus() {
		localstate.hidemenus=!localstate.hidemenus;
		updateState(localstate);
	}

	const preventDefault = (event) => event.preventDefault();

	//Causes this to run once, otherwise endless loop
	useEffect(() => {
		if (state.appstart) {
			localstate.selectedmenu = location.pathname;
		} 
		if (state.navigate){
			localstate.navigate = false;
			history.push(localstate.navigateto);
			updateState(localstate);
		}
	})



	const LoggedInContent = () => {
		const appContext = useContext(AppContext);
		const { authStatus, checklogin, userUserName, logout, userRole, setCurrentMenu, currentmenu, userPerms, userTZ } = appContext;


		const LogUserOut = () => {
			//Sync localstate! Is within lexical scope?
			localstate.loginstate = false;
			updateState(localstate);
			logout();
		}

		//Expanding Menus (open/close) - Uses context
		const handleMenuClick = (menuitem) => {
			if (menuitem === currentmenu) {
				setCurrentMenu("");
			} else {
				setCurrentMenu(menuitem);
			}
		};


		//The following will update our parent App (this), BEFORE we navigate to the following page. Avoids attempting to load the new page's database items twice.
		const handleClickMenuItem = (menuitem) => {
			localstate.navigate = true;
			localstate.navigateto = menuitem;
			localstate.selectedmenu = menuitem;
			updateState(localstate);
		}

		return (
			<>
					<MuiThemeProvider theme={deluxetheme1}>
						<ThemeProvider theme={deluxetheme1}>
					<div className={classes.root}>
						<CssBaseline />
						{(!state.hidemenus) &&
							<AppBar position="fixed" className={classes.appBar} style={(appbase.indexOf('localhost')>-1) ? {backgroundColor:"red"} : {backgroundColor:"#004983"}}>
								<Toolbar style={{ minHeight: '30px' }}>
									<Grid
										justifyContent="space-between" // Add it here :)
										container
										spacing={1}
									>
										<Grid item>
											<a target="_blank" href="/">
												<img src={deluxelogo} alt="Flex System" style={{ width: "150px", paddingTop: "5px" }} />
											</a>
										</Grid>
										<Grid item>
											<div style={{ padding: "8px 12px 0px 2px", display: "inline-block" }}>
												Logged in as {userUserName}
											</div>

											<Button color="inherit" onClick={LogUserOut} style={{ margin: "4px 2px 2px 2px", border: "1px solid #FFF", padding: "2px 5px", verticalAlign: "top" }}>
												Logout
											</Button>

											{(!state.hidemenus) &&
												<Button color="inherit" onClick={ToggleMenus} style={{ margin: "4px 0px 2px 8px", border: "1px solid #FFF", padding: "2px 5px", verticalAlign: "top" }}>
													Hide Menus
												</Button>
											}
										</Grid>
									</Grid>
								</Toolbar>
							</AppBar>
						}



						{/* ####     LOGGED-IN DRAWER MENU    #### */}
						{/* Hide drawer menu for Inventory Manager */}
						{(!state.hidemenus && !isPrintView) &&
							<MainMenu />
						}


						{/* ####     CONTENT AREA    #### */}
						<main style={{ overflow: "auto", width:"100%" }}>
							<div className={state.hidemenus ? classes.contentwithoutmenus : classes.contentwithmenus} id="flexcontentcontainer">
								{(state.hidemenus) &&
									<div style={{ height: "0px", textAlign: "right", position: "relative" }}>
										{/* See if we can put in a small menubox here */}
										<div className={classes.showmenubtn} style={{ height: "20px", right: "0px", zIndex: "5000" }}>
											<a onClick={ToggleMenus} className={classes.hoverunit}>Show Menus</a>
										</div>
									</div>
								}

								
									<Switch>
										<Route exact path="/" render={() => <ErrorProvider><MainPage /></ErrorProvider>} />
										<Route path="/boilerplatetable" render={(props) => <ErrorProvider><ProgressProvider><BoilerplateTable /></ProgressProvider></ErrorProvider>} />
										<Route path="/boilerplatekeyvalues/:id" render={(props) => <ErrorProvider><BoilerplateKeyValues /></ErrorProvider>} />
										<Route path="/boilerplatetabletest" render={(props) => <ErrorProvider><BoilerplateTableTest /></ErrorProvider>} />
										<Route path="/chartbuilder" render={(props) => <ErrorProvider><ChartBuilder /></ErrorProvider>} />
										<Route path="/toybox" render={(props) => <ErrorProvider><Toybox /></ErrorProvider>} />

										{/* Orders */}
										{/* New Route: */}
										<Route path="/orderstable" render={(props) => <ErrorProvider><OrdersTable appstate={state} updateState={updateState} /></ErrorProvider>} />
										<Route path="/orderinspection" render={(props) => <ErrorProvider><OrderInspection appstate={state} updateState={updateState} /></ErrorProvider>} />
										<Route path="/order/:id" render={(props) => <ErrorProvider><OrdersView isChild={false} viewmode="details" appstate={state} updateState={updateState} /></ErrorProvider>} />
										{/* Old Route: */}<Route path="/orders" render={(props) => <ErrorProvider><Orders appstate={state} updateState={updateState} /></ErrorProvider>} />

						
										{/* <Route path="/ordersview" render={(props) => <ErrorProvider><OrdersView mode="edit" appstate={state} updateState={updateState} /></ErrorProvider>} /> */}
										<Route path="/order/:id" render={(props) => <ErrorProvider><OrdersTable appstate={state} updateState={updateState} /></ErrorProvider>} />
										{/* <Route path="/orderinvoices" render={(props) => <ErrorProvider><OrdersView mode="invoices" updateState={updateState} /></ErrorProvider>} />
										<Route path="/orderworksheets" render={(props) => <ErrorProvider><OrdersView mode="worksheets" updateState={updateState} /></ErrorProvider>} /> */}
										<Route path="/orderprocessing" render={(props) => <ErrorProvider><OrderProcessing /></ErrorProvider>} />
										{/* <Route path="/ordermanifests" render={(props) => <ErrorProvider><OrderManifests /></ErrorProvider>} /> */}

										{/* Legacy Order View */}
										<Route path="/orderview/:orderid" render={(props) => <ErrorProvider><OrderViewLegacy updateState={updateState} /></ErrorProvider>} />

										{/* Products */}
										<Route path="/products" render={(props) => <ErrorProvider><ProductsTable /></ErrorProvider>} />
										<Route path="/product/:id" render={(props) => <ErrorProvider><ProductView /></ErrorProvider>} />
										<Route path="/productslegacy" render={(props) => <ErrorProvider><Products /></ErrorProvider>} />
										<Route path="/producttypes" render={(props) => <ErrorProvider><ProductTypesTable/></ErrorProvider>} />
										<Route path="/components" render={(props) => <ErrorProvider><ComponentsTable /></ErrorProvider>} />
										<Route path="/componenttypes" render={(props) => <ErrorProvider><ComponentTypesTable /></ErrorProvider>} />
										<Route path="/skus" render={(props) => <ErrorProvider><SkusTable /></ErrorProvider>} />
										<Route path="/skuintegrations" render={(props) => <ErrorProvider><SkuIntegrationsTable /></ErrorProvider>} />

										{/* Hardware Agent */}
										<Route path="/specs" render={(props) => <ErrorProvider><SpecsTable /></ErrorProvider>} />
										<Route path="/specalias" render={(props) => <ErrorProvider><SpecAliasTable /></ErrorProvider>} />
										<Route path="/batterytest" render={(props) => <ErrorProvider><BatteryTest /></ErrorProvider>} />
										<Route path="/hddspecs" render={(props) => <ErrorProvider><HDDSpecs /></ErrorProvider>} />

										{/* Reports */}
										<Route path="/reports" render={(props) => <ErrorProvider><Reports /></ErrorProvider>} />
										<Route path="/salesgraph" render={(props) => <ErrorProvider><SalesGraph /></ErrorProvider>} />
										<Route path="/topproducts" render={(props) => <ErrorProvider><TopProducts /></ErrorProvider>} />
										<Route path="/productperformance" render={(props) => <ErrorProvider><ProductPerformance /></ErrorProvider>} />

										{/*Admin Settings*/}
										<Route path="/users" render={(props) => <ErrorProvider><UserTable /></ErrorProvider>} />
										<Route path="/user/:id" render={(props) => <ErrorProvider><UserView /></ErrorProvider>} />
										<Route path="/clientaccess" render={(props) => <ErrorProvider><ClientAccess theme={deluxetheme1} /></ErrorProvider>} />
										<Route path="/integrations" render={(props) => <ErrorProvider><IntegrationsTable /></ErrorProvider>} />
										<Route path="/logs" render={(props) => <ErrorProvider><LogTable /></ErrorProvider>} />
										<Route path="/itemprograms" render={(props) => <ErrorProvider><ItemPrograms /></ErrorProvider>} />

										{/*Inventory Items*/}
										{/* <Route path="/items" render={(props) => <ErrorProvider><Items appstate={state} updateState={updateState} /></ErrorProvider>} /> */}
										<Route path="/item/:id" render={(props) => <ErrorProvider><Item appstate={state} updateState={updateState} /></ErrorProvider>} />
										<Route path="/checkin" render={(props) => <ErrorProvider><Checkin appstate={state} updateState={updateState} /></ErrorProvider>} />
										<Route path="/checkout" render={(props) => <ErrorProvider><Checkout appstate={state} updateState={updateState} /></ErrorProvider>} />
										<Route path="/inventorymanager" render={(props) => <ErrorProvider><InventoryManager /></ErrorProvider>} />
										<Route path="/purchasing" render={(props) => <ErrorProvider><Purchasing /></ErrorProvider>} />
										<Route path="/rowmonitor" render={(props) => <ErrorProvider><RowMonitor appstate={state} updateState={updateState} /></ErrorProvider>} />
										<Route path="/pricing" render={(props) => <ErrorProvider><Costing appstate={state} updateState={updateState} /></ErrorProvider>} />
										<Route path="/listings" render={(props) => <ErrorProvider><ListingsTable appstate={state} updateState={updateState} /></ErrorProvider>} />

										<Route path="/pricecalculator" render={(props) => <ErrorProvider><PriceCalculator /></ErrorProvider>} />

										  {/* Serve static files directly */}
     									<Route path="/storage" component={() => window.location = window.location.href} />

									</Switch>
								
							</div>
						</main>
					</div>

				</ThemeProvider>
					</MuiThemeProvider>
			
			</>
		)

	}

	//One container to manage view via shared App Context
	const AppContainer = () => {
		const appContext = useContext(AppContext);
		const { authStatus, logout, checklogin, appStarted } = appContext;
		useEffect(()=>{
			//How often does this run.
			//console.log("UseEffect we just made;");
			checklogin();
		},[]);

		return (
			<>

				{/* Otimistic. If the server doesn't recognize their token, it will redirect the user to login anyway. */}
				{(authStatus==="logged_in" && appStarted) &&
					<LoggedInContent />
				}

				{/* We don't want to flash the LoginPage if we haven't given ourselves an opportunity to log in automatically. */}
				{(authStatus!=="logged_in" && appStarted) &&
					<LoginPage state={state} updateState={updateState}/>
				}

				{/*
				<div>
					AppStart: {String(appStarted)}<br></br>
					AuthStatus: {String(authStatus)}<br></br>
					AuthChecked: {String(state.authchecked)}<br></br>
					LoginState: {String(state.loginstate)}<br></br>
				</div>
				*/}
				
			</>
		)
	}



	//Render
	return (
		<StyledEngineProvider injectFirst>
			<AppProvider>
				<AppContainer state={state}/>
			</AppProvider>
		</StyledEngineProvider>
	);
}

  
export default App;