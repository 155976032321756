//Boilerplate Table

/* ##########################  Configuration Sections  ########################## */	
//## UseState Variables
//## Column States
//##Column Configuration
//##Column Toggles
//##Row Design
//##Search Inputs
//##Button Functions


//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';

//Redux Features
import { useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
  } from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';

//Search Tools
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';


//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

//Icons
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:20000,
};

const ComponentsTable = (props) => {
	document.title="Components";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Products"));
	dispatch(setCurrentMenuItem("/components"));


	let history = useHistory();

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	
	const rowRefs = useRef([]);

	/* ##########################  UseState Variables  ########################## */	  
	const classes = useClasses(flexstyles);
	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we"re just updating current view items.
		griditems:[],		//Defaults
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws an error.
		order:"asc",
		orderby:"Name",
		selectedcount:0,
		rowsperpage:100,
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		searchoptions:{
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs:{
				searchpair1:{type:"Name", value: "", mode:"like", uuid:uuidv4()},
				searchpair2:{type:"ComponentType", value: "", mode:"like", uuid:uuidv4()},
				searchpair3:{type:"ProductType", value: "", mode:"like", uuid:uuidv4()},
				searchpair4:{type:"", value: "", mode:"right", uuid:uuidv4()},
				searchpair5:{type:"", value: "", mode:"like", uuid:uuidv4()},
				searchpair6:{type:"", value: "", mode:"like", uuid:uuidv4()}
			}
		}
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);
	
	function UpdateState(stateobject) {
		setState(stateobject);
	}




	/* ##########################  Top Button Refs and Functions  ########################## */	 
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnAddRow = useRef();
	const btnDeleteSelected = useRef();
	//const btnResetSearches = useRef();


	//Disable Buttons
	const DisableButtons = () => {
		btnSave.current.setAttribute("disabled", true);
		btnPendingSave.current.setAttribute("disabled", true);
		btnAddRow.current.setAttribute("disabled", true);
		btnDeleteSelected.current.setAttribute("disabled", true);
		//btnResetSearches.current.setAttribute("disabled", true);
	}

	//Enable Buttons
	const EnableButtons = () => {
		btnSave.current.removeAttribute("disabled");
		btnPendingSave.current.removeAttribute("disabled");
		btnAddRow.current.removeAttribute("disabled");
		btnDeleteSelected.current.removeAttribute("disabled");
		//btnResetSearches.current.removeAttribute("disabled");
	}




	/* ##########################  Column States  ########################## */	 
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		Name:true,
		ComponentType:true,
		ProductType:true,
		Cost:true,
		PullRate:true,
		SearchBool:true,
		SearchAlias:true
	});

	const UpdateColState = (colstate) =>{
		setColState(colstate);
	}


	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length===0){
			errors.NewError({errmsg:"No items selected.", errshow:true, errtimeout: 5, errtype:'neutral'});
		} else {
			//If all tests pass, use callback
			fnCallback(value);
		}
	}


	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);
	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = ! newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover}/>
				}
			</React.Fragment>
		)
	}

	const SelectRow = (index) => {
		if (localstate.selectedindexes.indexOf(index)===-1){
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length){
				UpdateState(localstate);			
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex,1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length+1)){
				UpdateState(localstate);			
			}
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i=0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
	};



	/* ##########################  Search Options  ########################## */

	//Example for auto-complete
	const [opennameoptions, setOpenNameOptions] = React.useState(false);
	const [nameoptions, setNameOptions] = React.useState([]);
	const loadingnameoptions = opennameoptions && nameoptions.length === 0;

	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	//Pre-set Key
	const onChangeSearch = debounce(function(searchtype, searchvalue, itembool, searchpair) {
		//If an item is selected, then deselected, itembool is still true, but the searchvalue becomes null.
		//If item selected from list (itembool), grab it's key's value instead 'name'
		if (itembool) {
			if (searchvalue !== null){
				searchvalue = searchvalue.name;
			} else {
				searchvalue="";
			}
		}
		//New API:
		console.log(searchtype);
		console.log(searchvalue);
		switch (searchpair) {
			case "searchpair1":
				localstate.searchoptions.searchpairs.searchpair1.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair1.value = searchvalue;
				break;
			case "searchpair2":
				localstate.searchoptions.searchpairs.searchpair2.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair2.value = searchvalue;
				break;
			case "searchpair3":
				localstate.searchoptions.searchpairs.searchpair3.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair3.value = searchvalue;
				break;
			case "searchpair4":
				localstate.searchoptions.searchpairs.searchpair4.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair4.value = searchvalue;
				break;
			default:
				break;
		}
		localstate.dbreload=true;
		UpdateState(localstate);
	}, 800);


	//API Build-out: 
	//	Old: Types [searchtype, sub1type, sub2type] , Search [search, sub1search, sub2search]
	//	New: Types [searchtype1, searchtype2, searchtype3], Search [search1, search2, search3]
	//Set Search Key:
	var mode="";
	const onChangeSearchType = (searchtype, search) => {
		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict
		//Default Mode: LIKE
		mode = "like";
		//Provision for automatically switching search mode
		if (searchtype==="Location"){
			mode="strict";
		}

		switch (search) {
			case "search1":
				localstate.searchoptions.searchpairs.searchpair1.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair1.mode = mode;
				break;
			case "search2":
				localstate.searchoptions.searchpairs.searchpair2.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair2.mode = mode;
				break;
			case "search3":
				localstate.searchoptions.searchpairs.searchpair3.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair3.mode = mode;
				break;
			case "search4":
				localstate.searchoptions.searchpairs.searchpair4.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair4.mode = mode;
				break;
			case "search5":
				localstate.searchoptions.searchpairs.searchpair5.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair5.mode = mode;
				break;
			case "search6":
				localstate.searchoptions.searchpairs.searchpair6.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair6.mode = mode;
				break;
			default:
				break;
        }
        
        //Provision to add columns if selected for search.
        if (searchtype==="Name"){
            colstate["Name"] = true;
            //setMenuEl(null);
        }
		UpdateState(localstate);
	}

	//Set Search Value:
	const onChangeSearchValue = debounce(function(searchvalue, search) {
		//Clears out changes because table is about to be reloaded:
		ResetPendingSaves();
		switch (search) {
			case "search1":
				localstate.searchoptions.searchpairs.searchpair1.value = searchvalue;
				break;
			case "search2":
				localstate.searchoptions.searchpairs.searchpair2.value = searchvalue;
				break;
			case "search3":
				localstate.searchoptions.searchpairs.searchpair3.value = searchvalue;
				break;
			case "search4":
				localstate.searchoptions.searchpairs.searchpair4.value = searchvalue;
				break;
			case "search5":
				localstate.searchoptions.searchpairs.searchpair5.value = searchvalue;
				break;
			case "search6":
				localstate.searchoptions.searchpairs.searchpair6.value = searchvalue;
				break;
			default:
				break;
		}
		localstate.dbreload = true;
		UpdateState(localstate);
	},600);

	//Key-Value Inputs
	const showsearch1 = true;
	const [showsearch2, setShowSearch2] = useState(true);
	const [showsearch3, setShowSearch3] = useState(true);
	const [showsearch4, setShowSearch4] = useState(false);
	const [showsearch5, setShowSearch5] = useState(false);
	const [showsearch6, setShowSearch6] = useState(false);
	const [lastsearch, setLastSearch] = useState("showsearch3");


	const AddSearch = () => {
		switch (lastsearch){
			case "showsearch1":
				setShowSearch2(true);
				setLastSearch("showsearch2");
				break;
			case "showsearch2":
				setShowSearch3(true);
				setLastSearch("showsearch3");
				break;
			case "showsearch3":
				setShowSearch4(true);
				setLastSearch("showsearch4");
				break;
			case "showsearch4":
				setShowSearch5(true);
				setLastSearch("showsearch5");
				break;
			case "showsearch5":
				setShowSearch6(true);
				setLastSearch("showsearch6");
				break;
			default:
				break;
		}
	}

	const RemoveSearch = (searchinput) => {
		switch (searchinput){
			case "search2":
				localstate.searchoptions.searchpairs.searchpair2.value = "";
				localstate.dbreload = true;
				setLastSearch("showsearch1");
				setShowSearch2(false);
				UpdateState(localstate);
				break;
			case "search3":
				localstate.searchoptions.searchpairs.searchpair3.value = "";
				localstate.dbreload = true;
				setLastSearch("showsearch2");
				setShowSearch3(false);
				UpdateState(localstate);
				break;
			case "search4":
				localstate.searchoptions.searchpairs.searchpair4.value = "";
				localstate.dbreload = true;
				setLastSearch("showsearch3");
				setShowSearch4(false);
				UpdateState(localstate);
				break;
			case "search5":
				localstate.searchoptions.searchpairs.searchpair5.value = "";
				localstate.dbreload = true;
				setLastSearch("showsearch4");
				setShowSearch5(false);
				UpdateState(localstate);
				break;
			case "search6":
				localstate.searchoptions.searchpairs.searchpair6.value = "";
				localstate.dbreload = true;
				setLastSearch("showsearch5");
				setShowSearch6(false);
				UpdateState(localstate);
				break;
			default:
				break;
		}
	}


	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";		
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);		
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page=0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems(){  
		localstate.selectedindexes = [];
		localstate.selectedcount=0;
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs
			}
		};
		var postoptions = {
			withCredentials:true,
			withXSRFToken: true,
			crossDomain:true,
			mode:"no-cors",
			timeout:11800,
		};
		
		axios.post(dbendpoint+"/components/get?page="+(localstate.page+1), postdata, postoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					for (var i =0; i<res.data.pagedata.data.length; i++){
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						res.data.pagedata.data[i].GridKey=i;
						res.data.pagedata.data[i].isSelected = false;
						res.data.pagedata.data[i].unsaved = false;
						res.data.pagedata.data[i].ExpandRow = false;
					}
					localstate.griditems = res.data.pagedata.data;
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.griditems=[];
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}

	useEffect(() => {
		if (state.dbreload){
			//Avoid duplicate loades.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
		}
	},);


	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	const AddRow = () => {
		localstate.griditems.unshift({
			ID:uuidv4(),
			Name:"",
			PendingItem:true,
			ComponentType:"",
			ProductType:"",
			Cost:0.00,
			PullRate:0.00,
			SearchBool:false,
			SearchAlias:""
		});
		//All selected indexes move up by 1.
		for (var i=0; i<localstate.selectedindexes.length; i++){
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}


	//A Ref stays consistent throughout async function
	const requestcount = useRef(0);




	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		//Configuration
		requestcount.current = 0;
		var timeout = 300; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
		var requestinterval = 100; //Milliseconds

		var updatearray = [];
		for (var i = 0; i < localstate.griditems.length; i++) {
			if (localstate.griditems[i].unsaved) {
				updatearray.push(localstate.griditems[i]);
			}
		}

		if (updatearray.length > 0) {
			DisableButtons();
			dispatch(newProgress({
				msg: 'Saving components...',
				show: true,
				settimeout: true, //This option allows for a timeout after the final item is pass/failed
				timeout: timeout, //Initiated on final item 
				type: 'items', //Labels your save types such as items, products, skus, parts
				finished: 0,
				percent: "0%",
				total: updatearray.length,
				pass: 0,
				fail: 0,
				faillist: [], //Can take simple values,
				faillinks: [], //Can take links to things like items or products.
				timeoutid: false,
				errors:[] //Can take strings
			}));
			i = 0;
			var limit = updatearray.length - 1;
			/* INTERVAL */
			var updateitems = setInterval(function () {
				var item = updatearray[i];
				const postdata = {
					item: item
				};
				axios.post(dbendpoint + "/components/update", postdata, defaultpostoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status === 200) {
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status === "login") {
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status === "Success") {
							//Success response also includes the item!
							//If we sent new rows, we'll need to reference the old ID.
							var itemindex = 0;
							if (res.data.OldID) {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
								//Set New ID
								localstate.griditems[itemindex].ID = res.data.item.ID;
							} else {
								itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.item.ID);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
							}
							dispatch(incrementPass());
						}
						if (res.data.Status === "Failure") {
							//Failure error - To Do: Dispatch failure messages to the ProgressBar
							dispatch(incrementFail());
						}
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);

					} else {
						//Non-200 message from server.
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
						dispatch(incrementFail());
					}
				}).catch(err => {
					//Non-200, 500 Error, timeout?
					requestcount.current++;
					FinalizeRequest(requestcount.current, limit, timeout);
					dispatch(incrementFail());
				});
				//If we have looped through all items, clear this interval.
				if (i === limit) {
					clearInterval(updateitems);
				}
				i++;
			}, requestinterval);
		} else {
			errors.NewError({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" })
			ResetPendingSaves();
		}
	}




	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i-1)===limit){
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display="";
			btnPendingSave.current.style.display="none";
		}
	} 


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i+'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}


	const ResetPendingSaves = () =>{
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				localstate.griditems[i].unsaved = false;
			}
		}
		EnableButtons();
		btnSave.current.style.display="";
		btnPendingSave.current.style.display="none";
	}





	const [showconfirmation, setShowConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () =>{
		var deleteitemsarray =[];
		//Reflect items to user for confimation.
		for (var i=0; i<state.selectedindexes.length; i++){
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowConfirmation(true);
	}
	const DeleteSelected = () => {
		var finishedrequests = 0;
		var postoptions = {
			withCredentials:true,
			withXSRFToken: true,
			crossDomain:true,
			mode:"no-cors",
			timeout:11800,
		};

		for (var i=0; i<localstate.selectedindexes.length; i++){
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")){
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {				
					item:localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint+"/components/delete", postdata, postoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;	
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status===200){
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status==="login"){
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status==="Success"){
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID){
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
							} else {
								errors.NewError({errmsg:"Could not delete one or more items.", errshow:true, errtimeout: 8, errtype:"warning"})
							}
						}
						if (res.data.Status==="Failure"){
							//Failure error
							errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
						}
					} else {
						//Non-200 message from server.
						errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowConfirmation(false);
	}


	/* ##########################  Cell Interaction  ########################## */

	const onChangeName = (event, index) => {
		if (event.key === "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+"Name"]){
				rowRefs.current[(index+1)+"Name"].focus();
			} else {
				rowRefs.current[("0Name")].focus();
			}
		} else {
			//Update ref
			rowRefs.current[index+"Name"].value=event.target.value;
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index].Name = event.target.value;
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			btnSave.current.style.color="white";
			btnSave.current.style.backgroundColor="#01579B";
		}
	}

	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	//Possibly add SearchBool to this
	const taborder = ["Name", "ComponentType", "ProductType", "Cost", "PullRate", "SearchAlias"];

	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Deny Decimals for Integer inputs!
		console.log("Key: "+event.key);
		if (event.key === "." && column==="SomePositiveInteger"){
			console.log("Prevent decimal in integer input");
			event.preventDefault();
		}
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown"){
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp"){
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length-1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
		//Handle Right Arrow - Special cases:
		/*
		if (event.key === "ArrowRight"){
			event.preventDefault();
			//Same Process as Tab
			var tabindex = taborder.indexOf(column);
			for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
				if (i === taborder.length) {
					i = -1;
					if (rowRefs.current[(index + 1) + column]) {
						index = index + 1;
					} else {
						index = 0;
					}
				} else {
					if (taborder[i]) {
						if (colstate[taborder[i]]) {
							rowRefs.current[index + taborder[i]].select();
							break;
						}
					} else {
						i = -1;
					}
				}
			}
		}
		//Handle Left Arrow
		if (event.key === "ArrowLeft"){
			event.preventDefault();
			var tabindex = taborder.indexOf(column);
			var i;
			var rowincrement=0;
			for (i=(tabindex-1); i>-2; i--){
				if (i===-1){
					rowincrement=-1;
					i=taborder.length; //Go to end of tab order
				} else {
					if (colstate[taborder[i]]) {
						break;
					}
				}
			}
			//If tabindex first and we're in the first row, go to the last element on last row.
			if (tabindex===0 && index===0){
				var lastindex = localstate.griditems.length-1;
				rowRefs.current[lastindex + taborder[i]].select();
			} else {
				rowRefs.current[(index+rowincrement) + taborder[i]].select();
			}
		}
		*/
	}


	//Useful for capturing Tabs\Enters on inputs
	const DetectTab = (event, index, column) => {
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Try:
				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
	}

	//Restrict Number (too many places past the decimal)
	const RestrictNumber = (newvalue, oldvalue) =>{
		var len = newvalue.length;
		var index = newvalue.indexOf('.');
		if (index > 0) {
			var decimalchars = (len-1) - index;
			if (decimalchars>2){
				return oldvalue;
			}
		}
		return newvalue;
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		//console.log(event.key);
		//If a value has been accepted into localstate, we assume it's valid and can be referenced here.
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		if (event.key !== "Tab"){
			//Conditionals for Types:
			if (column==="Cost"){
				newvalue = RestrictNumber(newvalue, oldvalue);
			}
			if (column==="PullRate"){
				newvalue = RestrictNumber(newvalue, oldvalue);
			}
			
			if (column==="SearchBool") { //OR other MaterialUI Checkbox.
				if (event.target.checked){
					localstate.griditems[index][column] = 1;
					//rowRefs.current[index+column].checked = true;
				} else {
					localstate.griditems[index][column] = 0;
					//rowRefs.current[index+column].checked = false;
				}
			} else if (column==="SomeSelectable") {
				//Keep this, we might use it.
				//Status works similar to booleans - Component takes care of view while localstate has yet to update the DB
				localstate.griditems[index][column] = newvalue;
			} else {
				//Update Refs like usual.
				rowRefs.current[index+column].value=newvalue;
				//Always update localstate.
				localstate.griditems[index][column] = newvalue;
			}
			
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			//Update Save button to show pending saves
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
		}
	}


	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		localstate.searchoptions.searchpairs.searchpair1.type = "Name";
		localstate.searchoptions.searchpairs.searchpair1.value = "";
		localstate.searchoptions.searchpairs.searchpair1.mode = "like";
		localstate.searchoptions.searchpairs.searchpair1.uuid = uuidv4();

		localstate.searchoptions.searchpairs.searchpair2.type = "ProductType";
		localstate.searchoptions.searchpairs.searchpair2.value = "";
		localstate.searchoptions.searchpairs.searchpair2.mode = "like";
		localstate.searchoptions.searchpairs.searchpair2.uuid = uuidv4();

		localstate.searchoptions.searchpairs.searchpair3.type = "ComponentType";
		localstate.searchoptions.searchpairs.searchpair3.value = "";
		localstate.searchoptions.searchpairs.searchpair3.mode = "like";
		localstate.searchoptions.searchpairs.searchpair3.uuid = uuidv4();

		localstate.searchoptions.searchpairs.searchpair4.type = "";
		localstate.searchoptions.searchpairs.searchpair4.value = "";
		localstate.searchoptions.searchpairs.searchpair4.mode = "strict";
		localstate.searchoptions.searchpairs.searchpair4.uuid = uuidv4();

		localstate.searchoptions.searchpairs.searchpair5.type = "";
		localstate.searchoptions.searchpairs.searchpair5.value = "";
		localstate.searchoptions.searchpairs.searchpair5.mode = "like";
		localstate.searchoptions.searchpairs.searchpair5.uuid = uuidv4();

		localstate.searchoptions.searchpairs.searchpair6.type = "";
		localstate.searchoptions.searchpairs.searchpair6.value = "";
		localstate.searchoptions.searchpairs.searchpair6.mode = "strict";
		localstate.searchoptions.searchpairs.searchpair6.uuid = uuidv4();

		localstate.dbreload = true;
		UpdateState(localstate);
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	const BulkUpdate = () =>{
		//Explanation:
		//Currently checkboxes are uncontrolled, they simply add the indexes to the localstate.selectedindexes, uncommitted to state.
		//This avoids an expensive rerender for large grids.
		var i;
		var random;
		for (i=0; i<localstate.selectedindexes.length; i++){
			console.log(localstate.griditems[localstate.selectedindexes[i]].Name);
			random = getRandomInt(3);
			//Update view to reflect pending changes
			rowRefs.current[localstate.selectedindexes[i]+"Cost"].value=random;
			rowRefs.current[localstate.selectedindexes[i]+"SaveStatus"].classList.add("unsavedhighlight");
			//Update localstate
			localstate.griditems[localstate.selectedindexes[i]]["Cost"] = random;		
			localstate.griditems[localstate.selectedindexes[i]].unsaved = true;
		}
		btnSave.current.style.color="white";
		btnSave.current.style.backgroundColor="#01579B";
	}

	const GoToComponentTypes = () => {
		history.push('/componenttypes');
	}

	/* ##########################  TABLE HEAD  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "Name", numeric: false, label: "Name", align:"left", allowsort:true, style:{width:"400px"}},
		{ id: "ComponentType", numeric: false, label: "Component Type", align:"left", allowsort:true, style:{}},
		{ id: "ProductType", numeric: false, label: "Product Type", align:"left", allowsort:false, style:{}},
		{ id: "Cost", numeric: true, label: "Cost", align:"right", allowsort:true, style:{}},
		{ id: "PullRate", numeric: true, label: "Pull Rate", align:"right", allowsort:true, style:{}},
		{ id: "SearchBool", numeric: false, label: "Searchable", align:"center", allowsort:true, style:{}},
		{ id: "SearchAlias", numeric: false, label: "Search Alias", align:"left", allowsort:true, style:{}},
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};
	
		return (
			<thead style={{display:"table-header-group"}}>
				<tr style={{border:"1px solid #CCC",
							backgroundColor:"#DDD"}}>
					<td style={{width: "14px", padding:"none", display:"table-cell", padding:"2px 4px 2px 5px"}}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) => 
					colstate[headCell.id] &&
					( 	
						<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}				
							>
								{(headCell.allowsort) && 
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id==="Name") &&
											<div style={{width:"30px", display:"inline-block"}}></div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id) 
											? <span style={{fontWeight:"bold"}}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) && 
									<span>{headCell.label}</span>
								}
							</td>
					))}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/

	/* ##########################  Render Function  ########################## */
	return (
        <div style={{padding:"8px", overflow:"auto", minWidth:"400px"}}>
			{/* Standard Page Header with right floated error message space */ }
			<div style={{height:"35px"}}>
				<div style={{textAlign:"center"}}>
					<h2>Components</h2>
				</div>
				{(errors.currenterror.errshow) &&
					<div style={{position:"relative", float:"right", bottom:"26px", height:"25px", fontSize:"12px"}}>
						<ErrorMessage />
					</div>
				}
				<div style={{ position: "relative", float: "right", bottom: "30px", height: "0px" }}>
					<ProgressBar />
				</div>
			</div>

			{/* /* ##########################  Search Inputs  ########################## */	}
			{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
			{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}
			
			<div>

				{/* Key-Value Searches */}


				{/* Search Pair 1 */}
				{(showsearch1) &&
					<div className={classes.searchinputs}>
						<FormControl className={classes.searchtypeinput} variant="standard" style={{minWidth:"160px"}}>
							{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
							<Select
								key={localstate.searchoptions.searchpairs.searchpair1.uuid}
								value={localstate.searchoptions.searchpairs.searchpair1.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search1")}
							>
								<MenuItem value={"Name"}>Name</MenuItem>
								<MenuItem value={"ComponentType"}>Component Type</MenuItem>
								<MenuItem value={"ProductType"}>Product Type</MenuItem>
								<MenuItem value={"Cost"}>Cost</MenuItem>
								<MenuItem value={"PullRate"}>Pull Rate</MenuItem>
								<MenuItem value={"SearchAlias"}>Search Alias</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search1" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair1.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search1")} />
						<div style={{ width: "30px" }}>&nbsp;</div>
					</div>
				}

				{/* Search Pair 2 */}
				{(showsearch2) &&
					<div className={classes.searchinputs}>
						<FormControl className={classes.searchtypeinput} variant="standard" style={{minWidth:"160px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair2.uuid}
								value={localstate.searchoptions.searchpairs.searchpair2.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search2")}
							>
								<MenuItem value={"Name"}>Name</MenuItem>
								<MenuItem value={"ComponentType"}>Component Type</MenuItem>
								<MenuItem value={"ProductType"}>Product Type</MenuItem>
								<MenuItem value={"Cost"}>Cost</MenuItem>
								<MenuItem value={"PullRate"}>Pull Rate</MenuItem>
								<MenuItem value={"SearchAlias"}>Search Alias</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search2" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair2.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search2")} />
						<div style={{ width: "30px", float:"right" }}>
							{(showsearch2 && !showsearch3) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search2')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 3 */}
				{(showsearch3) &&
					<div className={classes.searchinputs}>
						<FormControl className={classes.searchtypeinput} variant="standard" style={{minWidth:"160px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair3.uuid}
								value={localstate.searchoptions.searchpairs.searchpair3.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search3")}
							>
								<MenuItem value={"Name"}>Name</MenuItem>
								<MenuItem value={"ComponentType"}>Component Type</MenuItem>
								<MenuItem value={"ProductType"}>Product Type</MenuItem>
								<MenuItem value={"Cost"}>Cost</MenuItem>
								<MenuItem value={"PullRate"}>Pull Rate</MenuItem>
								<MenuItem value={"SearchAlias"}>Search Alias</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search3" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair3.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search3")} />
						<div style={{ width: "30px" }}>
							{(showsearch3 && !showsearch4) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search3')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 4 */}
				{(showsearch4) &&
					<div className={classes.searchinputs}>
						<FormControl className={classes.searchtypeinput} variant="standard" style={{minWidth:"160px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair4.uuid}
								value={localstate.searchoptions.searchpairs.searchpair4.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search4")}
							>
								<MenuItem value={"Name"}>Name</MenuItem>
								<MenuItem value={"ComponentType"}>Component Type</MenuItem>
								<MenuItem value={"ProductType"}>Product Type</MenuItem>
								<MenuItem value={"Cost"}>Cost</MenuItem>
								<MenuItem value={"PullRate"}>Pull Rate</MenuItem>
								<MenuItem value={"SearchAlias"}>Search Alias</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search4" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair4.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search4")} />
						<div style={{ width: "30px" }}>
							{(showsearch4 && !showsearch5) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search4')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}


				{/* Search Pair 5 */}
				{(showsearch5) &&
					<div className={classes.searchinputs}>
						<FormControl className={classes.searchtypeinput} variant="standard" style={{minWidth:"160px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair5.uuid}
								value={localstate.searchoptions.searchpairs.searchpair5.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search5")}
							>
								<MenuItem value={"Name"}>Name</MenuItem>
								<MenuItem value={"ComponentType"}>Component Type</MenuItem>
								<MenuItem value={"ProductType"}>Product Type</MenuItem>
								<MenuItem value={"Cost"}>Cost</MenuItem>
								<MenuItem value={"PullRate"}>Pull Rate</MenuItem>
								<MenuItem value={"SearchAlias"}>Search Alias</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search5" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair5.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search5")} />
						<div style={{ width: "30px" }}>
							{(showsearch5 && !showsearch6) &&
								<IconButton className={"transparenticon"} size="small" aria-label="delete" onClick={() => RemoveSearch("search5")}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}

				{/* Search Pair 6 */}
				{(showsearch6) &&
					<div className={classes.searchinputs}>
						<FormControl className={classes.searchtypeinput} variant="standard" style={{minWidth:"160px"}}>
							<Select
								key={localstate.searchoptions.searchpairs.searchpair6.uuid}
								value={localstate.searchoptions.searchpairs.searchpair6.type} disableUnderline
								onChange={(event) => onChangeSearchType(event.target.value, "search6")}
							>
								<MenuItem value={"Name"}>Name</MenuItem>
								<MenuItem value={"ComponentType"}>Component Type</MenuItem>
								<MenuItem value={"ProductType"}>Product Type</MenuItem>
								<MenuItem value={"Cost"}>Cost</MenuItem>
								<MenuItem value={"PullRate"}>Pull Rate</MenuItem>
								<MenuItem value={"SearchAlias"}>Search Alias</MenuItem>
							</Select>
						</FormControl>
						<TextField id="search6" variant="standard"
							key={localstate.searchoptions.searchpairs.searchpair6.uuid+1}
							className={classes.searchinput} InputProps={{ disableUnderline: true }}
							onChange={(event) => onChangeSearchValue(event.target.value, "search6")} />
						<div style={{ width: "30px" }}>
							{(showsearch6) &&
								<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch('search6')}>
									<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
								</IconButton>
							}
						</div>
					</div>
				}


				{/* On last search input, don't show! */}
				{(!showsearch6) &&
					<div style={{display:"inline-block", paddingLeft:"15px"}}>
						<IconButton size="small" aria-label="Add Search" onClick={()=>AddSearch()}>
							<AddIcon color="primary" fontSize="large" style={{padding:"5px"}}></AddIcon>
						</IconButton>
					</div>
				}

			</div>


			{/* Top Buttons & Pagination */}
			<div style={{height:"5px"}}>&nbsp;</div>
			<div>
				{(!showconfirmation) &&
					<React.Fragment>
						
									
									<Button
										className={(userPerms.updateComponent === 1 && userPerms.createComponent === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										onClick={() => SaveChanges()}
										ref={el => btnSave.current = el}>
										<SaveIcon sx={{color:"lightgray"}}></SaveIcon>&nbsp;Save Changes
									</Button>
								

									<Button
										className={(userPerms.updateComponent === 1 && userPerms.createComponent === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										style={{display:"none"}}
										onClick={() => SaveChanges()}
										ref={el => btnPendingSave.current = el}>
										<PendingIcon sx={{color:"orange"}}></PendingIcon>&nbsp;Save Changes
									</Button>
								

									<Button
										className={(userPerms.createComponent === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										onClick={() => AddRow()}
										ref={el => btnAddRow.current = el}>
										Add Row
									</Button>
								

									<Button
										className={(userPerms.deleteComponent === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										onClick={() => RejectIfInvalidSelected("",DeleteSelectedInit)}
										ref={el => btnDeleteSelected.current = el}>
										Delete Selected
									</Button>
									


							{/* ##########################  Column Toggles  ########################## */}
							<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										aria-haspopup="true"
										onClick={ShowColumnMenu}>
										Columns
									</Button>

									<Menu
										className={classes.bluebtn}
										color="primary"
										id="simple-menu"
										anchorEl={showcolumnmenu}
										keepMounted
										open={Boolean(showcolumnmenu)}
										onClose={CloseColumnMenu}
									>
										<MenuItem disableRipple className={classes.columnmenu}>
											<div style={{verticalAlign:"top"}}> {/* Optional container for 2 column menu! */}
												<div style={{display:"inline-block", maxHeight:"600px", overflow:"auto", verticalAlign:"top"}}>
													<FormGroup>
														<FlexColumnOption value="Name" label="Name"/>
														<FlexColumnOption value="ComponentType" label="Component Type"/>
														<FlexColumnOption value="ProductType" label="Product Type"/>
														<FlexColumnOption value="Cost" label="Cost" />
														<FlexColumnOption value="PullRate" label="Pull Rate" />
														<FlexColumnOption value="SearchBool" label="Searchable" />
														<FlexColumnOption value="SearchAlias" label="Search Alias"/>
													</FormGroup>
												</div>
											</div>
										</MenuItem>
									</Menu>



						<Button
							className={classes.bluebtn}
							color="primary" variant="contained" onClick={() => ResetSearches()}>
							Reset Searches
						</Button>

					
						<Button
							className={(userPerms.readComponentType === 1) ? classes.bluebtn : classes.hidden}
							color="primary" variant="contained" onClick={() => GoToComponentTypes()}>
							View\Edit Component Types
						</Button>


					</React.Fragment>
				}

				{/* Confirmation Space */}
				{(showconfirmation) &&
					<div>
						<b>Are you sure you want to delete these items?</b>
						<div style={{padding:"10px 0px"}}>
							{deleteitems.map((row, index) => { 
                                if (deleteitems.length === index + 1) {
									return(<span key={index}>{row.Name}</span>)
								} else {
									return(<span key={index}>{row.Name}, </span>)
								}
							})}
						</div>
						<Button className={classes.warningbutton} variant="contained" onClick={()=>DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
						<Button variant="contained" onClick={()=>CancelDelete()}>Cancel</Button>
					</div>
				}

				{(localstate.totalitems>0) &&
					<TablePagination className={classes.paginationalign}
						style={{display:"inline-flex", float:"right"}}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[100,250]}
					/>
				}
			</div>
			

			<table aria-label="caption table" size="small" className={classes.flexgrid} style={{display:"table", tableLayout:"fixed", minWidth:"100%", borderCollapse:"collapse", borderColor:"grey"}}>
				<EnhancedTableHead
					numSelected={localstate.selectedcount} 
					classes={classes}
					order={localstate.order}
					orderBy={localstate.orderby}
					onSelectAllClick={handleSelectAllClick}
					onRequestSort={handleRequestSort}
					rowCount={state.griditems.length}
				/>
				{/* /* ##########################  Row Design  ########################## */	 }
				{/* If DB reload, don't allow view of table. */}
				{(!localstate.dbreload) &&
					<tbody style={{ display: "table-row-group" }}>
						{(localstate.griditems.length > 0) &&
							localstate.griditems.map((row, index) => {
								//Create all-new refs on each render. Helps avoid issues with grid states.
								rowRefs.current[index + "Checkbox"] = React.createRef();
								rowRefs.current[index + "SaveStatus"] = React.createRef();
								rowRefs.current[index + "Name"] = React.createRef();
								rowRefs.current[index + "ComponentType"] = React.createRef();
								rowRefs.current[index + "ProductType"] = React.createRef();
								rowRefs.current[index + "Cost"] = React.createRef();
								rowRefs.current[index + "PullRate"] = React.createRef();
								rowRefs.current[index + "SearchAlias"] = React.createRef();
								return (
									<React.Fragment key={row.ID}>
										<tr	className={classes.flexgridrow}>
											{/* Checkbox - Requires inner div to change background color with SaveStatus */}
											<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
												<div style={{ padding: "6px 4px 1px 4px" }}>
													{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
													{/* Don't show checkbox if this is a PendingItem */}
													{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el=>rowRefs.current[index+"Checkbox"]=el} 
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

												</div>
											</td>

											{/* Name */}
											{(colstate.Name) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														ref={el => rowRefs.current[index + "Name"] = el}
														className={classes.flexgridinput}
														style={{ minWidth: "100px" }}
														onKeyDown={(event) => DetectTab(event, index, "Name")}
														onKeyUp={(event) => onChangeValue(event, index, "Name")}
														defaultValue={row.Name} />
												</td>
											}

											{/* ComponentType */}
											{(colstate.ComponentType) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														ref={el => rowRefs.current[index + "ComponentType"] = el}
														className={classes.flexgridinput}
														style={{ minWidth: "100px" }}
														onKeyDown={(event) => DetectTab(event, index, "ComponentType")}
														onKeyUp={(event) => onChangeValue(event, index, "ComponentType")}
														defaultValue={row.ComponentType} />
												</td>
											}

											{/* ProductType */}
											{(colstate.ComponentType) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														ref={el => rowRefs.current[index + "ProductType"] = el}
														className={classes.flexgridinput}
														style={{ minWidth: "100px" }}
														onKeyDown={(event) => DetectTab(event, index, "ProductType")}
														onKeyUp={(event) => onChangeValue(event, index, "ProductType")}
														defaultValue={row.ProductType} />
												</td>
											}


											{/* COST */}
											{(colstate.Cost) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														type="number" step="0.01"
														ref={el => rowRefs.current[index + "Cost"] = el}
														className={classes.flexgridinput}
														style={{ minWidth: "50px", textAlign: "right" }}
														onKeyDown={(event) => DetectTab(event, index, "Cost")}
														onKeyUp={(event) => onChangeValue(event, index, "Cost")}
														defaultValue={row.Cost} />
												</td>
											}


											{/* PullRate */}
											{(colstate.PullRate) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														type="number" step="0.01"
														ref={el => rowRefs.current[index + "PullRate"] = el}
														className={classes.flexgridinput}
														style={{ minWidth: "50px", textAlign: "right" }}
														onKeyDown={(event) => DetectTab(event, index, "PullRate")}
														onKeyUp={(event) => onChangeValue(event, index, "PullRate")}
														defaultValue={row.PullRate} />
												</td>
											}


											{/* SearchBool */}
											{(colstate.SearchBool) &&
												<td>													
													<div style={{ padding: "4px 4px 1px 4px", textAlign:"center" }}>
														<Checkbox
															className={classes.gridcheckbox}
															disableRipple
															color="default"
															defaultChecked={row.SearchBool===1 ? true : false}
															checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
															icon={<span className={classes.icon} />}
															onChange={(event) => onChangeValue(event, index, "SearchBool")}
														/>
													</div>
												</td>
											}

											{/* SearchAlias */}
											{(colstate.SearchAlias) &&
												<td className={classes.flexgridinputcontainer}>
													<input
														ref={el => rowRefs.current[index + "SearchAlias"] = el}
														className={classes.flexgridinput}
														style={{ minWidth: "100px" }}
														onKeyDown={(event) => DetectTab(event, index, "SearchAlias")}
														onKeyUp={(event) => onChangeValue(event, index, "SearchAlias")}
														defaultValue={row.SearchAlias} />
												</td>
											}

										</tr>

									</React.Fragment>

								)
							}
							)
						}
						{(localstate.griditems.length === 0) &&
							<tr	className={classes.flexgridrow}><td colSpan="100%"
								style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
						}
					</tbody>
				}
				{(localstate.dbreload) &&
					<tbody>
						<tr>
							<td colSpan="100%">
								<div style={{padding:"20px", textAlign:"center", margin:"auto"}}>
									<CircularProgress />
								</div>
							</td>
						</tr>
					</tbody>
				}
			</table> 
			{(localstate.totalitems > localstate.rowsperpage) &&
				<TablePagination className={classes.paginationalign}
					component="div"
					count={localstate.totalitems}
					page={localstate.page}
					onPageChange={handleChangePage}
					rowsPerPage={localstate.rowsperpage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[100,250]}
				/>
			}
			<br></br>
			Component Notes: Some components can be used in order inspection for comparison of sku components to a manifest item's reported specifications. Their types are: <br></br>
			CPU<br></br>
				RAM<br></br>
				HDD1<br></br>
				Optical<br></br>
				Webcam<br></br>
				Resolution<br></br>
				COA<br></br>
				BodyGrade<br></br>
				LCD Grade<br></br>
				Grade<br></br>
				Other Features<br></br>
				Add-ons<br></br>
				
			
		</div>
    );
}

export default ComponentsTable;