import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	selectErrorMessage
} from './errormessageSlice';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';


export function NewErrorMessage() {
	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const error = useSelector(selectErrorMessage);

	//console.log(error);

	return (
		<React.Fragment>
			{(error.errorstate.errshow) &&
				<div>
					{(error.errorstate.errtype === 'ok') &&
						<span style={{ backgroundColor: '#086b00', color: 'white', display: 'inline-block', borderRadius: '2px', paddingTop: '3px', paddingBottom: '3px', paddingLeft: '8px', paddingRight: '8px', boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.24)" }}>
							{error.errorstate.errmsg}&nbsp;
							{(error.errorstate.link) &&
								<a href={error.errorstate.link} target="_blank" rel="noreferrer" style={{
									color: "#FFF",
									textDecoration: "underline"
								}}>{error.errorstate.linktext}</a>
							}
						</span>
					}
					{(error.errorstate.errtype === 'warning') &&
						<span style={{ backgroundColor: '#e58700', color: 'white', display: 'inline-block', borderRadius: '2px', paddingTop: '3px', paddingBottom: '3px', paddingLeft: '8px', paddingRight: '8px', boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.24)" }}>
							{error.errorstate.errmsg}&nbsp;
							{(error.errorstate.link) &&
								<a href={error.errorstate.link} target="_blank" rel="noreferrer" style={{
									color: "#FFF",
									textDecoration: "underline"
								}}>{error.errorstate.linktext}</a>
							}
						</span>
					}
					{(error.errorstate.errtype === 'danger') &&
						<span style={{ backgroundColor: '#d42300', color: 'white', display: 'inline-block', borderRadius: '2px', paddingTop: '3px', paddingBottom: '3px', paddingLeft: '8px', paddingRight: '8px', boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.24)" }}>
							{error.errorstate.errmsg}&nbsp;
							{(error.errorstate.link) &&
								<a href={error.errorstate.link} target="_blank" rel="noreferrer" style={{
									color: "#FFF",
									textDecoration: "underline"
								}}>{error.errorstate.linktext}</a>
							}
						</span>
					}
					{(error.errorstate.errtype === 'neutral') &&
						<span style={{ backgroundColor: '#AAA', color: 'white', display: 'inline-block', borderRadius: '2px', paddingTop: '3px', paddingBottom: '3px', paddingLeft: '8px', paddingRight: '8px', boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.24)" }}>
							{error.errorstate.errmsg}&nbsp;
							{(error.errorstate.link) &&
								<a href={error.errorstate.link} target="_blank" rel="noreferrer" style={{
									color: "#FFF",
									textDecoration: "underline"
								}}>{error.errorstate.linktext}</a>
							}
						</span>
					}
				</div>
			}
		</React.Fragment>
	)
}
