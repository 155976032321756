import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "./contexts/AppContext";
import axios from "axios";
import {
	NOT_LOGGED_IN,
	LOG_IN_FORM,
	SIGN_UP_FORM,
	LOGGED_IN,
} from "./constants/AuthStatus";
//import { MdEmail } from "react-icons/md";
//import { GoKey } from "react-icons/go";
//import { FaRegEye } from "react-icons/fa";
//import { FaRegEyeSlash } from "react-icons/fa";

//import AuthMenu from "./AuthMenu";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import deluxelogo from '../images/deluxe.png';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import CircularProgress from '@mui/material/CircularProgress';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';

let hostName = "";
if (process.env.NODE_ENV === "development") {
	hostName = "http://localhost:8000/v4/";
} else if (process.env.NODE_ENV === "production") {
	hostName = process.env.REACT_APP_DB_API4+"/";
}

const CustomButton = styled(Button)({
	width: "100%",
	color: "#01579B",
	boxShadow: 'none',
	textTransform: 'none',
	fontSize: 16,
	padding: '6px 12px',
	border: '1px solid',
	lineHeight: 1.5,
	backgroundColor: '#EEE',
	borderColor: '#00458f',
	fontFamily: [
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	'&:hover': {
		backgroundColor: '#DDD',
		borderColor: '#004983',
		boxShadow: 'none',
	},
	'&:active': {
		boxShadow: 'none',
		backgroundColor: '#004983',
		borderColor: '#005cbf',
	},
	'&:focus': {
		boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
	},
});

const LoginPage = (props) => {
	const classes = useClasses(flexstyles);
	const appContext = useContext(AppContext);
	const {
		userEmail,
		userUserName,
		userPassword,
		handleUserEmail,
		handleUserUserName,
		handleUserPassword,
		setUserRole,
		setUserPerms,
		setUserTZ,
		setUserId,
		setUserName,
		setUserUserName,
		setErrorMessage,
		setAuthStatus,
		errorMessage,
		setAppStarted
	} = appContext;

	//Loading Circular Progress
	const [isLoading, setIsLoading] = useState(false);

	//Unused right now
	const [hidePassword, setHidePassword] = useState(true);
	const showHiddenPassword = hidePassword ? "" : "hidden";
	const showRevealedPassword = hidePassword ? "hidden" : "";
	function togglePassword() {
		setHidePassword(!hidePassword);
	}


	//Try: Put login function in here instead. Then use props.updateState to update our application.



	const login = () => {
		setIsLoading(true);
		setErrorMessage("");
		axios.defaults.withCredentials = true;
		axios.defaults.withXSRFToken =  true;
		// CSRF COOKIE
		axios.get(hostName + "sanctum/csrf-cookie").then(
			(response) => {
				//console.log(response);
				// LOGIN
				axios
					.post(hostName + "login", {
						//email: userEmail,
						username: userUserName,
						password: userPassword,
					})
					.then(
						(response) => {
							//console.log(response);
							// GET USER
							axios.get(hostName + "user").then(
								(response) => {
									//console.log(response);
									setUserId(response.data.id);
									setUserName(response.data.firstname+" "+response.data.lastname);
									setUserUserName(response.data.username);
									setUserRole(response.data.role);
									setUserPerms(response.data.userperms);
									setUserTZ(response.data.timezone);
									setErrorMessage("");
									setAuthStatus(LOGGED_IN);
									setIsLoading(false);
									setAppStarted(true);
									//localstate.loginstate = true;
									//props.updateState(localstate);
								},
								// GET USER ERROR
								(error) => {
									setIsLoading(false);
									setErrorMessage("Could not complete the login");
								}
							);
						},
						// LOGIN ERROR
						(error) => {
							setIsLoading(false);
							if (error.response) {
								setErrorMessage(error.response.data.message);
							} else {
								setErrorMessage("Could not complete the login");
							}
						}
					);
			},
			// COOKIE ERROR
			(error) => {
				setIsLoading(false);
				setErrorMessage("Could not complete the login");
			}
		);
	};

	//App starts without context, we need to initiate it!
	const checklogin = () => {
		axios.defaults.withCredentials = true;
		axios.defaults.withXSRFToken =  true;
		// CSRF COOKIE
		axios.get(hostName + "sanctum/csrf-cookie").then(
			(response) => {
				axios.get(hostName + "user").then(
					(response) => {
						//console.log(response);
						setUserId(response.data.id);
						setUserName(response.data.name);
						setUserUserName(response.data.username);
						setErrorMessage("");
						setAuthStatus(LOGGED_IN);
					},
					// GET USER ERROR
					(error) => {
						setErrorMessage("Could not find current user");
					}
				);
			},
			// COOKIE ERROR
			(error) => {
				setErrorMessage("Could not complete the login");
			}
		);
	}

	const CheckEnter = (event) => {
		if (event.key==="Enter"){
			login();
		}
	}

	return (
		<>
			<div style={{ paddingTop: "20px" }}>
				<Box sx={{ width: 275, margin: "auto" }}>
					<Card variant="outlined" sx={{ backgroundColor: "#01579B", textAlign: "center", p: 3, my: 4 }}>
						<div>
							<img src={deluxelogo} />
						</div>

						<div className={classes.authinputcontainer}>
							<InputBase
								placeholder="Username"
								classes={{
									root: classes.authinputroot,
									input: classes.authinput,
								}}
								inputProps={{ 'aria-label': 'username' }}
								value={userUserName}
								onChange={handleUserUserName}
								onKeyUp={(event) => CheckEnter(event)}
							/>
						</div>
						<div style={{ width: "100%", height: "8px" }}>
							&nbsp;
						</div>
						<div className={classes.authinputcontainer}>
							<InputBase
								placeholder="Password"
								type="password"
								classes={{
									root: classes.authinputroot,
									input: classes.authinput,
								}}
								inputProps={{ 'aria-label': 'username' }}
								value={userPassword}
								onChange={handleUserPassword}
								onKeyUp={(event) => CheckEnter(event)}
							/>
						</div>
						<div style={{ width: "100%", height: "8px" }}>
							&nbsp;
						</div>
						<CustomButton variant="contained" disableRipple
							onClick={() => login()}>
							Login
						</CustomButton>
					</Card>
				</Box>
			</div>
			<div style={{ textAlign: "center", height: "40px" }}>
				{(isLoading) &&
					<CircularProgress />
				}
				{errorMessage}
			</div>
			
		</>
	);
};

export default LoginPage;
