import React from 'react';
import amazon from '../images/amazon.png';
import ebay from '../images/ebay.png';
import newegg from '../images/newegg.png';
import neweggbusiness from '../images/neweggbusiness.png';
import deluxepcs from '../images/deluxeicon.png';
import walmart from '../images/walmart.png';
import backmarket from '../images/backmarket.png';
import reebelo from '../images/reebelo.png';
import directorder from '../images/directorder.png';

var hostbase = process.env.REACT_APP_BASE;



const MarketIcon = (props) => {
	
	return(
		<>
			{(props.storename==="Amazon") &&
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={amazon} />	
			}
			{(props.storename==="Ebay") &&
			<div>
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={ebay} />
			</div>	
			}
			{(props.storename==="Newegg") &&
			<div>
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={newegg} />
			</div>	
			}
			{(props.storename==="Ebay-JT") &&
			<div>
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={ebay} />
			</div>	
			}
			{(props.storename==="DeluxePCs.com") &&
			<div>
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={deluxepcs} />
			</div>	
			}
			{(props.storename==="Newegg Business") &&
			<div>
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={neweggbusiness} />
			</div>	
			}
			{(props.storename==="Walmart") &&
			<div>
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={walmart} />
			</div>	
			}
			{(props.storename==="Reebelo") &&
			<div>
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={reebelo} />
			</div>	
			}
			{(props.storename==="Direct Order") &&
			<div>
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={directorder} />
			</div>	
			}
			{(props.storename==="Ebay-LU") &&
			<div>
				<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={ebay} />
			</div>	
			}
			{(props.storename==="Back Market") &&
				<div>
					<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={backmarket} />
				</div>
			}
			{(props.storename==="BackMarket") &&
				<div>
					<img style={{height:props.height, verticalAlign:props.verticalalignment}} src={backmarket} />
				</div>
			}
			
		</>
	)
}

    


export default MarketIcon;